import { get } from "lodash";
import API from "./axiosConfig";

// Serviço de Orçamentos
const budgetService = {
  /**
   * Obtém a lista de orçamentos do usuário com paginação
   * @param {Object} query - Parâmetros de consulta (página, filtros, etc.)
   * @returns {Promise}
   */
  getBudgets: (query = {}) => {
    return API
      .get('/budgets', { params: query })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data || { error: 'Erro ao obter os orçamentos.' };
      });
  },

  /**
   * Obtém os detalhes de um orçamento pelo ID
   * @param {string} id - ID do orçamento
   * @returns {Promise}
   */
  getBudgetById: (id) => {
    return API
      .get(`budgets/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data || { error: 'Erro ao obter o orçamento.' };
      });
  },

  /**
   * Cria um novo orçamento
   * @param {Object} data - Dados do orçamento
   * @returns {Promise}
   */
  createBudget: (data) => {
    return API
      .post('/budgets', data)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data || { error: 'Erro ao criar o orçamento.' };
      });
  },

  getLatestBudgets: () => {
    return API
      .get('/budgets/latest')
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data || { error: 'Erro ao obter os orçamentos.' };
      });
  },

  /**
   * Atualiza um orçamento existente
   * @param {string} id - ID do orçamento
   * @param {Object} data - Dados atualizados
   * @returns {Promise}
   */
  updateBudget: (id, data) => {
    return API
      .put(`budgets/${id}`, data)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data || { error: 'Erro ao atualizar o orçamento.' };
      });
  },

  /**
   * Remove um orçamento pelo ID
   * @param {string} id - ID do orçamento
   * @returns {Promise}
   */
  deleteBudget: (id) => {
    return API
      .delete(`budgets/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data || { error: 'Erro ao excluir o orçamento.' };
      });
  },
};

export default budgetService;