import RegisterForm from "../../components/registerForm"

import { useNavigate } from "react-router-dom"

import { createUser } from "../../services/backend/UserService"
import {useToastContext} from '../../context/ToastContext'
import LoginLayout from "../layouts/LoginLayout"

import { Helmet } from "react-helmet"
import { SITE_NAME } from "../../misc/siteConfig"

function Register() {
    const navigate = useNavigate()
    const {showToast} = useToastContext()

    const handleSubmit = async (userData) => {
        try {
            await createUser(userData)
            showToast({
                title: 'Cadastrado com sucesso',
                status: 'success'
            })
            navigate('/login')
        } catch (error) {
            showToast({
                title: 'Error ao cadastrar',
                description: error.messsage,
                status: 'error'
            })
        }
    }

    return (
        <LoginLayout>
            <Helmet>
                <title>{SITE_NAME} &#8208; Registrar</title>
            </Helmet>
            <RegisterForm onSubmit={handleSubmit} />
        </LoginLayout>
    )
}

export default Register