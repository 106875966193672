import React from "react";
import { HStack, Link, StackDivider } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

function SupportFooter({options}) {

    return (
        <>
            {options && (
                <HStack
                    p={4}
                    borderTopWidth={1}
                    width='100%'
                    fontSize='xs'
                    divider={<StackDivider borderColor='gray.200' />}>

                    {options.map((option, index) => (
                        <Link as={RouterLink} key={index} to={option.href}>
                            {option.label}
                        </Link>
                    ))}
                </HStack>
            )}
        </>
    )
}

export default SupportFooter