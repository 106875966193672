import API from "../../services/backend/axiosConfig";
import { useEffect, useState } from "react";

export default function useGetPlans() {
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        API.get('/plans/list').then((response) => {
            setPlans(response.data.docs);
        });
    }, []);

    return { plans };
}