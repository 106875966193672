import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { Box, Button, FormControl, Input, Textarea } from '@chakra-ui/react';

export default function ContactForm({ ...rest }) {
    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                message: '',
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required('Name is required'),
                email: Yup.string().email('Invalid email').required('Email is required'),
                message: Yup.string().required('Message is required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
                console.log(values);
            }}
        >
            {({ isSubmitting, values }) => (
                <Box {...rest}>
                    <Form>
                        <FormControl id="name" mb={4} isRequired>
                            <Field as={Input} type="text" name="name" value={values.name} placeholder="Nome Completo" />
                        </FormControl>

                        <FormControl id="email" mb={4} isRequired>
                            <Field as={Input} type="email" name="email" value={values.email} placeholder="Email" />
                        </FormControl>

                        <FormControl id="message" mb={4} isRequired>
                            <Field as={Textarea} name="message" value={values.message} placeholder="Mensagem" />
                        </FormControl>

                        <Button colorScheme='teal' type="submit" isLoading={isSubmitting}>
                            Enviar
                        </Button>
                    </Form>
                </Box>
            )}
        </Formik>
    );
}

