export default function FormattedCycle(frequency, frequencyType) {
    switch (frequencyType) {
        case 'days':
            return `${frequency} dia(s)`;
        case 'weeks':
            return `${frequency} semana(s)`;
        case 'months':
            return `${frequency} mês(es)`;
        case 'years':
            return `${frequency} ano(s)`;
        default:
            return '';
    }
}