import React from 'react';
import {
	Box,
	Button,
	Input,
	Select,
	FormControl,
	FormLabel,
	ModalFooter,
	FormErrorMessage,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';

const EquipmentForm = ({ equipment, onClose, onSubmit }) => {
	const types = [
		"Câmera",
		"Microfone",
		"Lentes",
		"Gravador de Áudio",
		"Estabilizador de Câmera",
		"Tripé",
		"Iluminação LED",
		"Softbox",
		"Refletores",
		"Tela Verde",
		"Drones",
		"Monitores",
		"MicroSD"
	]

	const validationSchema = Yup.object().shape({
		type: Yup.string().oneOf(types, 'Tipo inválido').required('Tipo obrigatório'),
		model: Yup.string().required('Modelo obrigatório'),
		quantity: Yup.number().min(1, 'Quantidade precisa ser maior que 0').required('Quantidade obrigatória'),
	})

	const makeSubmit = (values, actions) => {
		console.log(values)
		const action = equipment ? 'edit' : 'create'
		onSubmit({
			action,
			data: values
		})
		actions.setSubmitting(false)
		onClose();
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={{
				_id: equipment ? equipment._id : '',
				type: equipment ? equipment.type : '',
				model: equipment ? equipment.model : '',
				quantity: equipment ? equipment.quantity : 0
			}}
			onSubmit={makeSubmit}
		>
			{({ errors: formError, touched, values: formData, handleChange, handleBlur, handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Box>
						<FormControl mb={4} isRequired isInvalid={!!formError.type && touched.type}>
							<FormLabel>Tipo</FormLabel>
							<Select
								name="type"
								value={formData.type}
								onChange={handleChange}
								onBlur={handleBlur}
								isRequired
							>
								<option>Selecione um tipo</option>
								{types.map((type) => (
									<option key={type} value={type}>{type}</option>
								))}
							</Select>
							<FormErrorMessage>{formError.type}</FormErrorMessage>
						</FormControl>

						<FormControl mb={4} isRequired isInvalid={!!formError.model && touched.model}>
							<FormLabel>Modelo</FormLabel>
							<Input
								name="model"
								value={formData.model}
								placeholder='ex: Canon 5D Mark IV'
								onChange={handleChange}
								onBlur={handleBlur}
								isRequired
							/>
							<FormErrorMessage>{formError.model}</FormErrorMessage>
						</FormControl>

						<FormControl mb={4} isRequired isInvalid={!!formError.quantity && touched.quantity}>
							<FormLabel>Quantidade</FormLabel>
							<Input
								name="quantity"
								value={formData.quantity}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								isRequired
							/>
							<FormErrorMessage>{formError.quantity}</FormErrorMessage>
						</FormControl>

						<ModalFooter>
							<Button colorScheme="teal" type='submit'>
								Salvar
							</Button>
							<Button onClick={onClose} ml={3}>
								Cancelar
							</Button>
						</ModalFooter>
					</Box>
				</form>
			)}
		</Formik>
	);
};

export default EquipmentForm;
