import { useEffect } from "react";
import budgetService from "../../services/backend/BudgetService";

export default function useDeleteBudget() {
    const deleteBudget = async (id) => {
        try {
            const response = await budgetService.deleteBudget(id)
            return response
        } catch (error) {
            return {
                error: 'Erro ao excluir o orçamento.'
            }
        }
    }

    return { deleteBudget }
}