import { Box } from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";
import { SITE_NAME } from "../../misc/siteConfig";
import BudgetForm from "../../components/dashboard/budget/budgetForm";
import { useNavigate } from "react-router-dom";

import {useToastContext} from "../../context/ToastContext";
import budgetService from "../../services/backend/BudgetService";

export default function CreateBudget() {
    const {showToast} = useToastContext()
    const navigate = useNavigate()

    const handleSubmit = async (values) => {
        try {
            const response = await budgetService.createBudget(values)

            navigate('/dashboard/orcamentos/ver/'+response.budget._id, {state: {budget: response.budget}})
            showToast({
                title: 'Criado com sucesso',
                description: response.message,
                status: 'success'
            })
        } catch (error) {
            console.log(error)
            showToast({
                title: 'Falha na criação',
                description: error.error,
                status: 'error'
            })
        }
    }

    return (
        <Box>
            <Helmet>
                <title>{SITE_NAME} - Criar Orcamento</title>
            </Helmet>

            <BudgetForm onSubmit={handleSubmit}/>
        </Box>
    )
}