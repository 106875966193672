import ListEquipments from "../components/dashboard/equips/ListEquipments";
import { Box, Heading } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { SITE_NAME } from "../misc/siteConfig";
import ToolNotIncluded from "../components/dashboard/plan/toolNotIncluded";

import {checkPlanTools} from "../auth/userHelpers";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

function Equipamento() {

    const auth = useAuthUser();

    return (
        checkPlanTools(auth, 'Equipamentos') ? (
            <Box>
                <Helmet>
                    <title>{SITE_NAME} &#8208; Equipamentos</title>
                </Helmet>
                <Heading p={4} borderWidth="1px" borderRadius="md" fontSize='md' boxShadow='md' bgColor='white'>Equipamentos</Heading>
                <ListEquipments />
            </Box>
        ) : <ToolNotIncluded />
    )
}

export default Equipamento;