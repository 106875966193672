import API from "../../services/backend/axiosConfig";

export default function useUpdateSiteConfig () {


    const updateSiteConfig = async (data) => {
        try {
            const siteConfig = await API.put(`/siteconfig/${data._id}`, data);
            return siteConfig.data;
        } catch (error) {
            console.log(error);
            return {
                error: 'Erro local ao atualizar configuração do site.'
            }
        }
    }

    return {updateSiteConfig}

}