import React, { useEffect } from "react";

import SiteConfigForm from "../../components/dashboard/admin/config/siteConfigForm";
import useUpdateSiteConfig from "../../hooks/siteConfig/useUpdateSiteConfig";
import { useToastContext } from "../../context/ToastContext";
import { useAnnouncementContext } from "../../context/AnnouncementContext";

export default function SiteConfigs() {
    const {showToast} = useToastContext()
    const {siteConfig, setSiteConfig} = useAnnouncementContext()
    const {updateSiteConfig} = useUpdateSiteConfig()

    const handleSubmit = (data) => {
        updateSiteConfig(data)
            .then(
                (config) => {
                    setSiteConfig(config)
                    showToast({
                        title: 'Concluido',
                        description: 'Configuração atualizada',
                        status: 'success'
                    })
            }).catch((error) => {
                showToast({
                    description: 'Erro ao tentar atualizar os dados. Tente novamente mais tarde.',
                    status: 'error'
                })
            });
    }

    return <SiteConfigForm siteConfig={siteConfig} submit={handleSubmit}/>
}