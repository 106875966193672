import React from 'react';
import { HStack, Card, CardBody, CardFooter, IconButton, CardHeader, Heading, List, ListItem, ListIcon, Badge, Button, Text } from '@chakra-ui/react';
import { AtSignIcon, PhoneIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import PopoverUi from '../../ui/Popover';

export const CustomerCard = ({ customer, onEdit, onDelete, ...rest }) => {

  return (
    <Card p={6} {...rest}>
      <CardHeader>

        <HStack>
          <Heading size='md'>{customer.fullname}</Heading>
          <Badge p={2}>{customer.type === 'CPF' ? 'Pessoa Física' : 'Pessoa Jurídica'}</Badge>
        </HStack>

      </CardHeader>

      <CardBody>
        <List spacing={2}>
          <ListItem>
            <ListIcon as={AtSignIcon} />
            {customer.email}
          </ListItem>

          <ListItem>
            <ListIcon as={PhoneIcon} />
            {customer.phonenumber}
          </ListItem>
        </List>
      </CardBody>

      <CardFooter>
        <HStack spacing={2}>
          <IconButton onClick={onEdit} icon={<EditIcon />}></IconButton>

          <PopoverUi
            element={<IconButton icon={<DeleteIcon />}></IconButton>}>
              <Text>Tem certeza que deseja deletar este cliente?</Text>
              <Button onClick={onDelete}>Deletar</Button>
          </PopoverUi>
        </HStack>
      </CardFooter>
    </Card>
  )
};
