import { Box, Text, IconButton, HStack, VStack } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

function CronNote({ note, onDelete }) {
    return (
        <Box p={4} borderWidth={1} borderColor='gray' borderRadius={10} boxShadow='md'>
            <VStack align='start'>
                <Text as='b'>{note.title}</Text>
                <Text>{note.message}</Text>

                <HStack>
                    <Text fontSize='xs'>{new Date(note.createdAt).toLocaleString()}</Text>
                    <IconButton size='xs' variant='ghost' onClick={() => onDelete(note._id)} icon={<DeleteIcon />} />
                </HStack>
            </VStack>
        </Box>
    )
}

export default CronNote