
import { updateUserById, updatePassword, deleteUserById } from '../services/backend/UserService';

const checkPlanTools = (user, tool) => {
    if (user.subscription && user.subscription.planId.tools.includes(tool)) {
        return true
    }
    else {
        return false
    }
}

const updatePass = async ({ user, currentPassword, newPassword }) => {
    try {
        var refreshPass = await updatePassword(user._id, currentPassword, newPassword)
        return true
    } catch (error) {
        return false
    }
}

const deleteAccount = async (user) => {
    try {
        await deleteUserById(user._id)
        return true
    } catch (error) {
        return false;
    }
}

const updateUser = async (user, updates) => {
    try {
        var userUpdated = await updateUserById(user._id, updates);
        return true
    } catch (error) {
        return false
    }
}

export  {
    checkPlanTools,
    updatePass,
    deleteAccount,
    updateUser
}