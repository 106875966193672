import React, { useEffect } from "react";
import LoginLayout from "../layouts/LoginLayout";
import { Formik } from "formik";
import * as Yup from 'yup';
import { useParams } from "react-router-dom";
import { Box, FormControl, FormLabel, Input, FormErrorMessage, Button, InputGroup, InputRightAddon, Text, IconButton, Link, Heading } from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import { getResetToken, resetPassword } from "../../services/backend/UserService";
import { useToastContext } from "../../context/ToastContext";

export default function ResetPassword() {
    const navigate = useNavigate();
    const { showToast } = useToastContext();
    const { token } = useParams();
    const [tokenStatus, setTokenStatus] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState({
        password: false,
        confirmPassword: false
    });

    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Senha é obrigatória'),
        confirmPassword: Yup.string().required('Confirmação de senha é obrigatória').oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
        token: Yup.string().required('Token é obrigatório'),
    });

    useEffect(() => {
        const checkToken = async (token) => {
            try {
                await getResetToken(token);
                setTokenStatus(true);
            } catch (error) {
                setTokenStatus(false);
                showToast({
                    title: 'Erro ao redefinir senha',
                    message: error.error,
                    type: 'error'
                })
            }
        }

        if (token) {
            checkToken(token);
        }
    }, [token]);

    const handleResetPassword = async (values) => {
        try {
            const response = await resetPassword(values.token, values.password);
            showToast({
                title: 'Senha redefinida com sucesso',
                type: 'success'
            });

            navigate('/login');
        } catch (error) {
            showToast({
                title: 'Erro ao redefinir senha',
                message: error.response.data.error,
                type: 'error'
            })
        }
    }

    return (
        <LoginLayout>
            {tokenStatus ? (
                <Formik
                initialValues={{
                    password: '',
                    confirmPassword: '',
                    token: token
                }}
                validationSchema={validationSchema}
                onSubmit={handleResetPassword}
            >
                {({ handleSubmit, handleBlur, errors, touched, setFieldValue, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Box p={10} bgColor='white' borderRadius={'md'} boxShadow={'md'}>
                            <FormControl id="password" mb={4} isInvalid={touched.password && errors.password} isRequired>
                                <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Senha</FormLabel>

                                <InputGroup>
                                    <Input
                                        type={showPassword.password ? 'text' : 'password'}
                                        name="password"
                                        value={values.password}
                                        onChange={(e) => setFieldValue('password', e.target.value)}
                                        onBlur={handleBlur}
                                    />
                                    <InputRightAddon>
                                        <IconButton size='sm' variant='ghost' onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })} icon={<ViewIcon />} />
                                    </InputRightAddon>
                                </InputGroup>

                                <FormErrorMessage>{touched.password && errors.password}</FormErrorMessage>
                            </FormControl>

                            <FormControl id="confirmPassword" mb={4} isInvalid={touched.confirmPassword && errors.confirmPassword} isRequired>
                                <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Confirmação de Senha</FormLabel>

                                <InputGroup>
                                    <Input
                                        type={showPassword.confirmPassword ? 'text' : 'password'}
                                        name="confirmPassword"
                                        value={values.confirmPassword}
                                        onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                                        onBlur={handleBlur}
                                    />
                                    <InputRightAddon>
                                        <IconButton size='sm' variant='ghost' onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })} icon={<ViewIcon />} />
                                    </InputRightAddon>
                                </InputGroup>

                                <FormErrorMessage>{touched.confirmPassword && errors.confirmPassword}</FormErrorMessage>
                            </FormControl>

                            <FormControl>
                                <Button width={'100%'} colorScheme='teal' mb={2} type="submit">Alterar senha</Button>
                                <Link href="/login">
                                    <Button variant={'outline'} width={'100%'} fontSize={'xs'} textDecoration={'underline'}>Fazer login</Button>
                                </Link>
                            </FormControl>
                        </Box>
                    </form>
                )}
            </Formik>
            ) : (
                <Box py={8} px={20} bgColor='white' borderRadius={'md'} boxShadow={'md'}>
                    <Heading textAlign={'center'} size="lg" textColor={'red'}>Token inválido ou expirado</Heading>
                    <Text textAlign={'center'} mb={4}>Por favor, solicite um novo token de redefinição de senha.</Text>
                    <Link href="/conta/recuperar">
                        <Button variant={'outline'} colorScheme="teal" width={'100%'}>Solicitar novo link</Button>
                    </Link>
                </Box>
            )}
        </LoginLayout>
    )
}