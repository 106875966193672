import { GridItem } from "@chakra-ui/react";

import SupportFooter from "../../components/ui/supportFooter";

export default function Footer({...rest}) {
    return (
        <GridItem {...rest}>
            <SupportFooter options={[
                { label: "Suporte", href: "/suporte" },
                { label: "Contato", href: "/contato" },
                { label: "FAQ - Perguntas Frequentes", href: "/suporte/faq" },
                { label: "Termos de Uso", href: "/suporte/termos" },
                { label: "Política de Privacidade", href: "/suporte/privacidade" },
            ]} />
        </GridItem>
    )
}