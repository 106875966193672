import CronForm from "../../components/dashboard/cron/cronForm"
import { Heading, Box } from "@chakra-ui/react"
import { useToastContext } from "../../context/ToastContext"
import CronService from "../../services/backend/CronService"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

function CronCreate() {
    const { showToast } = useToastContext()
    const [cron, setCron] = useState({})
    const { id } = useParams()
    const [action, setAction] = useState('create')
    const navigate = useNavigate()

    const handleCreate = async (data) => {
        try {
            var response;

            if (action === 'create') response = await CronService.createCron({ ...data })
            if (action === 'edit') response = await CronService.updateCronById(data._id, { ...data })

            navigate('/dashboard/cronograma')
            showToast({
                title: data.title,
                description: response.message,
                status: 'success'
            })
        } catch (error) {
            showToast({
                title: data.title || 'Erro ao criar',
                description: error.message,
                status: 'error'
            })
        }
    }

    useEffect(() => {

        if (id) {
            CronService.getCronById(id)
                .then(response => {
                    setCron(response)
                    setAction('edit')
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [id])

    return (

        <Box>
            <Heading
                p={4}
                borderWidth="1px"
                borderRadius="md"
                fontSize='md'
                boxShadow='md'
                bgColor='white'>
                {action === 'create' ? 'Novo cronograma' : `Editar > ${cron.title}`}
            </Heading>

            <CronForm
                cron={cron}
                action={action}
                onSubmit={handleCreate}
                p={8}
                mt={10}
                marginBottom={20}
                width='100%'
                mx="auto"
                bg="white"
                shadow="md"
                borderRadius="md"
                borderColor="gray" />
        </Box>
    )
}

export default CronCreate