import React, { createContext, useContext, useEffect } from 'react'
import useLoadSiteConfig  from '../hooks/siteConfig/useLoadSiteConfig'

const AnnouncementContext = createContext('announcement')

export const AnnouncementProvider = ({ children }) => {
    const [siteConfig, setSiteConfig] = React.useState({});
    const { loadSiteConfig } = useLoadSiteConfig();

    useEffect(() => {
        loadSiteConfig().then((config) => {
            setSiteConfig(config)
        });
    }, [])

    return (
        <AnnouncementContext.Provider value={{siteConfig, setSiteConfig, loadSiteConfig}}>
            {children}
        </AnnouncementContext.Provider>
    )
}

export const useAnnouncementContext = () => useContext(AnnouncementContext)