import React, { useEffect, useState } from 'react';
import { Box,SimpleGrid, Heading, HStack, IconButton } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import CronDetails from '../../components/dashboard/cron/view/cronDetails'
import CronEquips from '../../components/dashboard/cron/view/cronEquips'
import CronScenes from '../../components/dashboard/cron/view/cronScenes'
import CronTeam from '../../components/dashboard/cron/view/cronTeam'
import CronScript from '../../components/dashboard/cron/view/cronScript'
import CronLocations from '../../components/dashboard/cron/view/cronLocations'
import CronNote from '../../components/dashboard/cron/view/cronNote';

import { FaPrint } from "react-icons/fa";

const CronView = () => {
  const { id } = useParams();
  const [cronograma, setCronograma] = useState({
    _id: 'cron1',
    title: 'Filmagem de Ação',
    timestart: new Date('2024-08-13:00:00Z'),
    timend: new Date('2024-09-10T18:00:00Z'),
    scenes: [
      { _id: 'cena1', order: 1, duration: 120, description: 'Cena de abertura', content: 'Introdução ao filme', voiceover: 'Narração inicial', image: 'https://via.placeholder.com/150', recorded: false, script: 'script1', user: 'user1' },
      { _id: 'cena2', order: 2, duration: 90, description: 'Entrevista com o especialista', content: 'Entrevista com um especialista sobre o tema', voiceover: '', image: 'https://via.placeholder.com/150', recorded: false, script: 'script2', user: 'user2' },
      { _id: 'cena3', order: 3, duration: 110, description: 'Ação principal', content: 'Sequência de ação principal do filme', voiceover: 'Narração sobre a ação', image: 'https://via.placeholder.com/150', recorded: false, script: 'script3', user: 'user3' },
      { _id: 'cena4', order: 4, duration: 80, description: 'Cena de encerramento', content: 'Encerramento com créditos', voiceover: '', image: 'https://via.placeholder.com/150', recorded: false, script: 'script4', user: 'user1' },
      { _id: 'cena5', order: 5, duration: 100, description: 'Cena de comédia', content: 'Cena de comédia com o elenco', voiceover: 'Comentário humorístico', image: 'https://via.placeholder.com/150', recorded: false, script: 'script5', user: 'user2' },
      { _id: 'cena6', order: 6, duration: 95, description: 'Promoção', content: 'Cena para promover o produto', voiceover: '', image: 'https://via.placeholder.com/150', recorded: false, script: 'script6', user: 'user3' }
    ],
    locations: [
      { _id: 'loc1', name: 'Estúdio Central', address: { streetname: 'Rua das Flores', streetnumber: 123, hood: 'Jardim das Acácias', city: 'São Paulo', state: 'SP' }, rep: { name: 'João Silva', phone: 123456789, email: 'joao@exemplo.com' }, photo: 'https://via.placeholder.com/150', description: 'Estúdio principal para gravações.', user: 'user1' },
      { _id: 'loc2', name: 'Praia do Leste', address: { streetname: 'Avenida Beira-Mar', streetnumber: 456, hood: 'Centro', city: 'Rio de Janeiro', state: 'RJ' }, rep: { name: 'Maria Oliveira', phone: 987654321, email: 'maria@exemplo.com' }, photo: 'https://via.placeholder.com/150', description: 'Praia para filmagens externas.', user: 'user2' },
      { _id: 'loc3', name: 'Café da Esquina', address: { streetname: 'Rua da Paz', streetnumber: 789, hood: 'Centro Histórico', city: 'Belo Horizonte', state: 'MG' }, rep: { name: 'Carlos Almeida', phone: 456789123, email: 'carlos@exemplo.com' }, photo: 'https://via.placeholder.com/150', description: 'Café para cenas internas.', user: 'user3' }
    ],
    equips: [
      { _id: 'equip1', type: 'Câmera', model: 'Canon EOS R5', quantity: 2, user: 'user1' },
      { _id: 'equip2', type: 'Microfone', model: 'Rode NTG4+', quantity: 5, user: 'user2' },
      { _id: 'equip3', type: 'Iluminação LED', model: 'Neewer 660', quantity: 4, user: 'user3' },
      { _id: 'equip4', type: 'Tripé', model: 'Manfrotto MT190XPRO4', quantity: 3, user: 'user1' },
      { _id: 'equip5', type: 'Drones', model: 'DJI Mavic Air 2', quantity: 1, user: 'user2' },
      { _id: 'equip6', type: 'Tela Verde', model: 'Elgato Green Screen', quantity: 2, user: 'user3' }
    ],
    user: { _id: 'user1', name: 'Ana Pereira', email: 'ana@exemplo.com' },
    notes: [
      { _id: '1', name: 'Nota de Logística', message: 'Confirmar disponibilidade de equipamento', createdAt: new Date() },
      { _id: '2', name: 'Nota de Direção', message: 'Agendar ensaio com o elenco', createdAt: new Date() }
    ],
    script: { 
      _id: 'script1', 
      title: 'Roteiro de Ação', 
      content: 'Detalhes do roteiro de ação', 
      author: 'Autor A', 
      customer: {
        fullname: 'Teste do Teste'
      } 
    },
    team: [
      { _id: 'team1', name: 'João Souza', function: 'Diretor' },
      { _id: 'team2', name: 'Fernanda Lima', function: 'Roteirista' },
      { _id: 'team3', name: 'Roberto Campos', function: 'Diretor de Fotografia' },
      { _id: 'team4', name: 'Claudia Rocha', function: 'Assistente de Produção' },
      { _id: 'team5', name: 'Marcelo Almeida', function: 'Gaffer' }
    ],
    customer: {
      fullname: 'Teste do Teste'
    }
  })

  useEffect(() => {
    
  })

  const handlePrint = () => {
  }

  return (
    <Box p={5} width='100%' overflowY='auto' id='printable'>
        <HStack mb={6}>
            <IconButton icon={<FaPrint />} onClick={handlePrint} />
        </HStack>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <Box>
          <CronDetails 
            title={cronograma.title} 
            timestart={cronograma.timestart} 
            timend={cronograma.timend} 
            customer={cronograma.customer} 
          />
        </Box>
        <Box>
          <CronScript script={cronograma.script} />
        </Box>
        <Box gridColumn={{ base: 'span 2', md: 'span 2' }}>
          <CronScenes scenes={cronograma.scenes} />
        </Box>
        <Box gridColumn={{ base: 'span 2', md: 'span 2' }}>
          <CronLocations locations={cronograma.locations} />
        </Box>
        <Box gridColumn={{ base: 'span 2', md: 'span 2' }}>
          <CronEquips equips={cronograma.equips} />
        </Box>
        <Box gridColumn={{ base: 'span 2', md: 'span 2' }}>
          <CronTeam team={cronograma.team} />
        </Box>

        <Box gridColumn={{ base: 'span 2', md: 'span 2' }} bgColor='white'>
          <Box boxShadow='md' padding={10}>
            <Heading size="md">Notas</Heading>
            <SimpleGrid columns={4} spacing={4}>
            {cronograma.notes.map(note => (
                <CronNote note={note} />
            ))}
            </SimpleGrid>
          </Box>
        </Box>
        
      </SimpleGrid>
    </Box>
  );
};

export default CronView;
