import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    VStack,
} from '@chakra-ui/react'

const PopoverUi = ({ element, children }) => {

    return (
        <Popover>
            <PopoverTrigger>
                {element}
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody p={5}>
                    <VStack align='start'>
                        {children}
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default PopoverUi