import React from "react";
import { IconButton, Link, ButtonGroup } from "@chakra-ui/react";
import { RiCustomerServiceLine, RiMailLine } from "react-icons/ri";
import { FaInstagram, FaYoutube } from "react-icons/fa";

const SocialIcons = ({ options = [], ...props }) => {
    // Mapeamento de ícones
    const iconMap = {
        instagram: FaInstagram,
        youtube: FaYoutube,
        email: RiMailLine,
        suporte: RiCustomerServiceLine,
    };

    if (!options || options.length === 0) {
        return null;
    }

    return (
        <ButtonGroup {...props} variant="outline" colorScheme="teal">
            {options.map(({ name, link, }, index) => {
                const Icon = iconMap[name.toLowerCase()];
                if (!Icon) return null;

                return (
                    <Link key={index} href={link} isExternal>
                        <IconButton
                            icon={<Icon />}
                            size={"sm"}
                            aria-label={name}
                        />
                    </Link>
                );
            })}
        </ButtonGroup>
    );
};

export default SocialIcons;
