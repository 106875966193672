import React, { useState, useEffect } from "react";
import { ButtonGroup, TableContainer, VStack, Table, Tbody, Tr, Td, Heading, IconButton, Link, Button, Spacer, Icon, HStack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { EditIcon } from "@chakra-ui/icons";
import { ReactComponent as CronIcon } from '../../../assets/icons/cron.svg'

import useGetLatestCrons from "../../../hooks/crons/useGetLatestCrons";

export default function LatestCrons() {
    const [crons, setCrons] = useState([]);
    const { getLatestCrons } = useGetLatestCrons();

    useEffect(() => {
        getLatestCrons().then((response) => {
            setCrons(response);
        });
    }, []);

    return (
        <VStack
            bgColor={"white"}
            borderRadius={"md"}
            p={4}
            minHeight={"250px"}
            alignItems={"start"}
            justifyContent={"start"}
            boxShadow={"md"}
        >


            <HStack width={"100%"}>
                <Icon as={CronIcon} />
                <Heading size={"xs"} textTransform={"uppercase"}>Cronogramas recentes</Heading>
            </HStack>

            <TableContainer width={"100%"}>
                <Table variant={"simple"} size={"sm"}>
                    {/* <Thead>
                        <Tr>
                            <Td fontWeight={"bold"}>Titulo</Td>
                            <Td fontWeight={"bold"}>Termino</Td>
                            <Td></Td>
                        </Tr>
                    </Thead> */}
                    <Tbody>
                        {crons && crons.map((cron) => (
                            <Tr key={cron._id}>
                                <Td textAlign={"center"}>{cron.title}</Td>
                                {/* <Td textAlign={"center"}><DateDisplay date={cron.timend} /></Td> */}
                                <Td textAlign={"center"}>
                                    <Link as={RouterLink} to={`/dashboard/cronograma/editar/${cron._id}`} state={{ cron }}>
                                        <IconButton variant={"ghost"} icon={<EditIcon color={"gray.400"} />} />
                                    </Link>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>

            <Spacer />

            <ButtonGroup mt={4}>
                <Button as={RouterLink} to={`/dashboard/cronograma`} variant={"outline"} size={"xs"}>Ver todos</Button>
            </ButtonGroup>
        </VStack>
    )
}