// import React, { useEffect, useState } from 'react';
// import {
// 	Box,
// 	Button,
// 	FormControl,
// 	FormLabel,
// 	Input,
// 	VStack,
// 	FormErrorMessage,
// 	Select,
// 	IconButton,
// 	HStack
// } from '@chakra-ui/react';
// import AsyncSelect from 'react-select/async';
// import { FaTrash } from 'react-icons/fa';

// import { useToastContext } from '../../../context/ToastContext';
// import { getScriptsAll } from "../../../services/backend/ScriptService";
// import { getScenesAll } from '../../../services/backend/ScenesService';
// import { getLocationsAll } from '../../../services/backend/LocationsService';
// import { getAvaliableEquips } from '../../../services/backend/EquipamentsService';

// function CronForm({ cron, onSubmit, action = 'create', ...rest }) {
// 	const [formErrors, setFormErrors] = useState({});
// 	const [formData, setFormData] = useState({
// 		title: '',
// 		timestart: '',
// 		timend: '',
// 		scenes: [],
// 		locations: [],
// 		equips: [],
// 		script: '',
// 		team: [],
// 	});

// 	const { showToast } = useToastContext();

// 	useEffect(() => {
// 		if (action === 'edit' && cron) {
// 			setFormData({
// 				...cron,
// 				title: cron.title || '',
// 				timestart: formatDateTimeForInput(cron.timestart),
// 				timend: formatDateTimeForInput(cron.timend),
// 				scenes: cron.scenes ? cron.scenes.map(scene => ({ label: scene.content || scene.description, value: scene._id })) : [],
// 				locations: cron.locations ? cron.locations.map(location => ({ label: location.name, value: location._id })) : [],
// 				equips: cron.equips ? cron.equips.map(equip => ({ label: equip.model, value: equip._id })) : [],
// 				script: cron.script ? { label: cron.script.title, value: cron.script._id } : '',
// 				team: cron.team || []
// 			});
// 		} else if (action === 'create') {
// 			setFormData({
// 				title: '',
// 				timestart: '',
// 				timend: '',
// 				scenes: [],
// 				locations: [],
// 				equips: [],
// 				script: '',
// 				team: []
// 			});
// 		}
// 	}, [cron, action]);

// 	const [roles] = useState([
// 		{ label: 'Diretor', value: 'Diretor' },
// 		{ label: 'Produtor', value: 'Produtor' },
// 		{ label: 'Roteirista', value: 'Roteirista' },
// 		{ label: 'Assistente de Produção', value: 'Assistente de Produção' },
// 		{ label: 'Assistente de Direção', value: 'Assistente de Direção' },
// 		{ label: 'Diretor de Fotografia', value: 'Diretor de Fotografia' },
// 		{ label: 'Continuísta', value: 'Continuísta' },
// 		{ label: 'Técnico de Som', value: 'Técnico de Som' },
// 		{ label: 'Diretor de Arte', value: 'Diretor de Arte' },
// 		{ label: 'Operador de Câmera', value: 'Operador de Câmera' },
// 		{ label: 'Assistente de Câmera', value: 'Assistente de Câmera' },
// 		{ label: 'Figurinista', value: 'Figurinista' },
// 		{ label: 'Gaffer', value: 'Gaffer' }
// 	]);

// 	const fetchOptions = async (inputValue, model) => {
// 		try {
// 			switch (model) {
// 				case 'scripts':
// 					const scriptsResponse = await getScriptsAll({ searchQuery: inputValue });
// 					return scriptsResponse.docs;

// 				case 'scenes':
// 					if (!formData.script) {
// 						showToast({ description: 'Selecione um roteiro!', status: 'warning' });
// 						return [];
// 					}
// 					const scenesResponse = await getScenesAll({ searchQuery: inputValue, script: formData.script.value });
// 					return scenesResponse.docs;

// 				case 'locations':
// 					const locationsResponse = await getLocationsAll({ searchQuery: inputValue });
// 					return locationsResponse.docs;

// 				case 'equips':
// 					const equipsResponse = await getAvaliableEquips({ searchQuery: inputValue });
// 					return equipsResponse.docs;

// 				default:
// 					return [];
// 			}
// 		} catch (error) {
// 			showToast({ title: 'Problema para carregar dados', description: error.message, status: 'error' });
// 			return [];
// 		}
// 	};

// 	const loadOptions = async (inputValue, key) => {
// 		if (!inputValue) return [];
// 		const response = await fetchOptions(inputValue, key);
// 		return response.map(item => ({
// 			label: item.title || item.name || item.description || item.model,
// 			value: item._id,
// 		}));
// 	};

// 	const handleChange = (selectedOption, name) => {
// 		setFormData({ ...formData, [name]: selectedOption || '' });
// 		setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
// 	};

// 	const handleMultiChange = (selectedOptions, name) => {
// 		setFormData({ ...formData, [name]: selectedOptions || [] });
// 		setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
// 	};

// 	const handleTeamChange = (index, field, value) => {
// 		const updatedTeam = [...formData.team];
// 		updatedTeam[index][field] = value;
// 		setFormData({ ...formData, team: updatedTeam });
// 	};

// 	const handleAddTeamMember = () => {
// 		setFormData({ ...formData, team: [...formData.team, { name: '', function: '' }] });
// 	};

// 	const handleRemoveTeamMember = (index) => {
// 		const updatedTeam = formData.team.filter((_, i) => i !== index);
// 		setFormData({ ...formData, team: updatedTeam });
// 	};

// 	const validateForm = () => {
// 		const errors = {};
// 		if (!formData.title) errors.title = 'Título é obrigatório';
// 		if (!formData.timestart) errors.timestart = 'Data de início é obrigatória';
// 		if (!formData.timend) errors.timend = 'Data de término é obrigatória';
// 		if (formData.scenes.length === 0) errors.scenes = 'Selecione ao menos uma cena';
// 		if (!formData.script) errors.script = 'Selecione um roteiro';

// 		if (formData.team.length > 0) {
// 			var memberWithErrors = formData.team.filter(member => {
// 				return member.name === '' || member.function === ''
// 			})

// 			if (memberWithErrors.length > 0) errors.team = 'Todos os membros precisam ter uma função definida'
// 		}

// 		setFormErrors(errors);
// 		return Object.keys(errors).length === 0;
// 	};

// 	const handleSubmit = (e) => {
// 		e.preventDefault();
// 		if (validateForm()) {
// 			// Converta `scenes`, `locations`, `equips` e `script` para arrays de IDs
// 			const updatedFormData = {
// 				...formData,
// 				scenes: formData.scenes.map(scene => scene.value), // Extrai IDs das cenas
// 				locations: formData.locations.map(location => location.value), // Extrai IDs dos locais
// 				equips: formData.equips.map(equip => equip.value), // Extrai IDs dos equipamentos
// 				script: formData.script ? formData.script.value : '', // Extrai ID do roteiro (ou usa uma string vazia se não estiver selecionado)
// 			};

// 			// Chama a função de submit com os dados atualizados
// 			onSubmit(updatedFormData);
// 		}
// 	};

// 	return (
// 		<Box {...rest} spacing={5} align='start'>
// 			<form onSubmit={handleSubmit}>
// 				<VStack spacing={4} align='start'>
// 					{/* Título */}
// 					<FormControl isInvalid={formErrors.title} isRequired>
// 						<FormLabel>Título</FormLabel>
// 						<Input
// 							name="title"
// 							value={formData.title}
// 							onChange={(e) => handleChange(e.target.value, 'title')}
// 							placeholder="Título do Cronograma"
// 						/>
// 						<FormErrorMessage>{formErrors.title}</FormErrorMessage>
// 					</FormControl>

// 					<HStack width="100%">
// 						{/* Data de Início */}
// 						<FormControl isInvalid={formErrors.timestart} isRequired width="50%">
// 							<FormLabel>Data de Início</FormLabel>
// 							<Input
// 								type="datetime-local"
// 								name="timestart"
// 								value={formData.timestart}
// 								onChange={(e) => handleChange(e.target.value, 'timestart')}
// 							/>
// 							<FormErrorMessage>{formErrors.timestart}</FormErrorMessage>
// 						</FormControl>

// 						{/* Data de Término */}
// 						<FormControl isInvalid={formErrors.timend} isRequired width="50%">
// 							<FormLabel>Data de Término</FormLabel>
// 							<Input
// 								type="datetime-local"
// 								name="timend"
// 								value={formData.timend}
// 								onChange={(e) => handleChange(e.target.value, 'timend')}
// 							/>
// 							<FormErrorMessage>{formErrors.timend}</FormErrorMessage>
// 						</FormControl>
// 					</HStack>

// 					{/* Membros da Equipe */}
// 					<FormControl isInvalid={formErrors.team}>
// 						<FormLabel>Membros da Equipe</FormLabel>
// 						{formData.team.map((member, index) => (
// 							<HStack key={index} spacing={4} mb={3}>
// 								<Input
// 									name="name"
// 									value={member.name}
// 									onChange={(e) => handleTeamChange(index, 'name', e.target.value)}
// 									placeholder="Nome"
// 								/>
// 								<Select
// 									name="function"
// 									value={member.function}
// 									onChange={(e) => handleTeamChange(index, 'function', e.target.value)}
// 								>
// 									<option>Selecione uma função</option>
// 									{roles.map(role => (
// 										<option key={role.value} value={role.value}>
// 											{role.label}
// 										</option>
// 									))}
// 								</Select>
// 								<IconButton
// 									icon={<FaTrash />}
// 									aria-label="Remover Membro da Equipe"
// 									onClick={() => handleRemoveTeamMember(index)}
// 								/>
// 							</HStack>
// 						))}

// 						<FormErrorMessage>{formErrors.team}</FormErrorMessage>
// 						<Button mt={4} onClick={handleAddTeamMember}>Adicionar Membro</Button>
// 					</FormControl>

// 					{/* Roteiro */}
// 					<FormControl isInvalid={formErrors.script} isRequired>
// 						<FormLabel>Roteiro</FormLabel>
// 						<AsyncSelect
// 							cacheOptions
// 							defaultOptions
// 							value={formData.script}
// 							loadOptions={(inputValue) => loadOptions(inputValue, 'scripts')}
// 							onChange={(option) => handleChange(option, 'script')}
// 						/>
// 						<FormErrorMessage>{formErrors.script}</FormErrorMessage>
// 					</FormControl>

// 					{/* Cenas */}
// 					<FormControl isInvalid={formErrors.scenes} isRequired>
// 						<FormLabel>Cenas</FormLabel>
// 						<AsyncSelect
// 							cacheOptions
// 							defaultOptions
// 							isMulti
// 							value={formData.scenes}
// 							loadOptions={(inputValue) => loadOptions(inputValue, 'scenes')}
// 							onChange={(options) => handleMultiChange(options, 'scenes')}
// 						/>
// 						<FormErrorMessage>{formErrors.scenes}</FormErrorMessage>
// 					</FormControl>

// 					{/* Locais */}
// 					<FormControl>
// 						<FormLabel>Locais</FormLabel>
// 						<AsyncSelect
// 							cacheOptions
// 							defaultOptions
// 							isMulti
// 							value={formData.locations}
// 							loadOptions={(inputValue) => loadOptions(inputValue, 'locations')}
// 							onChange={(options) => handleMultiChange(options, 'locations')}
// 						/>
// 					</FormControl>

// 					{/* Equipamentos */}
// 					<FormControl>
// 						<FormLabel>Equipamentos</FormLabel>
// 						<AsyncSelect
// 							cacheOptions
// 							defaultOptions
// 							isMulti
// 							value={formData.equips}
// 							loadOptions={(inputValue) => loadOptions(inputValue, 'equips')}
// 							onChange={(options) => handleMultiChange(options, 'equips')}
// 						/>
// 					</FormControl>

// 					{/* Botão de Envio */}
// 					<Button type="submit" colorScheme="teal">
// 						{action === 'create' ? 'Salvar' : 'Salvar Alterações'}
// 					</Button>
// 				</VStack>
// 			</form>
// 		</Box>
// 	);
// }

// function formatDateTimeForInput(dateTime) {
// 	if (!dateTime) return '';
// 	const date = new Date(dateTime);
// 	const year = date.getFullYear();
// 	const month = String(date.getMonth() + 1).padStart(2, '0');
// 	const day = String(date.getDate()).padStart(2, '0');
// 	const hours = String(date.getHours()).padStart(2, '0');
// 	const minutes = String(date.getMinutes()).padStart(2, '0');
// 	return `${year}-${month}-${day}T${hours}:${minutes}`;
// }

// export default CronForm;
import React from 'react';
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Checkbox,
	VStack,
	FormErrorMessage,
	Select,
	IconButton,
	HStack,
} from '@chakra-ui/react';
import AsyncSelect from 'react-select/async';
import { FaTrash } from 'react-icons/fa';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';

import { useToastContext } from '../../../context/ToastContext';
import ScriptService from '../../../services/backend/ScriptService';
import { getScenesAll } from '../../../services/backend/ScenesService';
import { getLocationsAll } from '../../../services/backend/LocationsService';
import { getAvaliableEquips } from '../../../services/backend/EquipamentsService';
import { formatDateForInput } from '../../ui/DateDisplay';

function CronForm({ cron, onSubmit, action = 'create', ...rest }) {
	const { showToast } = useToastContext();

	// Lista de funções disponíveis para os membros da equipe
	const teamRoles = [
		'Diretor',
		'Produtor',
		'Assistente de Produção',
		'Cinegrafista',
		'Editor de Vídeo',
		'Designer de Som',
		'Roteirista',
		'Assistente de Câmera',
		'Iluminador',
		'Maquiador',
		'Ator',
		'Dublador',
	];

	const validationSchema = Yup.object().shape({
		script: Yup.object()
			.shape({
				label: Yup.string().required('O nome do roteiro é obrigatório'),
				value: Yup.string().required('O ID do roteiro é obrigatório'),
			})
			.nullable()
			.required('Selecione um roteiro'),

		scenes: Yup.array()
			.of(
				Yup.object().shape({
					label: Yup.string().required('O nome da cena é obrigatório'),
					value: Yup.string().required('O ID da cena é obrigatório'),
				})
			)
			.min(1, 'Selecione pelo menos uma cena')
			.required('Cenas são obrigatórias'),

		locations: Yup.array()
			.of(
				Yup.object().shape({
					label: Yup.string(),
					value: Yup.string(),
				})
			)
			.nullable(),

		equips: Yup.array()
			.of(
				Yup.object().shape({
					equip: Yup.object()
						.shape({
							label: Yup.string().required('O nome do equipamento é obrigatório'),
							value: Yup.string().required('O ID do equipamento é obrigatório'),
						})
						.nullable()
						.required('Selecione um equipamento'),
					inWork: Yup.number()
						.min(1, 'A quantidade mínima é 1')
						.required('A quantidade é obrigatória'),
				})
			)
			.nullable(),
	});



	// Carregar dados para AsyncSelect
	const fetchOptions = async (inputValue, model, values) => {
		try {
			switch (model) {
				case 'script':
					return (await ScriptService.getScriptsAll({ searchQuery: inputValue })).docs;
				case 'scenes':
					return (await getScenesAll({ script: values.script?.value })).docs;
				case 'locations':
					return (await getLocationsAll({ searchQuery: inputValue })).docs;
				case 'equips':
					return (await getAvaliableEquips({ searchQuery: inputValue })).docs;
				default:
					return [];
			}
		} catch (error) {
			showToast({ title: 'Erro ao carregar dados', description: error.message, status: 'error' });
			return [];
		}
	};

	const convertKey = (key) => {
		switch (key) {
			case 'Roteiro':
				return 'script';
			case 'Cenas':
				return 'scenes';
			case 'Locações':
				return 'locations';
			case 'Equipamentos':
				return 'equips';
			default:
				return key;
		}
	}

	const loadOptions = async (inputValue, key, values) => {
		const response = await fetchOptions(inputValue, convertKey(key), values);
		return response.map((item) => ({
			label: item.title || item.name || item.description || item.model,
			value: item._id,
		}));
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				_id: cron?._id || '',
				title: cron?.title || '',
				timestart: formatDateForInput(cron?.timestart) || '',
				timend: formatDateForInput(cron?.timend) || '',
				script: cron?.script
					? { label: cron.script.title, value: cron.script._id }
					: null,
				scenes: cron?.scenes?.map((scene) => ({
					label: scene.content || scene.description,
					value: scene._id,
				})) || [],
				locations: cron?.locations?.map((location) => ({
					label: location.name,
					value: location._id,
				})) || [],
				equips: cron?.equips?.map((equip) => ({
					equip: {
						value: equip.equip._id, 
						label: equip.equip.model,  
					},
					inWork: equip.inWork, 
				})) || [],
				team: cron?.team || [],
			}}
			validationSchema={validationSchema}
			onSubmit={(values) => {
				const updatedData = {
					...values,
					script: values.script?.value || '',
					scenes: values.scenes.map((scene) => scene.value),
					locations: values.locations.map((location) => location.value),
					equips: values.equips.map((equip) => {
						return {
							equip: equip.equip.value,
							inWork: equip.inWork,
						};
					}),
				};
				onSubmit(updatedData);
				console.log(updatedData)
			}}
		>
			{({ values, setFieldValue, errors, touched }) => (
				<Form>
					<Box {...rest}>
						<VStack spacing={4} align="start">
							{/* Título */}
							<FormControl isInvalid={touched.title && errors.title} isRequired>
								<FormLabel>Título</FormLabel>
								<Field as={Input} name="title" placeholder="Título do Cronograma" />
								<FormErrorMessage>{errors.title}</FormErrorMessage>
							</FormControl>

							<HStack width="100%">
								{/* Data de Início */}
								<FormControl isInvalid={touched.timestart && errors.timestart} isRequired>
									<FormLabel>Data de Início</FormLabel>
									<Field as={Input} type="date" name="timestart" />
									<FormErrorMessage>{errors.timestart}</FormErrorMessage>
								</FormControl>

								{/* Data de Término */}
								<FormControl isInvalid={touched.timend && errors.timend} isRequired>
									<FormLabel>Data de Término</FormLabel>
									<Field as={Input} type="date" name="timend" />
									<FormErrorMessage>{errors.timend}</FormErrorMessage>
								</FormControl>
							</HStack>

							{/* Campos com AsyncSelect */}
							{['Roteiro', 'Cenas', 'Locações'].map((key) => (
								<FormControl 
								key={key} 
								isInvalid={touched[convertKey(key)] && errors[convertKey(key)]} 
								isRequired={convertKey(key) === 'script' || convertKey(key) === 'scenes'}
								>
									<FormLabel>{key.charAt(0).toUpperCase() + key.slice(1)}</FormLabel>
									<AsyncSelect
										cacheOptions
										defaultOptions
										noOptionsMessage={() => 'Digite para pesquisar'}
										loadingMessage={() => 'Procurando...'} 
										isMulti={convertKey(key) !== 'script'}
										loadOptions={(inputValue) => loadOptions(inputValue, key, values)}
										value={values[convertKey(key)]}
										onChange={(option) => setFieldValue(convertKey(key), option)}
									/>
									<FormErrorMessage>{errors[convertKey(key)]}</FormErrorMessage>
								</FormControl>
							))}

							{/* Equipe */}
							<FieldArray name="team">
								{({ remove, push }) => (
									<FormControl>
										<FormLabel>Membros da Equipe</FormLabel>
										{values.team.map((member, index) => (
											<HStack key={index} spacing={4} my={3}>
												<Field as={Input} name={`team[${index}].name`} placeholder="Nome" />
												<Field as={Select} name={`team[${index}].function`}>
													<option value="">Selecione uma função</option>
													{teamRoles.map((role) => (
														<option key={role} value={role}>
															{role}
														</option>
													))}
												</Field>
												<IconButton
													icon={<FaTrash />}
													aria-label="Remover"
													onClick={() => remove(index)}
												/>
											</HStack>
										))}
										<Button mt={4} onClick={() => push({ name: '', function: '' })}>
											Adicionar Membro
										</Button>
									</FormControl>
								)}
							</FieldArray>

							{/* Equips */}
							<FormControl isInvalid={touched.equips && errors.equips}>
								<FormLabel>Equipamentos</FormLabel>
								<Field name="equips">
									{({ field, form }) => (
										<FieldArray name="equips">
											{({ remove, push }) => (
												<>
													{values.equips.map((equip, index) => (
														<HStack key={index} spacing={4} my={3}>
															<AsyncSelect
																styles={{
																	container: (base) => ({
																		...base,
																		width: '100%',
																	})
																}}
																cacheOptions
																defaultOptions
																loadOptions={(inputValue) =>
																	loadOptions(inputValue, 'Equipamentos', values)
																}
																value={values.equips[index].equip}
																onChange={(selectedOption) =>
																	form.setFieldValue(
																		`equips[${index}].equip`,
																		selectedOption
																	)
																}
																placeholder="Selecione um equipamento"
															/>
															<Field
																as={Input}
																type="number"
																name={`equips[${index}].inWork`}
																placeholder="Quantidade"
															/>
															<IconButton
																icon={<FaTrash />}
																aria-label="Remover"
																onClick={() => remove(index)}
															/>
														</HStack>
													))}
													<Button
														mt={2}
														onClick={() => push({ equipId: null, inWork: 1 })}
													>
														Adicionar Equipamento
													</Button>
												</>
											)}
										</FieldArray>
									)}
								</Field>
								<FormErrorMessage>{errors.equips}</FormErrorMessage>
							</FormControl>


							<Button type="submit" colorScheme="teal">
								{action === 'create' ? 'Salvar' : 'Salvar Alterações'}
							</Button>
						</VStack>
					</Box>
				</Form>
			)}
		</Formik>
	);
}

export default CronForm;