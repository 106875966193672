import { FormControl, FormErrorMessage, Input, Textarea, Box, HStack, Button } from "@chakra-ui/react"
import { useState } from "react"

function CronNoteForm({onSubmit, ...rest}) {
    const [formData, setFormData] = useState({
        title: '',
        message: ''
    })
    const [formErrors, setFormErrors] = useState({
        title: '',
        message: ''
    })

    const handleChange = (e) => {
        e.preventDefault()
        const {name, value} = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const validateNote = () => {
        let errors = {}

        if(!formData.title) errors.title = 'Preencha o titulo'
        if(!formData.message) errors.message = 'Preencha a mensagem'

        setFormErrors(errors)
        return Object.keys(errors).length === 0
    }

    const handleSubmit = () => {
        if(validateNote()) {
            onSubmit(formData)
        }
    }

    return (
        <Box {...rest}>
            <FormControl isInvalid={formErrors.title} isRequired>
                <Input name="title" type="text" placeholder="Digite um titulo" onChange={(e) => handleChange(e)} value={formData.title} />
                <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>

            <FormControl mt={6} isInvalid={formErrors.title} isRequired>
                <Textarea name="message" onChange={(e) => handleChange(e)} placeholder="Ex: Lembrar de conferir a locação X, antes da diária começar." value={formData.message} />
                <FormErrorMessage>{formErrors.title}</FormErrorMessage>
            </FormControl>

            <FormControl mt={6}>
                <HStack>
                    <Button onClick={handleSubmit} colorScheme="teal">Salvar</Button>
                </HStack>
            </FormControl>
        </Box>
    )
}

export default CronNoteForm