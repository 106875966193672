import React, { useState, useEffect, useCallback } from 'react';
import { Box, HStack, Input, Button, Text, SimpleGrid, GridItem } from '@chakra-ui/react';

import { CustomerCard } from './customerCard';
import { CustomerModal } from './customerModal';
import { AddIcon } from '@chakra-ui/icons';

import { useToastContext } from '../../../context/ToastContext';
import { getCustomersAll, deleteCustomerById } from '../../../services/backend/CustomersService'

const ListCustomer = () => {
	const [customers, setCustomers] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const { showToast } = useToastContext()

	const fetchCustomers = useCallback(async (bypass = false) => {

		try {
			const response = await getCustomersAll({
				page: currentPage,
				searchQuery
			})

			if(bypass ||response.docs.length !== customers.length) setCustomers(response.docs)
			setTotalResults(response.totalResults)

		} catch (error) {
			showToast({
				title: 'Algo deu errado',
				description: error.message,
				status: 'error'
			})
		}


	},[searchQuery, currentPage, showToast, customers]);

	useEffect(() => {
		fetchCustomers()
	  }, [fetchCustomers]);

	const handleEdit = (customer) => {
		setSelectedCustomer(customer);
		setIsEditModalOpen(true);
	};

	const handleCreate = () => {
		setSelectedCustomer(null);
		setIsEditModalOpen(true);
	};

	const handleDelete = async (customerId) => {

		try {
			const response = await deleteCustomerById(customerId)
			fetchCustomers()
			showToast({
				title: 'Sucesso',
				description: response.message,
				status: 'success'
			})

		} catch (error) {
			showToast({
				title: 'Algo deu errado',
				description: error.message,
				status: 'error'
			})
		}
	};

	return (
		<Box>

			<HStack spacing={4} p={4} borderWidth="1px" borderRadius="md" boxShadow='md' bgColor='white' mt={4}>

				<Button colorScheme="teal" leftIcon={<AddIcon />} onClick={handleCreate}>Adicionar</Button>

				<Input
					width={300}
					placeholder="Pesquisar..."
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
			</HStack>

			<SimpleGrid templateColumns="repeat(auto-fit, 400px)" columns={5} gap={4} mt={10}>
				{customers.length > 0 && (
					customers.map((customer) => (
						<CustomerCard
							boxShadow='md'
							sx={{ borderWidth: '2px', borderColor: 'white' }}
							_hover={{ borderColor: 'teal' }}
							key={customer._id}
							customer={customer}
							onEdit={() => handleEdit(customer)}
							onDelete={() => handleDelete(customer._id)}
						/>
					))
				)}
			</SimpleGrid>

			{totalResults > 1 && (
				<Box display="flex" justifyContent="space-between" mt={4}>
					<HStack spacing={4} mt={4}>
						<Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>Anterior</Button>
						<Text mx={2}>Página {currentPage}</Text>
						<Button onClick={() => setCurrentPage((prev) => prev + 1)}>Próxima</Button>
					</HStack>
				</Box>
			)}

			{isEditModalOpen && (
				<CustomerModal
					isOpen={isEditModalOpen}
					onClose={() => setIsEditModalOpen(false)}
					customer={selectedCustomer}
					update={fetchCustomers}
				/>
			)}
		</Box>
	);
};

export default ListCustomer;
