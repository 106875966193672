import budgetService from "../../services/backend/BudgetService";

export default function useGetLatestBudgets() {
    const getLatestBudgets = async () => {
        try {
            const response = await budgetService.getLatestBudgets()
            return response
        } catch (error) {
            return {
                error: 'Erro ao carregar os orçamentos.'
            }
        }
    }

    return { getLatestBudgets }
}