import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link, Icon, VStack, HStack, Text, MenuButton, Button, MenuList, MenuItem, Menu } from "@chakra-ui/react";

import { ReactComponent as Script } from '../../assets/icons/script.svg'
import { ReactComponent as Cron } from '../../assets/icons/cron.svg'
import { RiContactsLine } from "react-icons/ri";
import { ReactComponent as Location } from '../../assets/icons/location.svg'
import { ReactComponent as Cog } from '../../assets/icons/cog.svg'
import { ReactComponent as Equips } from '../../assets/icons/equips.svg'
import { ReactComponent as Dashboard } from '../../assets/icons/dash.svg'
import { ReactComponent as Premium } from '../../assets/icons/premium.svg'
import {ReactComponent as Invoice} from '../../assets/icons/invoice.svg'

import useAuthUser from "react-auth-kit/hooks/useAuthUser";

import Main from '../../assets/css/main.css'


function MenuLinks({ ...rest }) {
	const user = useAuthUser()

	const location = useLocation()
	const linkStyle = {
		textDecoration: 'none',
		borderRadius: 5,
	};

	const activeLinkStyle = {
		bgColor: 'teal',
		borderRadius: 5,
	}

	const activePath = location.pathname
	const allPath = activePath.split('/')
	const currentPath = allPath[2];

	return (
		<VStack align="flex-start" mt={50} width='100%'>
			<Link
				sx={currentPath === "home" ? activeLinkStyle : {}}
				as={RouterLink}
				to="/dashboard/home"
				width='100%'
				_hover={{ ...linkStyle, bgColor: activeLinkStyle.bgColor }}
				px={3}
				py={2} >
				<HStack>
					<Icon as={Dashboard} boxSize={5} />
					<Text>Dashboard</Text>
				</HStack>
			</Link>
			<Link
				sx={currentPath === "roteiro" ? activeLinkStyle : {}}
				as={RouterLink}
				width='100%'
				to="/dashboard/roteiro"
				_hover={{ ...linkStyle, bgColor: activeLinkStyle.bgColor }}
				px={3}
				py={2}>

				<HStack>
					<Icon as={Script} boxSize={5} />
					<Text>Roteiros</Text>
				</HStack>

			</Link>

			<Link
				sx={currentPath === "cronograma" ? activeLinkStyle : {}}
				as={RouterLink}
				width='100%'
				to="/dashboard/cronograma"
				_hover={{ ...linkStyle, bgColor: activeLinkStyle.bgColor }}
				px={3}
				py={2}>
				<HStack>
					<Icon as={Cron} color='#31B39F' boxSize={5} />
					<Text>Cronograma</Text>
				</HStack>
			</Link>

			<Link
				sx={currentPath === "equipamentos" ? activeLinkStyle : {}}
				as={RouterLink}
				width='100%'
				to="/dashboard/equipamentos"
				_hover={{ ...linkStyle, bgColor: activeLinkStyle.bgColor }}
				px={3}
				py={2}>
				<HStack>
					<Icon as={Equips} color='#31B39F' boxSize={5} />
					<Text>Equipamentos</Text>
				</HStack>
			</Link>

			<Link
				sx={currentPath === "locacoes" ? activeLinkStyle : {}}
				as={RouterLink}
				width='100%'
				to="/dashboard/locacoes"
				_hover={{ ...linkStyle, bgColor: activeLinkStyle.bgColor }}
				px={3}
				py={2}>
				<HStack>
					<Icon as={Location} color='#31B39F' boxSize={5} />
					<Text>Locações</Text>
				</HStack>
			</Link>

			<Link
				sx={currentPath === "clientes" ? activeLinkStyle : {}}
				as={RouterLink}
				width='100%'
				to="/dashboard/clientes"
				_hover={{ ...linkStyle, bgColor: activeLinkStyle.bgColor }}
				px={3}
				py={2}>
				<HStack>
					<Icon as={RiContactsLine} color='#31B39F' boxSize={5} />
					<Text>Clientes</Text>
				</HStack>
			</Link>

			<Link
				sx={currentPath === "orcamentos" ? activeLinkStyle : {}}
				as={RouterLink}
				width='100%'
				to="/dashboard/orcamentos"
				_hover={{ ...linkStyle, bgColor: activeLinkStyle.bgColor }}
				px={3}
				py={2}>
				<HStack>
					<Icon as={Invoice} color='#31B39F' boxSize={5} />
					<Text>Orçamentos</Text>
				</HStack>
			</Link>
			
			<Link
				sx={currentPath === "premium" ? activeLinkStyle : {}}
				as={RouterLink}
				width='100%'
				to="/dashboard/premium"
				_hover={{ ...linkStyle, bgColor: activeLinkStyle.bgColor }}
				px={3}
				py={2}>
				<HStack>
					<Icon as={Premium} boxSize={5} />
					<Text>Assinatura</Text>
				</HStack>
			</Link>

			<Link
				sx={currentPath === "configuracoes" ? activeLinkStyle : {}}
				as={RouterLink}
				width='100%'
				to="/dashboard/configuracoes"
				_hover={{ ...linkStyle, bgColor: activeLinkStyle.bgColor }}
				px={3}
				py={2}>
				<HStack>
					<Icon as={Cog} boxSize={5} />
					<Text>Configurações</Text>
				</HStack>
			</Link>

			{user.role === 'admin' && (
				<Menu>
					<MenuButton
						as={Button}
						width={'100%'}
						mt={1}
						textColor={'teal'}
						borderColor={'teal'}
						variant={'outline'}>
						Admin
					</MenuButton>
					<MenuList>
						<MenuItem as={RouterLink} to={'/dashboard/admin/planos'} textColor={'teal'}>Planos</MenuItem>
						<MenuItem textColor={'teal'}>Assinaturas</MenuItem>
						<MenuItem textColor={'teal'}>Relatórios</MenuItem>
						<MenuItem as={RouterLink} to={'/dashboard/admin/configuracoes'} textColor={'teal'}>Configurações</MenuItem>
					</MenuList>
				</Menu>
			)}
		</VStack>
	)

}

export default MenuLinks;