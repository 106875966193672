import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";

import PlanCard from "../../premium/planCard";

function ListPlans({plans, setEditable, ...props}) {
    
    return (
        <Box {...props}>
            <SimpleGrid templateColumns="repeat(3, 400px)" gap={4}>
                {plans && plans.map((plan) => (
                    <PlanCard key={plan._id} plan={plan} button_label={'Editar'} callback={setEditable} />
                ))}
            </SimpleGrid>
        </Box>
    );
}

export default ListPlans