import { Routes, Route } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Scripts from './pages/scripts/Scripts';
import Layout from "./pages/layouts/Layout";
import Customer from "./pages/Customer";
import Scenes from "./pages/scripts/Scenes";
import Equipamento from "./pages/Equipamentos";
import Locacao from './pages/Locacao';

import Homepage from "./pages/public/Homepage";

import Config from "./pages/Config";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/forgotPassword";
import ResetPassword from "./pages/auth/resetPassword";

import Cron from "./pages/cron/Cron";
import CronCreate from './pages/cron/create'
import CronView from "./pages/cron/view";


import Public from "./pages/shared/Public";
import Premium from "./pages/premium/Premium";
import Checkout from "./pages/premium/Checkout";

import Budget from "./pages/budget/budget";
import CreateBudget from "./pages/budget/create";
import EditBudget from "./pages/budget/edit";

import Planos from "./pages/admin/Planos";
import SiteConfigs from "./pages/admin/SiteConfigs";

import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import ViewBudget from "./pages/budget/view";
import { AnnouncementProvider } from "./context/AnnouncementContext";
import SiteLayout from "./pages/layouts/SiteLayout";
import Contato from "./pages/public/Contato";
import Privacidade from "./pages/public/suporte/Privacidade";
import Termos from "./pages/public/suporte/Termos";
import Suporte from "./pages/public/Suporte";


function App() {
	return (
		<Routes>
			<Route element={<SiteLayout />} >
				<Route path="/" element={<Homepage />} />
				<Route path="/contato" element={<Contato />} />
				<Route path="/suporte" element={<Suporte />} />
				<Route path="/suporte/privacidade" element={<Privacidade />} />
				<Route path="/suporte/termos" element={<Termos />} />
			</Route>
			<Route path="/login" element={<Login />} />
			<Route path="/registrar" element={<Register />} />
			<Route path="/conta/recuperar" element={<ForgotPassword />} />
			<Route path="/conta/resetar/:token" element={<ResetPassword />} />

			<Route path="/public/roteiro/:id" element={<Public />} />
			<Route path="/checkout" element={<Checkout />} />

			<Route element={<AuthOutlet fallbackPath="/login" />} >

				<Route path="/dashboard" element={<AnnouncementProvider><Layout /></AnnouncementProvider>}>
					<Route path="home" element={<Dashboard />} />

					<Route path="roteiro" element={<Scripts />} />
					<Route path="roteiro/cenas/:id" element={<Scenes />} />

					<Route path="cronograma" element={<Cron />} />
					<Route path="cronograma/criar" element={<CronCreate />} />
					<Route path="cronograma/editar/:id" element={<CronCreate />} />
					<Route path="cronograma/ver/:id" element={<CronView />} />

					<Route path="orcamentos/editar/:id" element={<EditBudget />} />
					<Route path="orcamentos/ver/:id" element={<ViewBudget />} />
					<Route path="orcamentos" element={<Budget />} />
					<Route path="orcamentos/novo" element={<CreateBudget />} />

					<Route path="clientes" element={<Customer />} />
					<Route path="equipamentos" element={<Equipamento />} />
					<Route path="locacoes" element={<Locacao />} />
					<Route path="configuracoes" element={<Config />} />
					<Route path="premium/:show" element={<Premium />} />
					<Route path="premium" element={<Premium />} />

					<Route path="admin/planos" element={<Planos />} />
					<Route path="admin/configuracoes" element={<SiteConfigs />} />
				</Route>

			</Route>
		</Routes>
	);
}

export default App;
