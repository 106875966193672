import { Field, FieldArray, Form, useFormikContext } from "formik";
import { AccordionItem, AccordionButton, AccordionPanel, Box, FormControl, FormLabel, Input, FormErrorMessage, HStack, Checkbox, Button, IconButton, Icon, Select } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

export default function SectionAnnouncement() {
    const { touched, errors, values } = useFormikContext();
    return (
        <AccordionItem bgColor={'white'} borderRadius={'md'} boxShadow={'md'} my={4}>
            <h2>
                <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                        Avisos
                    </Box>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <FieldArray name="announcement">
                    {({ remove, push }) => (
                        <>
                            {values.announcement.map((announcement, index) => (
                                <Box key={index} mb={4}>
                                    <HStack gap={6}>
                                        <FormControl width={'20%'} isInvalid={touched.announcement?.[index]?.title && errors.announcement?.[index]?.title}>
                                            <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Título</FormLabel>
                                            <Field as={Input} name={`announcement.${index}.title`} placeholder="Digite o título" />
                                            <FormErrorMessage>{errors.announcement?.[index]?.title}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl width={'25%'} isInvalid={touched.announcement?.[index]?.message && errors.announcement?.[index]?.message}>
                                            <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Mensagem</FormLabel>
                                            <Field as={Input} name={`announcement.${index}.message`} placeholder="Digite a mensagem" />
                                            <FormErrorMessage>{errors.announcement?.[index]?.message}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl width={'8%'} isInvalid={(touched.announcement?.[index]?.button?.label && errors.announcement?.[index]?.button?.label)}>
                                            <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Botão</FormLabel>
                                            <Field as={Input} name={`announcement.${index}.button.label`} placeholder="Texto" />
                                            <FormErrorMessage>{errors.announcement?.[index]?.button?.label}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl width={'17%'} isInvalid={touched.announcement?.[index]?.button?.link && errors.announcement?.[index]?.button?.link}>
                                            <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Link</FormLabel>
                                            <Field as={Input} name={`announcement.${index}.button.link`} placeholder="Link" />
                                            <FormErrorMessage>{errors.announcement?.[index]?.button?.link}</FormErrorMessage>
                                        </FormControl>

                                        <FormControl width={'8%'} isInvalid={touched.announcement?.[index]?.type && errors.announcement?.[index]?.type}>
                                            <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Tipo</FormLabel>
                                            <Field
                                                as={Select}
                                                name={`announcement.${index}.type`}
                                                placeholder="Selecione o tipo"
                                            >
                                                <option value="success">Sucesso</option>
                                                <option value="info">Informação</option>
                                                <option value="warning">Aviso</option>
                                                <option value="error">Erro</option>
                                            </Field>
                                        </FormControl>

                                        <FormControl width={'8%'} isInvalid={touched.announcement?.[index]?.status && errors.announcement?.[index]?.status}>
                                            <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Status</FormLabel>
                                            <Field
                                                as={Checkbox}
                                                name={`announcement.${index}.status`}
                                                defaultChecked={announcement.status}
                                            >
                                                Ativo
                                            </Field>
                                        </FormControl>

                                        <FormControl width={'8%'} isInvalid={touched.announcement?.[index]?.dimissable && errors.announcement?.[index]?.dimissable}>
                                            <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Dimissível</FormLabel>
                                            <Field 
                                                as={Checkbox}
                                                name={`announcement.${index}.dimissable`}
                                                defaultChecked={announcement.dimissable}
                                            >
                                                Dimissível
                                            </Field>
                                        </FormControl>

                                        <FormControl width={'5%'}>
                                            <IconButton
                                                aria-label="Remover Anuncio"
                                                mt={6}
                                                variant={'ghost'}
                                                icon={<Icon as={DeleteIcon} />}
                                                colorScheme={'red'}
                                                onClick={() => remove(index)}
                                            />
                                        </FormControl>
                                    </HStack>
                                </Box>
                            ))}

                            <IconButton
                                aria-label="Adicionar Anuncio"
                                icon={<AddIcon />}
                                colorScheme={'teal'}
                                onClick={() => push({ title: '', message: '', status: false, dimissable: false, button: { label: '', link: '' } })}
                            />
                        </>
                    )}
                </FieldArray>
            </AccordionPanel>
        </AccordionItem>
    )
}