import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableContainer,
    ButtonGroup,
    Button,
    IconButton
} from '@chakra-ui/react'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { EditIcon, DeleteIcon } from '@chakra-ui/icons'
import DateDisplay from '../../ui/DateDisplay'
import BudgetStatus from './budgetStatus'
import { Link } from 'react-router-dom'

const BudgetTable = ({ budgets, pagination, actions }) => {

    return (
        <TableContainer width='100%' borderWidth="1px" borderRadius="md" bgColor='white' boxShadow='lg' mt={6}>
            <Table colorScheme='gray'>
                <Thead>
                    <Tr>
                        <Th>Serviço</Th>
                        <Th>Cliente</Th>
                        <Th>Preço</Th>
                        <Th>Validade</Th>
                        <Th>Criado</Th>
                        <Th>Status</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {budgets.map(budget => (
                        <Tr key={budget._id}>
                            <Td>
                                <Link as={RouterLink} to={`/dashboard/orcamentos/ver/${budget._id}`} state={{ budget }}>
                                    {budget.serviceType}
                                </Link>
                            </Td>
                            <Td>{budget.client.name}</Td>
                            <Td>R$ {budget.totalCost}</Td>
                            <Td><DateDisplay date={budget.warrantyDate} /></Td>
                            <Td><DateDisplay date={budget.createdAt} /></Td>
                            <Td><BudgetStatus status={budget.status} /></Td>
                            <Td>
                                <IconButton
                                    size='sm'
                                    aria-label='Editar'
                                    icon={<EditIcon />}
                                    mr={2}
                                    onClick={() => actions.edit(budget)}
                                />
                                <IconButton
                                    onClick={() => actions.remove(budget)}
                                    size='sm'
                                    aria-label='Excluir'
                                    icon={<DeleteIcon />}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>

                <Tfoot p={4}>
                    <Tr>
                        <Th>
                            <ButtonGroup size='sm'>
                                <Button onClick={() => pagination.toNextPage()}>Próximo</Button>
                                <Button colorScheme='ghost' textColor={'teal'}>{pagination.page} / {pagination.totalPages}</Button>
                                <Button onClick={() => pagination.toPrevPage()}>Anterior</Button>
                            </ButtonGroup>
                        </Th>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>
    )
}

export default BudgetTable