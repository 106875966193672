import { Box, FormControl, FormLabel, HStack, Input, Select, Stack, Tooltip, Switch } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import React, { useEffect } from "react";

function FormCardType({ gateway, change, ...props }) {

    useEffect(() => {
        if (gateway && gateway.fields) {
            // Monta os campos de cartão de crédito
            gateway.fields.create('cardNumber', { placeholder: '0000 0000 0000 0000' }).mount('cardNumber');
            gateway.fields.create('expirationDate', { placeholder: 'MM/AA' }).mount('expirationDate');
            gateway.fields.create('securityCode', { placeholder: '000' }).mount('securityCode');
        }
    }, [gateway]);

    return (
        <Box {...props}>
            <FormControl>
                <FormLabel>Nome do titular do cartão</FormLabel>
                <Input
                    isRequired
                    name="cardholderName"
                    type="text"
                    placeholder="Nome Completo"
                    onChange={(e) => change({ cardholderName: e.target.value })}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Número do cartão</FormLabel>
                <Stack direction={['column', 'row']}>
                    <Box as="div" id="cardNumber" width={'100%'} height={'40px'} borderRadius={'md'} borderColor={'gray.100'} borderWidth={2}></Box>
                </Stack>
            </FormControl>

            <FormControl>
                <FormLabel>Validade e CVV</FormLabel>
                <Stack direction={['column', 'row']}>
                    <Box as="div" id="expirationDate" width={'50%'} height={'40px'} borderRadius={'md'} borderColor={'gray.100'} borderWidth={2}></Box>
                    <Box as="div" id="securityCode" ml={2} width={'48%'} height={'40px'} borderRadius={'md'} borderColor={'gray.100'} borderWidth={2}></Box>
                </Stack>
            </FormControl>

            <FormControl>
                <FormLabel>Identificação</FormLabel>
                <HStack>
                    <Select id="identificationType" name="identificationType" width={'28%'} onChange={(e) => change({ identificationType: e.target.value })}>
                        <option value="" disabled selected>Documento</option>
                        <option value="CPF">CPF</option>
                        <option value="CNPJ">CNPJ</option>
                    </Select>

                    <Input
                        type="text"
                        id="identificationNumber"
                        name="identificationNumber"
                        width={'70%'}
                        onChange={(e) => change({ identificationNumber: e.target.value })}
                    />
                </HStack>
            </FormControl>

            <FormControl mt={5}>
                <Stack direction={'row'}>
                    <FormLabel>
                        Renovação automatica
                        <Tooltip placement="right" label="Selecione para renovar automaticamente o plano">
                            <QuestionIcon />
                        </Tooltip>
                    </FormLabel>
                    <Switch onChange={(e) => change({ auto_recurring: e.target.checked })} />
                </Stack>
            </FormControl>
        </Box>
    );
}

export default FormCardType;
