import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';

import CustomerForm from './customerForm';
import { useToastContext } from '../../../context/ToastContext'

import { updateCustomerById, createCustomer } from '../../../services/backend/CustomersService';

export const CustomerModal = ({ update, isOpen, onClose, customer }) => {
	const { showToast } = useToastContext()

	const handleSubmit = async (formData) => {
		try {
			var response = {}
			if (customer) response = await updateCustomerById(formData)
			else response = await createCustomer(formData)
			showToast({
				title: 'Sucesso',
				description: response.message,
				status: 'success'
			})

			update(true)
			onClose()

		} catch (error) {
			showToast({
				title: 'Algo deu errado',
				description: error.message,
				status: 'error'
			})
		}

	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent p={30}>
				<ModalHeader>{customer ? 'Editar Cliente' : 'Criar Cliente'}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<CustomerForm customer={customer} onSubmit={handleSubmit} />
				</ModalBody>
				<ModalFooter>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
