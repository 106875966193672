// import React, { useEffect, useState } from 'react';
// import {
// 	Box,
// 	FormControl,
// 	FormLabel,
// 	Input,
// 	FormErrorMessage,
// 	Stack,
// 	InputGroup,
// 	InputRightElement,
// 	VStack,
// 	Button,
// 	Popover,
// 	PopoverTrigger,
// 	PopoverContent,
// 	PopoverArrow,
// 	PopoverCloseButton,
// 	PopoverHeader,
// 	PopoverBody
// } from '@chakra-ui/react';

// const ConfigForm = ({ user, onSubmit, passwordReset, onDelete }) => {
// 	const [formData, setFormData] = useState({
// 		fullname: '',
// 		email: ''
// 	});

// 	const [formPassword, setFormPassword] = useState({
// 		currentPassword: '',
// 		password: '',
// 		passwordConfirm: ''
// 	})

// 	const [show, setShow] = useState(false)

// 	const [errors, setErrors] = useState({});
// 	const [errorPassword, setErrorPassword] = useState({})

// 	const handleChange = (e) => {
// 		const { name, value } = e.target;
// 		setFormData(prevData => ({
// 			...prevData,
// 			[name]: value
// 		}))

// 		if (errors[name]) {
// 			setErrors({
// 				...errors,
// 				[name]: ''
// 			})
// 		}
// 	};

// 	const handleChangePassword = (name, value) => {
// 		setFormPassword((prevData) => ({
// 			...prevData,
// 			[name]: value
// 		}))

// 		if (errorPassword[name]) {
// 			setErrorPassword(prevData => ({
// 				...prevData,
// 				[name]: ''
// 			}))
// 		}
// 	}

// 	const validate = () => {
// 		let tempErrors = {};

// 		if (!formData.fullname) {
// 			tempErrors.fullname = 'Digite seu nome completo';
// 		}

// 		if (!formData.email) {
// 			tempErrors.email = 'Digite seu email para cadastro';
// 		} else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
// 			tempErrors.email = 'Por favor, insira um email válido!';
// 		}

// 		setErrors(tempErrors);

// 		return Object.keys(tempErrors).length === 0;
// 	};

// 	const validatePassword = () => {
// 		let tempErrors = {};

// 		if (!formPassword.currentPassword) tempErrors.currentPassword = 'Preencha com a sua senha atual'

// 		if (!formPassword.password || formPassword.password.length < 8) {
// 			tempErrors.password = 'Digite uma senha forte (mínimo 8 caracteres)';
// 		}

// 		if (formPassword.password !== formPassword.passwordConfirm) {
// 			tempErrors.passwordConfirm = 'As senhas não coincidem!'
// 		}

// 		setErrorPassword(tempErrors)
// 		return Object.keys(tempErrors).length === 0;
// 	}

// 	const handleClick = () => {
// 		setShow(!show)
// 	}

// 	useEffect(() => {
// 		if (user) {
// 			setFormData((prevData) => ({
// 				...prevData,
// 				...user
// 			}))
// 		}
// 	}, [user])

// 	const handleSubmit = (e) => {
// 		e.preventDefault();
// 		if (validate()) {
// 			onSubmit(formData)
// 		}
// 	};

// 	const handleSubmitPassword = (e) => {
// 		e.preventDefault()
// 		if (validatePassword()) {
// 			passwordReset({
// 				newPassword: formPassword.password,
// 				currentPassword: formPassword.currentPassword
// 			})
// 			setFormPassword({
// 				currentPassword: '',
// 				password: '',
// 				passwordConfirm: ''
// 			})
// 		}
// 	}

// 	const handleDeleteAccount = () => {
// 		onDelete()
// 	}

// 	return (
// 		<Box mt={10}>
// 			<form onSubmit={handleSubmit}>
// 				<VStack spacing={10} width='50%'>
// 					<Stack spacing={4} p={10} bgColor='white' boxShadow='lg' borderRadius='md' width='100%'>

// 						{/* Nome Completo */}
// 						<FormControl isInvalid={errors.fullname} isRequired>
// 							<FormLabel>Nome Completo</FormLabel>
// 							<Input
// 								type="text"
// 								name="fullname"
// 								value={formData.fullname}
// 								onChange={handleChange}
// 							/>
// 							<FormErrorMessage>{errors.fullname}</FormErrorMessage>
// 						</FormControl>

// 						{/* Email */}
// 						<FormControl isInvalid={errors.email} isRequired>
// 							<FormLabel>Email</FormLabel>
// 							<Input
// 								type="email"
// 								name="email"
// 								value={formData.email}
// 								onChange={handleChange}
// 							/>
// 							<FormErrorMessage>{errors.email}</FormErrorMessage>
// 						</FormControl>

// 						<Box width='100%'>
// 							<Button colorScheme='teal' onClick={(e) => handleSubmit(e)}>Salvar</Button>
// 						</Box>

// 					</Stack>

// 					<Stack spacing={4} p={10} bgColor='white' boxShadow='lg' borderRadius='md' width='100%'>
// 						{/* Senha */}
// 						<FormControl isInvalid={errorPassword.currentPassword} isRequired>
// 							<FormLabel fontSize='xs'>Senha atual</FormLabel>
// 							<InputGroup size='md'>
// 								<Input
// 									pr='4.5rem'
// 									name="currentPassword"
// 									value={formPassword.currentPassword}
// 									onChange={(e) => handleChangePassword('currentPassword', e.target.value)}
// 									type={show ? 'text' : 'password'}
// 									placeholder='Senha de acesso'
// 								/>

// 								<InputRightElement width='4.5rem'>
// 									<Button h='1.75rem' size='sm' onClick={handleClick}>
// 										{show ? 'Hide' : 'Show'}
// 									</Button>
// 								</InputRightElement>
// 							</InputGroup>

// 							<FormErrorMessage>{errorPassword.currentPassword}</FormErrorMessage>
// 						</FormControl>

// 						<FormControl isInvalid={errorPassword.password} isRequired>
// 							<FormLabel fontSize='xs'>Nova senha</FormLabel>
// 							<InputGroup size='md'>
// 								<Input
// 									pr='4.5rem'
// 									name="password"
// 									value={formPassword.password}
// 									onChange={(e) => handleChangePassword('password', e.target.value)}
// 									type={show ? 'text' : 'password'}
// 									placeholder='Senha de acesso'
// 								/>

// 								<InputRightElement width='4.5rem'>
// 									<Button h='1.75rem' size='sm' onClick={handleClick}>
// 										{show ? 'Hide' : 'Show'}
// 									</Button>
// 								</InputRightElement>
// 							</InputGroup>

// 							<FormErrorMessage>{errorPassword.password}</FormErrorMessage>
// 						</FormControl>

// 						<FormControl isInvalid={errorPassword.passwordConfirm} isRequired>
// 							<FormLabel fontSize='xs'>Confirmar senha</FormLabel>
// 							<InputGroup size='md'>
// 								<Input
// 									pr='4.5rem'
// 									name="passwordConfirm"
// 									onChange={(e) => handleChangePassword('passwordConfirm', e.target.value)}
// 									value={formPassword.passwordConfirm}
// 									type={show ? 'text' : 'password'}
// 									placeholder='Confirme a senha'
// 								/>

// 								<InputRightElement width='4.5rem'>
// 									<Button h='1.75rem' size='sm' onClick={handleClick}>
// 										{show ? 'Hide' : 'Show'}
// 									</Button>
// 								</InputRightElement>
// 							</InputGroup>

// 							<FormErrorMessage>{errorPassword.passwordConfirm}</FormErrorMessage>
// 						</FormControl>

// 						<Box width='100%'>
// 							<Button colorScheme='teal' onClick={e => handleSubmitPassword(e)}>Alterar senha</Button>
// 						</Box>
// 					</Stack>

// 					<Box width='100%'>
// 						<Popover placement='right'>
// 							<PopoverTrigger>
// 								<Button>Deletar minha conta</Button>
// 							</PopoverTrigger>
// 							<PopoverContent>
// 								<PopoverArrow />
// 								<PopoverCloseButton />
// 								<PopoverHeader>Confirmação!</PopoverHeader>
// 								<PopoverBody>
// 									<Box mb={2}>Tem certeza que quer deletar sua conta? Essa ação não pode ser revertida!</Box>
// 									<Button onClick={handleDeleteAccount}>Confirmar</Button>
// 								</PopoverBody>
// 							</PopoverContent>
// 						</Popover>
// 					</Box>
// 				</VStack>
// 			</form>
// 		</Box>
// 	);
// };

// export default ConfigForm;
import React, { useState } from 'react';
import {
	Box,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	Stack,
	InputGroup,
	InputRightElement,
	VStack,
	Button,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const ConfigForm = ({ user, onSubmit, passwordReset, onDelete }) => {
	const [showPassword, setShowPassword] = useState({
		currentPassword: false,
		password: false,
		passwordConfirm: false,
	});

	const togglePasswordVisibility = (field) => {
		setShowPassword((prevState) => ({
			...prevState,
			[field]: !prevState[field],
		}));
	};

	// Esquema de Validação com Yup
	const validationSchema = Yup.object().shape({
		fullname: Yup.string().required('Digite seu nome completo'),
		email: Yup.string()
			.email('Por favor, insira um email válido!')
			.required('Digite seu email para cadastro'),
	});

	const passwordValidationSchema = Yup.object().shape({
		currentPassword: Yup.string().required('Preencha com a sua senha atual'),
		password: Yup.string()
			.min(8, 'Digite uma senha forte (mínimo 8 caracteres)')
			.required('Digite uma senha forte (mínimo 8 caracteres)'),
		passwordConfirm: Yup.string()
			.oneOf([Yup.ref('password'), null], 'As senhas não coincidem!')
			.required('Confirme a senha'),
	});

	return (
		<Box mt={10}>
			{/* Formulário Principal */}
			<Formik
				initialValues={{
					fullname: user?.fullname || '',
					email: user?.email || '',
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				enableReinitialize
			>
				{({ errors, touched }) => (
					<Form>
						<VStack spacing={10} width="50%">
							<Stack
								spacing={4}
								p={10}
								bgColor="white"
								boxShadow="lg"
								borderRadius="md"
								width="100%"
							>
								{/* Nome Completo */}
								<FormControl isInvalid={touched.fullname && errors.fullname} isRequired>
									<FormLabel>Nome Completo</FormLabel>
									<Field as={Input} name="fullname" placeholder="Digite seu nome completo" />
									<FormErrorMessage>{errors.fullname}</FormErrorMessage>
								</FormControl>

								{/* Email */}
								<FormControl isInvalid={touched.email && errors.email} isRequired>
									<FormLabel>Email</FormLabel>
									<Field as={Input} name="email" type="email" placeholder="Digite seu email" />
									<FormErrorMessage>{errors.email}</FormErrorMessage>
								</FormControl>

								<Box width="100%">
									<Button type="submit" colorScheme="teal">
										Salvar
									</Button>
								</Box>
							</Stack>
						</VStack>
					</Form>
				)}
			</Formik>

			{/* Formulário de Senha */}
			<Formik
				initialValues={{
					currentPassword: '',
					password: '',
					passwordConfirm: '',
				}}
				validationSchema={passwordValidationSchema}
				onSubmit={(values, { resetForm }) => {
					passwordReset({
						newPassword: values.password,
						currentPassword: values.currentPassword,
					});
					resetForm();
				}}
			>
				{({ errors, touched }) => (
					<Form>
						<VStack spacing={10} width="50%" marginTop={10}>
							<Stack
								spacing={4}
								p={10}
								bgColor="white"
								boxShadow="lg"
								borderRadius="md"
								width="100%"
							>
								{/* Senha Atual */}
								<FormControl
									isInvalid={touched.currentPassword && errors.currentPassword}
									isRequired
								>
									<FormLabel fontSize="xs">Senha atual</FormLabel>
									<InputGroup size="md">
										<Field
											as={Input}
											name="currentPassword"
											type={showPassword.currentPassword ? 'text' : 'password'}
											placeholder="Senha de acesso"
										/>
										<InputRightElement width="4.5rem">
											<Button
												h="1.75rem"
												size="sm"
												onClick={() => togglePasswordVisibility('currentPassword')}
											>
												{showPassword.currentPassword ? 'Hide' : 'Show'}
											</Button>
										</InputRightElement>
									</InputGroup>
									<FormErrorMessage>{errors.currentPassword}</FormErrorMessage>
								</FormControl>

								{/* Nova Senha */}
								<FormControl isInvalid={touched.password && errors.password} isRequired>
									<FormLabel fontSize="xs">Nova senha</FormLabel>
									<InputGroup size="md">
										<Field
											as={Input}
											name="password"
											type={showPassword.password ? 'text' : 'password'}
											placeholder="Nova senha"
										/>
										<InputRightElement width="4.5rem">
											<Button
												h="1.75rem"
												size="sm"
												onClick={() => togglePasswordVisibility('password')}
											>
												{showPassword.password ? 'Hide' : 'Show'}
											</Button>
										</InputRightElement>
									</InputGroup>
									<FormErrorMessage>{errors.password}</FormErrorMessage>
								</FormControl>

								{/* Confirmar Senha */}
								<FormControl
									isInvalid={touched.passwordConfirm && errors.passwordConfirm}
									isRequired
								>
									<FormLabel fontSize="xs">Confirmar senha</FormLabel>
									<InputGroup size="md">
										<Field
											as={Input}
											name="passwordConfirm"
											type={showPassword.passwordConfirm ? 'text' : 'password'}
											placeholder="Confirme a senha"
										/>
										<InputRightElement width="4.5rem">
											<Button
												h="1.75rem"
												size="sm"
												onClick={() => togglePasswordVisibility('passwordConfirm')}
											>
												{showPassword.passwordConfirm ? 'Hide' : 'Show'}
											</Button>
										</InputRightElement>
									</InputGroup>
									<FormErrorMessage>{errors.passwordConfirm}</FormErrorMessage>
								</FormControl>

								<Box width="100%">
									<Button type="submit" colorScheme="teal">
										Alterar senha
									</Button>
								</Box>
							</Stack>
						</VStack>
					</Form>
				)}
			</Formik>

			{/* Botão de Deletar Conta */}
			<Box width="50%" mt={5}>
				<Popover placement="right">
					<PopoverTrigger>
						<Button colorScheme="ghost" textColor={'teal.500'}>Deletar minha conta</Button>
					</PopoverTrigger>
					<PopoverContent>
						<PopoverArrow />
						<PopoverCloseButton />
						<PopoverHeader>Confirmação!</PopoverHeader>
						<PopoverBody>
							<Box mb={2}>
								Tem certeza que quer deletar sua conta? Essa ação não pode ser revertida!
							</Box>
							<Button colorScheme="red" onClick={onDelete}>
								Confirmar
							</Button>
						</PopoverBody>
					</PopoverContent>
				</Popover>
			</Box>
		</Box>
	);
};

export default ConfigForm;
