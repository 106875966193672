import React, { useState, useEffect } from "react";
import { Link as RouterLink } from 'react-router-dom'
import { QRCode } from 'react-qrcode'
import {
    Box,
    Button,
    Text,
    useSteps,
    FormControl,
    FormLabel,
    ButtonGroup,
    HStack,
    Heading,
    Spinner,
    Stack,
    Textarea,
    Icon
} from "@chakra-ui/react";
import PlanCard from "../planCard";
import FormCardType from "./formCardType";
import FormPixType from "./formPixType";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import Countdown from "../../../ui/Countdown";
import { checkValidation } from "../../../../services/backend/UserService";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useToastContext } from "../../../../context/ToastContext";
import { useNavigate } from "react-router-dom";

function FormCheckout({ payment_status, submit, plan, ...props }) {
    const user = useAuthUser()
    const navigate = useNavigate()
    const { showToast } = useToastContext()

    const [MercadoPago, setMercadoPago] = useState({});
    const [loading, setLoading] = useState(true);
    var token = '';
    const [formData, setFormData] = useState({
        payment_type: '',
        payment_data: {
            pix: {
                name: '',
                document: '',
            },
            credit: {
                cardholderName: '',
                identificationType: '',
                identificationNumber: '',
                token: '',
                auto_recorring: false,
                issuer_id: '',
            }
        }
    })

    const [formError, setFormError] = useState({
        payment_data: {
            pix: {
                first_name: '',
                last_name: '',
                document: '',
            },
            credit: {
                first_name: '',
                last_name: '',
                number: '',
                cvv: '',
                expiration_date: '',
            }
        }
    })

    const setPix = (data) => {
        setFormData({
            ...formData,
            payment_data: {
                ...formData.payment_data,
                pix: {
                    ...formData.payment_data.pix,
                    ...data
                }
            }
        })
    }

    const setCredit = (data) => {
        setFormData({
            ...formData,
            payment_data: {
                ...formData.payment_data,
                credit: {
                    ...formData.payment_data.credit,
                    ...data
                }
            }
        })
    }

    const { activeStep, setActiveStep } = useSteps({
        initialStep: 0,
    });

    const prevStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const setPaymentType = (type) => {
        if (loading) setLoading(false)
        setFormData({
            ...formData,
            payment_type: type
        })
    }

    const validateForm = (step) => {
        const errors = {
            payment_data: {
                pix: {
                },
                credit: {
                }
            }
        }

        switch (step) {
            case 0:
                if (formData.payment_type === 'credit_card') {
                    if (!formData.payment_data.credit.first_name) errors.payment_data.credit.first_name = 'Por favor, informe o nome'
                    if (!formData.payment_data.credit.last_name) errors.payment_data.credit.last_name = 'Por favor, informe o sobrenome'
                    if (!formData.payment_data.credit.number) errors.payment_data.credit.number = 'Por favor, informe o número do cartão'
                    if (!formData.payment_data.credit.cvv) errors.payment_data.credit.cvv = 'Por favor, informe o cvv'
                    if (!formData.payment_data.credit.expiration_date) errors.payment_data.credit.expiration_date = 'Por favor, informe a data de expiração'
                } else if (formData.payment_type === 'pix') {
                    if (!formData.payment_data.pix.first_name) errors.payment_data.pix.first_name = 'Por favor, informe o nome'
                    if (!formData.payment_data.pix.last_name) errors.payment_data.pix.last_name = 'Por favor, informe o sobrenome'
                    if (!formData.payment_data.pix.document) errors.payment_data.pix.document = 'Por favor, informe o documento'
                }

                setFormError(errors)

                const hasErrors = Object.keys(errors.payment_data.pix).length > 0 || Object.keys(errors.payment_data.credit).length > 0;
                return !hasErrors;
            case 1:
                return formData.payment_data
            default:
                return true
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setMercadoPago(new window.MercadoPago('APP_USR-f083436e-4b13-43a2-a13f-4db43ac923a7'));
        }, 2000);

        if (payment_status.transaction_data && formData.payment_type === 'pix') {
            setInterval(async () => {
                try {
                    const subscriptionValidatiom = await checkValidation(user._id);
                    const { message, endDate } = subscriptionValidatiom;

                    if (endDate) {
                        showToast({
                            message: message,
                            status: 'Success'
                        })
                        clearInterval()
                        navigate('/dashboard');
                    }
                } catch (error) {
                    showToast({
                        message: error.message,
                        status: 'error'
                    })
                }
            }, 10000)
        }
    }, [payment_status, formData.payment_type, navigate, showToast, user._id]);

    const PaymentTimeout = () => {
        prevStep();
    }

    const createCardToken = async () => {
        try {
            const { cardholderName, identificationType, identificationNumber } = formData.payment_data.credit;

            // Chama o método para criar o token com os dados capturados
            const cardToken = await MercadoPago.fields.createCardToken({
                cardholderName,
                identificationType,
                identificationNumber,
            });

            const copyToken = { ...cardToken }
            token = copyToken.id
        } catch (error) {
            console.error("Erro ao gerar o token: ", error);
        }
    };

    const nextStep = async () => {
        if (formData.payment_type === 'credit_card') {
            await createCardToken();
            await submit({
                ...formData,
                payment_data: {
                    ...formData.payment_data,
                    credit: {
                        ...formData.payment_data.credit,
                        token: token
                    }
                }
            });
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (formData.payment_type === 'pix') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            await submit(formData);
        }
    };


    return (
        <Box {...props}>
            <Helmet>
                <script src="https://www.mercadopago.com/v2/security.js" view="checkout" output="deviceId"></script>
                <script src="https://sdk.mercadopago.com/js/v2"></script>
                <script src="https://www.mercadopago.com/v2/security.js" view="adquirir-plan"></script>
            </Helmet>

            <HStack
                spacing={4}
                borderRadius={"md"}
                boxShadow={'md'}
                padding={8}
                bgColor={'white'}
                alignItems={'center'}
                height={'100%'}>

                <FormControl>
                    <Stack
                        spacing={4}
                        direction={'column'}
                        justifyContent={'center'}
                    >
                        {activeStep === 0 && (
                            <>
                                <Box>
                                    <FormLabel htmlFor="name">Selecione um metódo de pagamento</FormLabel>
                                    <ButtonGroup>
                                        <Button onClick={() => setPaymentType('credit_card')}>Cartão de Crédito</Button>
                                        <Button onClick={() => setPaymentType('pix')}>Pix</Button>
                                    </ButtonGroup>
                                </Box>

                                <Spinner
                                    mt={4}
                                    thickness='20px'
                                    speed='0.65s'
                                    alignSelf={'center'}
                                    emptyColor='gray.200'
                                    color='teal.500'
                                    padding={20}
                                    size='xl'
                                    display={loading ? 'block' : 'none'}
                                />

                                <Box>
                                    {formData.payment_type === 'credit_card' && <FormCardType gateway={MercadoPago} change={setCredit} />}
                                    {formData.payment_type === 'pix' && <FormPixType errors={formError.payment_data.pix} pixObject={formData.payment_data.pix} change={setPix} />}
                                </Box>

                                <Box>
                                    <Button
                                        isLoading={loading}
                                        onClick={nextStep}
                                        colorScheme="teal"
                                        alignSelf={'flex-end'}
                                        size={'lg'}
                                    >
                                        Continuar
                                    </Button>
                                </Box>
                            </>
                        )}

                        {activeStep === 1 && formData.payment_type === 'credit_card' && (
                            <>
                                {payment_status.error && (
                                    <Stack height="100%" width={"100%"} direction={'column'} py={20} alignItems={'center'} justifyContent={'center'}>
                                        <Icon as={WarningIcon} color="red.500" boxSize={200} mb={10} />
                                        <Stack width={'70%'} direction={'column'} spacing={4} alignItems={'center'}>
                                            <Heading textAlign={'center'} color={'red.500'}>{payment_status.error}</Heading>
                                            <Button colorScheme="red" onClick={() => prevStep()}>Voltar</Button>
                                        </Stack>
                                    </Stack>
                                )}

                                {payment_status.date_created && (
                                    <Stack height="100%" width={"100%"} py={20} direction={'column'} alignItems={'center'} justifyContent={'center'}>
                                        <Icon as={CheckCircleIcon} color="teal" boxSize={200} />
                                        <Stack spacing={8} width={'70%'} alignItems={'center'}>
                                            <Box >
                                                <Heading>Assinatura concluida!</Heading>
                                                <Text>Você já pode aproveitar os benefícios do seu plano, clique no botão "Voltar" para retornar ao dashboard.</Text>
                                            </Box>

                                            <Link as={RouterLink} to="/dashboard">
                                                <Button colorScheme="teal" size={'lg'}>Voltar</Button>
                                            </Link>
                                        </Stack>
                                    </Stack>
                                )}
                            </>
                        )}

                        {activeStep === 1 && formData.payment_type === 'pix' && (
                            <>
                                {payment_status.transaction_data?.qr_code && (
                                    <Stack height="100%" width={"100%"} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                        <Stack direction={'column'} width={'80%'} alignItems={'center'} justifyContent={'center'}>
                                            {/* <Image width={'200px'} fallback={<Spinner size={'xl'} />} src={`data:image/png;base64,${QRCODE}`} alt="QR Code" /> */}
                                            <QRCode value={payment_status.transaction_data?.qr_code} />
                                            <Countdown width={'80%'} initialMinutes={3} callback={() => PaymentTimeout()} />
                                        </Stack>

                                        <Stack direction={'column'} width={'70%'} alignItems={'center'} justifyContent={'center'}>
                                            <Textarea width={'400px'} value={payment_status.transaction_data.qr_code} readOnly />
                                            <Text textAlign={'center'} fontSize={'xs'}>
                                                Copie o código abaixo e cole no aplicativo do seu banco na seção PIX. Não esqueça de conferir as informações antes de confirmar o pagamento.
                                            </Text>
                                            <ButtonGroup>
                                                <Button colorScheme="teal" id="submit">COPIAR</Button>
                                                <Button colorScheme="red" onClick={() => prevStep()}>Voltar</Button>
                                            </ButtonGroup>
                                        </Stack>
                                    </Stack>
                                )
                                }

                                {payment_status.error && (
                                    <Stack height="100%" width={"100%"} direction={'column'} py={20} alignItems={'center'} justifyContent={'center'}>
                                        <Icon as={WarningIcon} color="red.500" boxSize={200} mb={10} />
                                        <Stack width={'70%'} direction={'column'} spacing={4} alignItems={'center'}>
                                            <Heading textAlign={'center'} color={'red.500'}>{payment_status.error}</Heading>
                                            <Button colorScheme="red" onClick={() => prevStep()}>Voltar</Button>
                                        </Stack>
                                    </Stack>
                                )}
                            </>
                        )}
                    </Stack>
                </FormControl>

                <FormControl>
                    <PlanCard plan={plan} />
                </FormControl>

            </HStack>
        </Box>
    )
}

export default FormCheckout