
import API from '../backend/axiosConfig'
import ApiException from '../backend/ApiException'

const getScenesAll = async ({ page = 1, limit = 10, searchQuery ,script }) => {
    try {
        const response = await API.get('/cenas', {
            params: {
                page,
                limit,
                script,
                search: searchQuery
            }
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const getSceneById = async (_id = '') => {

    if (!_id && typeof _id !== 'string') throw new ApiException('Nenhum ID Fornecido')

    try {
        const response = await API.get(`/cenas/${_id}`)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const updateSceneById = async ({
    _id,
    description,
    content,
    voiceover,
    script,
    order,
    image,
    recorded = false
}) => {
    if (!_id || typeof _id !== 'string') {
        throw new ApiException('Nenhum ID Fornecido');
    }

    try {
        const formData = new FormData();

        // Adiciona os campos ao FormData
        formData.append('description', description || '');
        formData.append('content', content || '');
        formData.append('voiceover', voiceover || '');
        formData.append('script', script);
        formData.append('order', order);
        formData.append('recorded', recorded || false);

        // Se existir uma imagem, adiciona ao FormData
        if (image) {
            formData.append('image', image);
        } else {
            formData.append('image', null);
        }

        const response = await API.put(`/cenas/${_id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const deleteSceneById = async (_id = '') => {
    try {
        const response = await API.delete(`/cenas/${_id}`)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const createScene = async ({
    description,
    content,
    voiceover,
    script,
    order,
    image,
    recorded
}) => {
    try {
        const formData = new FormData();

        // Adiciona os campos ao FormData
        formData.append('description', description || '');
        formData.append('content', content || '');
        formData.append('voiceover', voiceover || '');
        formData.append('script', script);
        formData.append('order', order);
        formData.append('recorded', recorded || false);

        // Se existir uma imagem, adiciona ao FormData
        if (image) {
            formData.append('image', image);
        }

        const response = await API.post('/cenas', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

export {
    getScenesAll,
    getSceneById,
    deleteSceneById,
    updateSceneById,
    createScene
}