import React from "react";
import { CheckIcon, CloseIcon, TimeIcon, QuestionIcon } from "@chakra-ui/icons";

export default function BudgetStatus({ status }) {
    switch (status) {
        case "pendente":
            return <TimeIcon color="gray.500" />;
        case "aprovado":
            return <CheckIcon color="teal.500" />;
        case "rejeitado":
            return <CloseIcon color="red.500" />;
        default:
            return <QuestionIcon color="gray.500" />;
    }
}