import { Box, Link, Tooltip } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

function PremiumBox({ ...rest }) {
    const user = useAuthUser();
    return (!user.subscription && (
        <Tooltip
            hasArrow
            bgColor='teal.400'
            borderRadius={10}
            label='Com o premium você tem todas as ferramentas para otimizar suas produções.'
            placement="left">

            <Box {...rest}>
                <Link as={RouterLink} to={'/premium'} mt='30vh'>
                    Assine o Premium
                </Link>
            </Box>

        </Tooltip>
    ))
}

export default PremiumBox;