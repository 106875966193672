import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Textarea,
	IconButton,
	ButtonGroup,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	VStack,
	Center,
	Image,
	HStack,
	Checkbox,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, DragHandleIcon } from '@chakra-ui/icons';
import { FaShare } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useDropzone } from 'react-dropzone';
import { useToastContext } from '../../../context/ToastContext';
import { v4 as uuidv4 } from 'uuid';

// Componente para área de upload de imagem com drag and drop
const ImageUpload = ({ onChange, imageUrl }) => {
	const [previewUrl, setPreviewUrl] = useState(null);
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop: acceptedFiles => {
			if (acceptedFiles.length > 0) {
				onChange(URL.createObjectURL(acceptedFiles[0]));
			}
		},
	});

	useEffect(() => {
		if (imageUrl && imageUrl !== "null") {
			setPreviewUrl(imageUrl);
		} else {
			setPreviewUrl(null);
		}
	}, [imageUrl]);

	return (
		<Center
			border="2px dashed"
			borderColor="gray.300"
			p={4}
			borderRadius="md"
			{...getRootProps()}
			cursor="pointer"
		>
			<input {...getInputProps()} />
			{previewUrl ? (
				<Image
					src={previewUrl}
					alt="Imagem da cena"
					boxSize="100px"
					objectFit="cover"
				/>
			) : (
				<p>Arraste e solte uma imagem aqui ou clique para selecionar</p>
			)}
		</Center>
	);
};

// Componente para linha de cena
const DraggableItem = ({ scene, index, onDelete, onChange, onRemove }) => (
	<Draggable draggableId={scene._tempId} index={index}>
		{(provided) => (
			<Tr
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				<Td>
					<IconButton
						aria-label="Reordenar Cena"
						icon={<DragHandleIcon />}
						{...provided.dragHandleProps}
					/>
				</Td>
				<Td>{scene.order}</Td>
				<Td>
					<Checkbox
						onChange={e => onChange(scene._tempId, 'recorded', e.target.checked)}
						isChecked={scene.recorded} />
				</Td>
				<Td>
					<Textarea
						value={scene.description || ''}
						onChange={e => onChange(scene._tempId, 'description', e.target.value)}
					/>
				</Td>
				<Td>
					<Textarea
						value={scene.content || ''}
						onChange={e => onChange(scene._tempId, 'content', e.target.value)}
					/>
				</Td>
				<Td>
					<Textarea
						value={scene.voiceover || ''}
						onChange={e => onChange(scene._tempId, 'voiceover', e.target.value)}
					/>
				</Td>
				<Td>
					<HStack>
						<ImageUpload
							imageUrl={scene.image}
							onChange={image => onChange(scene._tempId, 'image', image)}
						/>
						<IconButton variant='ghost' icon={<DeleteIcon />} onClick={() => onRemove(scene._tempId)} />
					</HStack>
				</Td>
				<Td>
					<IconButton
						aria-label="Deletar Cena"
						icon={<DeleteIcon />}
						onClick={() => onDelete(scene._id)}
					/>
				</Td>
			</Tr>
		)}
	</Draggable>
);

const SceneForm = ({ onSubmit, handleShare, scenesList, handleDelete }) => {
	const [scenes, setScenes] = useState([]);
	const { showToast } = useToastContext()

	useEffect(() => {
		if (scenesList) {
			var sceneTempId = scenesList.map(scene => ({
				...scene,
				_tempId: uuidv4(),
			}));

			var sortScenes = sceneTempId.sort((a, b) => a.order - b.order)

			setScenes(sortScenes);
		}
	}, [scenesList])

	const onDragEnd = (result) => {
		if (!result.destination) return;

		const reorderedScenes = Array.from(scenes);
		const [moved] = reorderedScenes.splice(result.source.index, 1);
		reorderedScenes.splice(result.destination.index, 0, moved);

		// Atualizar a ordem das cenas
		reorderedScenes.forEach((scene, index) => {
			scene.order = index + 1;
		});

		setScenes(reorderedScenes);
	};

	const handleAddScene = () => {
		setScenes([...scenes, { order: scenes.length + 1, _tempId: uuidv4() }]);
	};

	const handleDeleteScene = (scene) => {
		if (scene._id) {
			handleDelete(scene)
		} else {
			const id = scene._tempId
			setScenes(scenes.filter(scene => scene._tempId !== id));
			showToast({
				title: "Cena deletada.",
				description: "A cena foi removida com sucesso.",
				status: "success"
			});
		}
	};

	const handleChangeScene = (id, field, value) => {
		setScenes(scenes.map(scene => (scene._tempId === id ? { ...scene, [field]: value } : scene)));
	};

	const handleRemoveImage = (sceneTempId) => {
		handleChangeScene(sceneTempId, 'image', null)
	}

	const handleSubmit = () => {
		onSubmit(scenes);
	};

	return (
		<Box p={5} bgColor='white' borderRadius='md' boxShadow='md'>
			<VStack spacing={4} alignItems='start'>

				<ButtonGroup>
					<Button leftIcon={<AddIcon />} onClick={handleAddScene}>Adicionar</Button>
					<IconButton icon={<FaShare />} />
				</ButtonGroup>

				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable1" direction="vertical">
						{(provided) => (
							<Table
								variant="simple"
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<Thead>
									<Tr>
										<Th>Reordenar</Th>
										<Th>Ordem</Th>
										<Th>Captado</Th>
										<Th>Descrição</Th>
										<Th>Conteúdo</Th>
										<Th>Voiceover</Th>
										<Th>Imagem</Th>
										<Th>Ações</Th>
									</Tr>
								</Thead>
								<Tbody>
									{scenes.map((scene, index) => (
										<DraggableItem
											key={scene._tempId}
											scene={scene}
											index={index}
											onDelete={() => handleDeleteScene(scene)}
											onChange={handleChangeScene}
											onRemove={handleRemoveImage}
										/>
									))}
									{provided.placeholder}
								</Tbody>
							</Table>
						)}
					</Droppable>
				</DragDropContext>
				<Button colorScheme="teal" onClick={handleSubmit}>
					Salvar
				</Button>
			</VStack>
		</Box>
	);
};

export default SceneForm;
