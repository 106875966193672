import { extendTheme } from "@chakra-ui/react";
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

const CraftTheme = extendTheme({
    fonts: {
        heading: `"Roboto", sans-serif`, // Fonte para headings (títulos)
        body: `"Roboto", sans-serif`,    // Fonte para o corpo do texto
    }
})

export default CraftTheme;