import { Icon, VStack, Link, Tooltip, Text, Box, IconButton } from "@chakra-ui/react";
import { HiLogout } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'

import { logoutUser } from "../../services/backend/UserService";
import { useToastContext } from "../../context/ToastContext";

function UserSession({ ...rest }) {
    const user = useAuthUser()
    const { showToast } = useToastContext()
    const navigate = useNavigate()
    const signOut = useSignOut()
    const isAuthenticated = useIsAuthenticated()

    const handleLogout = async () => {
        try {
            const logout = await logoutUser();
            const isSignedOut = signOut();
            navigate('/login')
        } catch (error) {
            // Caso algo dê errado com o logout
            console.error('Erro ao tentar fazer logout:', error);
        }
    }

    return (
        <VStack {...rest}>
            <Box>
                <Text textAlign={'center'}>{user.fullname}</Text>
            </Box>
            <Tooltip label='Logout'>
                <Link onClick={handleLogout} fontSize="sm">
                    <IconButton p={2} colorScheme="teal" variant={"ghost"} as={HiLogout} />
                </Link>
            </Tooltip>
        </VStack>
    )
}

export default UserSession;