import React from 'react';
import { Text } from '@chakra-ui/react';

export const formatDateForInput = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const dateString = d.toISOString().split('T')[0];
    return dateString
};

export const formatDate = (date, formatPattern = "dd/MM/yyyy") => {
    const options = {};

    // Personalização simples de formato baseado no padrão fornecido
    switch (formatPattern) {
        case 'dd/MM/yyyy':
            return date.toLocaleDateString('pt-BR');
        case 'MMMM do, yyyy':
            options.month = 'long';
            options.day = 'numeric';
            options.year = 'numeric';
            break;
        case 'MM/dd/yyyy':
            return date.toLocaleDateString('en-US');
        default:
            options.year = 'numeric';
            options.month = 'short';
            options.day = '2-digit';
    }

    return date.toLocaleDateString(undefined, options);
};

const DateDisplay = ({
    date = new Date(),  // Data padrão é a data atual
    formatPattern = 'dd/MM/yyyy', // Padrão de formatação
    textColor = 'black', // Cor do texto (personalizável)
    fontSize = 'md',     // Tamanho da fonte (personalizável)
    fontWeight = 'normal' // Peso da fonte (personalizável)
}) => {
    const parsedDate = new Date(date);

    // Verifica se a data é válida
    if (isNaN(parsedDate)) {
        return <Text color="red.500">Data inválida</Text>;
    }

    return (
        <Text>
            {formatDate(parsedDate, formatPattern)}
        </Text>
    );
};

export default DateDisplay;
