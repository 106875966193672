import { ButtonGroup, GridItem, Image, Spacer, Button, Divider, HStack, Link } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/images/Logo_Invert.png"

export default function Header({ ...rest }) {
    const navigate = useNavigate();

    return (
        <GridItem width={"100%"} {...rest} boxShadow={"md"} zIndex={1} position={"sticky"} top={0} bg={"white"}>
            <HStack maxW={"1200px"} mx={"auto"} justifyContent={"space-between"} >
                <Link href="/">
                    <Image src={Logo} width={"100px"} />
                </Link>

                <Spacer />

                <ButtonGroup variant={"ghost"} colorScheme="teal">
                    <Link href="/">
                        <Button fontSize={"sm"} color={"teal.600"}>Ínicio</Button>
                    </Link>

                    <Link href="/#ferramentas">
                        <Button fontSize={"sm"} color={"teal.600"}>Funcionalidades</Button>
                    </Link>

                    <Link href="/#planos">
                        <Button fontSize={"sm"} color={"teal.600"}>Planos</Button>
                    </Link>

                    <Link href="/contato">
                        <Button fontSize={"sm"} color={"teal.600"}>Contato</Button>
                    </Link>
                </ButtonGroup>

                <Divider orientation="vertical" mx={4} />

                <ButtonGroup>
                    <Button colorScheme={"teal"} onClick={() => navigate("/login")}>Entrar</Button>
                    <Button variant={"outline"} colorScheme={"teal"} onClick={() => navigate("/registrar")}>Registrar</Button>
                </ButtonGroup>
            </HStack>
        </GridItem>
    )
}