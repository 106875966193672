import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Bread({ options}) {
    return (
        <Breadcrumb py={2}>
            {options.map((option, index) => (
                <BreadcrumbItem key={index}>
                    <BreadcrumbLink as={Link} to={option.link} isCurrentPage={option.current}>
                        {option.name}
                    </BreadcrumbLink>
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    )
}