import { Box, Heading } from "@chakra-ui/react";
import ListScripts from "../../components/dashboard/scripts/listScripts";
import { Helmet } from "react-helmet";
import { SITE_NAME } from "../../misc/siteConfig";
import ToolNotIncluded from "../../components/dashboard/plan/toolNotIncluded";

import {checkPlanTools} from "../../auth/userHelpers";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

function Scripts() {
    const user = useAuthUser();
    return (
        checkPlanTools(user,'Roteiros') ? (
            <Box>
                <Helmet>
                    <title>{SITE_NAME} &#8208; Roteiros</title>
                </Helmet>
                <Heading p={4} borderWidth="1px" borderRadius="md" fontSize='md' boxShadow='md' bgColor='white'>Roteiros</Heading>
                <ListScripts />
            </Box>
        ) : <ToolNotIncluded />
    )
}

export default Scripts;