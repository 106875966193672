import { Helmet } from "react-helmet";
import { GridItem, SimpleGrid } from "@chakra-ui/react";
import { SITE_NAME } from "../misc/siteConfig";

import LatestBudgets from "../components/dashboard/home/latestBudgets";
import LatestCrons from "../components/dashboard/home/latestCrons";

function Dashboard() {


    return (
        <>
            <Helmet>
                <title>{SITE_NAME} &#8208; Dashboard</title>
            </Helmet>
            <SimpleGrid columns={5} spacing={4}>
                <GridItem>
                    <LatestBudgets />
                </GridItem>

                <GridItem>
                    <LatestCrons />
                </GridItem>
            </SimpleGrid>
        </>
    )

}

export default Dashboard;