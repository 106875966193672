import React, { useState, useEffect } from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { Image } from '@chakra-ui/react';
import { Outlet } from "react-router-dom";

import logo from '../../assets/images/logo.png';
import MenuLink from "../../components/dashboard/MenuLinks";
import UserSession from "../../components/dashboard/userSession";
import PremiumBox from "../../components/dashboard/premium/premiumBox";
import SocialIcons from "../../components/ui/SocialIcons";

import Announcement from "../../components/ui/announcement";
import { useAnnouncementContext } from "../../context/AnnouncementContext";

function Layout() {
    const { siteConfig } = useAnnouncementContext();

    return (
        <>
            <Flex height="100vh">
                <Box
                    width="250px"
                    bg="gray.700"
                    height='100%'
                    overflowY="auto"
                    position="fixed"
                    color="white"
                    p={4}
                    borderRightColor='teal'
                    borderRightWidth='4px'
                    boxShadow='lg'
                >
                    <VStack>
                        <Image src={logo} width='150px' mt={10} mb={10} />
                        <UserSession />
                        <MenuLink />
                        <SocialIcons mt={10} options={siteConfig.socialIcons} />
                    </VStack>

                </Box>


                <Box flex="1" p={4} ml='250px' bgColor='gray.100' overflowY='auto'>
                    <PremiumBox
                        bgColor='teal'
                        boxShadow='lg'
                        textColor='white'
                        zIndex={999}
                        p={4}
                        borderRadius={10}
                        position='fixed'
                        bottom='30px'
                        right='30px' />

                    {siteConfig.announcement?.length > 0 && siteConfig.announcement.map((announcement, index) => {
                        return <Announcement key={index} announcement={announcement} my={4} borderRadius={'md'} />
                    })}

                    <Outlet />
                </Box>
            </Flex>
        </>
    )

}

export default Layout;