import API from "../../services/backend/axiosConfig";

export default function useLoadSiteConfig () {


    const loadSiteConfig = async () => {
        try {
            const siteConfig = await API.get('/siteconfig');
            return siteConfig.data;
        } catch (error) {
            console.log(error);
        }
    }

    return {loadSiteConfig}

}