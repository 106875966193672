import { Heading, Table, Tbody, Text, Tr, Td, TableContainer, Icon, ButtonGroup, Button, IconButton, VStack, Spacer, Thead, Link, HStack, Badge } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from 'react-router-dom'

import DateDisplay from "../../ui/DateDisplay";
import BudgetStatus from "../budget/budgetStatus";
import { ViewIcon } from "@chakra-ui/icons";

import { ReactComponent as Invoice } from '../../../assets/icons/invoice.svg';
import useGetLatestBudgets from "../../../hooks/budget/useGetLatestBudgets";

export default function LatestBudgets() {
    const { getLatestBudgets } = useGetLatestBudgets();
    const [budgets, setBudgets] = useState([]);

    useEffect(() => {
        getLatestBudgets().then((response) => {
            setBudgets(response.budgets);
        });
    }, []);

    return (
        <VStack 
            bgColor={"white"} 
            borderRadius={"md"} 
            p={4} 
            minHeight={"250px"} 
            alignItems={"start"} 
            justifyContent={"start"} 
            boxShadow={"md"}
        >
            <HStack width={"100%"}>
                <Icon as={Invoice} />
                <Heading size={"xs"} textTransform={"uppercase"}>Orçamentos recentes</Heading>
            </HStack>

            <TableContainer width={"100%"}>
                <Table variant={"simple"} size={"sm"}>
                    {/* <Thead>
                        <Tr>
                            <Td fontWeight={"bold"}>Cliente</Td>
                            <Td fontWeight={"bold"}>Validade</Td>
                            <Td fontWeight={"bold"}>Status</Td>
                            <Td></Td>
                        </Tr>
                    </Thead> */}
                    <Tbody>
                        {budgets &&budgets.map((budget) => (
                            <Tr key={budget._id}>
                                <Td textAlign={"center"}>{budget.client.name}</Td>
                                <Td textAlign={"center"}><DateDisplay date={budget.expireDate} /></Td>
                                <Td textAlign={"center"}>
                                    <Badge p={2} borderRadius={"md"} variant={"outline"} colorScheme={budget.status === "aprovado" ? "teal" : "gray"}>
                                        <HStack>
                                            <BudgetStatus status={budget.status} />
                                            <Text textTransform={"capitalize"}>{budget.status}</Text>
                                        </HStack>
                                    </Badge>
                                </Td>
                                <Td textAlign={"center"}>
                                    <Link as={RouterLink} to={`/dashboard/orcamentos/ver/${budget._id}`} state={{ budget }}>
                                        <IconButton variant={'ghost'} icon={<ViewIcon color={'gray.400'} />} />
                                    </Link>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>

            <Spacer />

            <ButtonGroup mt={2} variant={"outline"}>
                <Button as={RouterLink} to={`/dashboard/orcamentos`} size={"xs"}>Ver todos</Button>
            </ButtonGroup>
        </VStack>
    )
}