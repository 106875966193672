import { GridItem, Heading, VStack, Text, HStack, Box } from "@chakra-ui/react";
import ContactForm from "../../components/site/ContactForm";
import { SITE_NAME } from "../../misc/siteConfig";
import SocialIcons from "../../components/ui/SocialIcons";

import { Helmet } from "react-helmet";

export default function Contato() {
    return (
        <GridItem py={20} display={'flex'} justifyContent={'center'}>
            <Helmet>
                <title>{SITE_NAME} &#8208; Contato</title>
            </Helmet>
            <HStack maxW={'1200px'} mx={'auto'} justifyContent={'center'} alignItems={'center'}>
                <VStack width={'40%'} alignItems={'flex-start'} spacing={5} justifyContent={'start'}>
                    <Heading size={'lg'} color={'teal.600'}>Entre em contato conosco!</Heading>
                    <Text width={'80%'}>Tem um dúvida ou sugestão? Preencha o formulário abaixo para entrar em contato, estamos sempre dispostos a ouvir o seu feedback.</Text>

                    <Box>
                        <Text fontWeight={'bold'}>Siga nossas redes sociais:</Text>
                        <SocialIcons options={[{ name: 'Instagram', link: 'https://www.instagram.com/brunocarvalhoo/' }]} />
                    </Box>
                </VStack>

                <ContactForm width={'40%'} my={10} />
            </HStack>
        </GridItem>
    );
}