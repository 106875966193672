import { Modal, ModalBody, ModalCloseButton, ModalOverlay, Input, ModalContent, FormControl, FormLabel, Spinner, Button, VStack } from '@chakra-ui/react'
import { SITE_URL } from '../../../misc/siteConfig'

function ScriptSharedModal({ isOpen, onClose, script, toggle, loading = false, ...rest }) {
    const toogleShare = () => {
        script.shared = !script.shared
        toggle(script)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} {...rest}>
            <ModalOverlay />
            <ModalCloseButton />
            <ModalContent>

                <ModalBody>
                    <FormControl p={10}>

                        {!loading ? (
                            <>
                                <VStack>
                                    {script.shared && (
                                        <>
                                        <FormLabel fontSize='xs' textAlign='center'>Use este link para compartilha seu roteiro</FormLabel>
                                        <Input isReadOnly value={`${SITE_URL}/public/roteiro/${script?._id}`} />
                                        </>
                                    )}

                                    {!script.shared && (
                                        <FormLabel>Qualquer pessoa pode acessar o roteiro a partir de seu link</FormLabel>
                                    )}
                                    <Button onClick={toogleShare}>{script.shared ? 'Desativar' : 'Ativar'}</Button>
                                </VStack>
                            </>
                        ) : (
                            <Spinner />
                        )}

                    </FormControl>
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}

export default ScriptSharedModal