import {
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Button,
    VStack,
    Text,
    Link,
    ButtonGroup,
    FormErrorMessage
} from "@chakra-ui/react"
import { useState } from "react"
import * as Yup from 'yup';
import {Formik} from 'formik';

function RegisterForm({ onSubmit, ...rest }) {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)

    const validationSchema = Yup.object().shape({
        fullname: Yup.string().required('Preencha com seu nome completo'),
        email: Yup.string().email('Digite um email válido').required('Preencha com um email'),
        password: Yup.string().min(8, 'A senha precisa ter pelo menos 8 caracteres').required('Preencha com uma senha'),
        passwordConfirm: Yup.string().equals([Yup.ref('password')], 'As senhas não são iguais').required('Por favor, confirma a senha')
    })

    const handleSubmit = (values) => {
        onSubmit(values)
    }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                fullname: '',
                email: '',
                password: '',
                passwordConfirm: ''
            }}
            onSubmit={handleSubmit}>
            {({ handleChange, handleSubmit, values, errors, touched, resetForm }) => (
                <VStack p={10} bgColor='white' borderRadius='md' boxShadow='md' spacing={5} {...rest}>
                    <FormControl isInvalid={errors.fullname} isRequired>
                        <FormLabel fontSize='xs'>Nome completo</FormLabel>
                        <Input
                            name="fullname"
                            onChange={handleChange}
                            value={values.fullname}
                            placeholder="Seu nome completo" />

                        <FormErrorMessage>{errors.fullname}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.email} isRequired>
                        <FormLabel fontSize='xs'>Email</FormLabel>
                        <Input
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            placeholder="Digite seu email principal" />

                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors.password} isRequired>
                        <FormLabel fontSize='xs'>Senha</FormLabel>
                        <InputGroup size='md'>
                            <Input
                                pr='4.5rem'
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                type={show ? 'text' : 'password'}
                                placeholder='Senha de acesso'
                                isInvalid={errors.password}
                            />

                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick}>
                                    {show ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    <FormControl isInvalid={errors.passwordConfirm} isRequired>
                        <FormLabel fontSize='xs'>Confirmar senha</FormLabel>
                        <InputGroup size='md'>
                            <Input
                                pr='4.5rem'
                                name="passwordConfirm"
                                onChange={handleChange}
                                value={values.passwordConfirm}
                                type={show ? 'text' : 'password'}
                                placeholder='Confirme a senha'
                            />

                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick}>
                                    {show ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>

                        <FormErrorMessage>{errors.passwordConfirm}</FormErrorMessage>
                    </FormControl>

                    <FormControl>
                        <ButtonGroup>
                            <Button colorScheme="teal" onClick={handleSubmit}>Registrar</Button>
                            <Button variant='ghost' onClick={resetForm}>Limpar</Button>
                        </ButtonGroup>
                    </FormControl>

                    <VStack width='100%' mt={5}>
                        <Link href='/login'>
                            <Text fontSize='xs'>Já tem conta? Fazer login!</Text>
                        </Link>
                    </VStack>
                </VStack>
            )}
        </Formik>
    )
}

export default RegisterForm