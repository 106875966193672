
import API from '../backend/axiosConfig'
import ApiException from '../backend/ApiException'

const getLocationsAll = async ({ page = 1, limit = 10, searchQuery }) => {
    try {
        const response = await API.get('/location', {
            params: {
                page,
                limit,
                search: searchQuery
            }
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const getLocationById = async (_id = '') => {

    if (!_id && typeof _id !== 'string') throw new ApiException('Nenhum ID encontrado')

    try {
        const response = await API.get(`/location/${_id}`)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const updateLocationById = async ({
    _id,
    name,
    address,
    rep,
    photo,
    description
}) => {
    try {
        // Cria uma instância de FormData
        const formData = new FormData();
        formData.append('name', name);
        formData.append('address', JSON.stringify(address)); // Pode precisar de formatação especial
        formData.append('rep', JSON.stringify(rep)); // Pode precisar de formatação especial
        formData.append('photo', photo); // Adicione o arquivo de imagem aqui
        formData.append('description', description);

        const response = await API.put(`/location/${_id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Content-Type': formData.getHeaders()['Content-Type'] // Para node.js server-side
            }
        });


        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
};


const deleteLocationById = async (_id = '') => {
    try {
        const response = await API.delete(`/location/${_id}`)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const createLocation = async ({
    name,
    address,
    rep,
    photo,
    description
}) => {
    try {
        // Crie uma instância do FormData
        const formData = new FormData();

        // Adicione os campos ao FormData
        formData.append('name', name);
        formData.append('address', JSON.stringify(address)); // Se address for um objeto, converta para string
        formData.append('rep', JSON.stringify(rep)); // Se rep for um objeto, converta para string
        formData.append('description', description);

        // Adicione o arquivo (se existir)
        if (photo) {
            if (typeof photo === 'string') {
                // Se photo for uma string, apenas adicione o valor
                formData.append('photo', photo);
            } else if (photo instanceof File) {
                // Se photo for um arquivo, adicione-o
                formData.append('photo', photo);
            }
        }

        // Envie a requisição
        const response = await API.post('/location', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
};


export {
    getLocationsAll,
    getLocationById,
    deleteLocationById,
    updateLocationById,
    createLocation
}