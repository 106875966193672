import React from 'react';
import {
    Box,
    VStack,
    Heading,
    Text,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
    Badge,
    Divider,
    Grid,
    GridItem,
    IconButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import html2pdf from 'html2pdf.js';

import { FaPrint } from "react-icons/fa";
import useDeleteBudget from '../../../hooks/budget/useDeleteBudget';

const BudgetContainer = ({ budget }) => {
    const navigate = useNavigate()
    const { deleteBudget } = useDeleteBudget()

    const handleDelete = async () => {
        if (budget._id) {
            await deleteBudget(budget._id)
            navigate('/dashboard/orcamentos')
        }
    }
    const handlePrint = () => {
        const element = document.getElementById('forPrint');
        html2pdf(element, {
            filename: `orcamento-${budget._id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        });
    }

    if (!budget) return <Text>Nenhum orçamento disponível para exibição.</Text>;

    return (
        <Box p={8} bg="white" borderRadius="md" boxShadow="lg" maxW="900px" id='forPrint'>
            <VStack spacing={10} align="start">
                {/* Título Principal */}
                <Box w="100%">
                    <Text textColor={'gray.500'}>Orçamento #{budget._id.toString()}</Text>
                    <Heading mb={4} textAlign="left" w="100%">
                        Detalhes do Orçamento
                    </Heading>
                </Box>

                {/* Informações do Cliente */}
                <Box w="100%">
                    <Heading size="md" mb={3}>Informações do Cliente</Heading>
                    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
                        <GridItem><Text><strong>Nome:</strong> {budget.client.name}</Text></GridItem>
                        <GridItem><Text><strong>E-mail:</strong> {budget.client.email}</Text></GridItem>
                        <GridItem><Text><strong>Telefone:</strong> {budget.client.phonenumber || 'Não informado'}</Text></GridItem>
                        <GridItem><Text><strong>Documento:</strong> {budget.client.type} {budget.client.document}</Text></GridItem>
                    </Grid>
                </Box>

                <Divider borderColor="gray.300" />

                {/* Detalhes do Projeto */}
                <Box w="100%">
                    <Heading size="md" mb={3}>Detalhes do Projeto</Heading>
                    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
                        <GridItem><Text><strong>Tipo de Serviço:</strong> {budget.serviceType}</Text></GridItem>
                        <GridItem><Text><strong>Status:</strong>{' '}
                            <Badge colorScheme={
                                budget.status === 'aprovado' ? 'green' :
                                    budget.status === 'rejeitado' ? 'red' : 'yellow'
                            }>
                                {budget.status}
                            </Badge>
                        </Text></GridItem>
                        <GridItem colSpan={2}>
                            <Text><strong>Descrição:</strong> {budget.serviceDescription}</Text>
                        </GridItem>
                    </Grid>
                </Box>

                <Divider borderColor="gray.300" />

                {/* Itens do Orçamento */}
                <Box w="100%">
                    <Heading size="md" mb={3}>Itens do Orçamento</Heading>
                    <TableContainer>
                        <Table size="md" variant="striped">
                            <Tbody>
                                {budget.items.map((item, index) => (
                                    <Tr key={index}>
                                        <Td><strong>{item.name}</strong></Td>
                                        <Td>{item.description}</Td>
                                        <Td isNumeric>R$ {item.cost.toFixed(2)}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>

                <Divider borderColor="gray.300" />

                {/* Resumo de Pagamento */}
                <Box w="100%">
                    <Heading size="md" mb={3}>Resumo de Pagamento</Heading>
                    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
                        <GridItem><Text><strong>Termos:</strong> {budget.paymentTerms}</Text></GridItem>
                        <GridItem><Text><strong>Método:</strong> {budget.paymentMethod}</Text></GridItem>
                        <GridItem colSpan={2}>
                            <Text fontWeight="bold" fontSize="lg"><strong>Total:</strong> R$ {budget.totalCost.toFixed(2)}</Text>
                        </GridItem>
                    </Grid>
                </Box>

                <Divider borderColor="gray.300" />

                {/* Datas Importantes */}
                <Box w="100%">
                    <Heading size="md" mb={3}>Datas Importantes</Heading>
                    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
                        <GridItem><Text><strong>Validade:</strong> {new Date(budget.expireDate).toLocaleDateString()}</Text></GridItem>
                        <GridItem><Text><strong>Garantia:</strong> {new Date(budget.warrantyDate).toLocaleDateString()}</Text></GridItem>
                    </Grid>
                </Box>

                <Divider borderColor="gray.300" />

                {/* Ações */}
                <Box w="100%">
                    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
                        <GridItem>
                            <IconButton onClick={() => navigate(`/dashboard/orcamentos/editar/${budget._id}`, { state: { budget } })} variant="ghost" size="lg" aria-label="Editar Orçamento" icon={<EditIcon />} />
                            <IconButton onClick={() => handleDelete()} variant="ghost" size="lg" aria-label="Excluir Orçamento" icon={<DeleteIcon />} />
                            <IconButton onClick={() => handlePrint()} variant="ghost" size="lg" aria-label="Imprimir Orçamento" icon={<FaPrint />} />
                        </GridItem>
                    </Grid>
                </Box>
            </VStack>
        </Box>
    );
};

export default BudgetContainer;
