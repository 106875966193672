import API from '../backend/axiosConfig';
import ApiException from '../backend/ApiException';

// Função para listar pagamentos com paginação e filtros opcionais
const getPayments = async (page = 1, limit = 10, userId = null, status = null) => {
    try {
        const params = { page, limit };
        if (userId) params.userId = userId;
        if (status) params.status = status;

        const response = await API.get('/payments', { params });
        return {
            docs: response.data.docs,
            page: response.data.page,
            totalPages: response.data.totalPages,
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            throw new ApiException(error.response.data.error);
        } else {
            throw new ApiException(error.message);
        }
    }
};

// Função para criar um novo pagamento
const createPayment = async (data) => {
    try {
        const response = await API.post('/payments', data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            throw new ApiException(error.response.data.error);
        } else {
            throw new ApiException(error.message);
        }
    }
};

// Função para buscar um pagamento por ID
const getPaymentById = async (id) => {
    try {
        const response = await API.get(`/payments/${id}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            throw new ApiException(error.response.data.error);
        } else {
            throw new ApiException(error.message);
        }
    }
};

// Função para atualizar um pagamento por ID
const updatePayment = async (id, data) => {
    try {
        const response = await API.put(`/payments/${id}`, data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            throw new ApiException(error.response.data.error);
        } else {
            throw new ApiException(error.message);
        }
    }
};

// Função para deletar um pagamento por ID
const deletePayment = async (id) => {
    try {
        const response = await API.delete(`/payments/${id}`);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            throw new ApiException(error.response.data.error);
        } else {
            throw new ApiException(error.message);
        }
    }
};

export { getPayments, createPayment, getPaymentById, updatePayment, deletePayment };
