import { Card, CardHeader, CardBody, CardFooter, Text, Heading, Table, Tbody, Tr, Td, IconButton, HStack, Badge, Progress, Button } from '@chakra-ui/react'

import { ViewIcon, DeleteIcon, EditIcon, TimeIcon } from '@chakra-ui/icons';
import { FaRegComment    } from "react-icons/fa";

import DateDisplay from '../../../ui/DateDisplay';
import isDateNear from '../../../../misc/dataNear';
import PopoverUi from '../../../ui/Popover';

function CronCard ({ cron, view, makenote, handleEdit, handleDelete, ...rest }){
    const isNear = isDateNear(cron.timestart, 5)

    const checkInitRecord = (cron) => {
        var hasRecorded = cron.scenes.filter( scene => scene.recorded)

        return hasRecorded.length >= 1
    }

    const checkProgress = (cron) => {
        var totalScenes = cron.scenes.length;
        var recordedScenes = cron.scenes.filter( scene => scene.recorded).length
        var progress = ((recordedScenes / totalScenes) * 100)
        return progress
    }

    return (
        <Card {...rest}>
            <CardHeader>
                <HStack justifyItems='center'>
                    <Heading size='md' color='teal'>
                        {cron.title}
                    </Heading>
                    {isNear && !checkInitRecord(cron) ? (
                        <Badge colorScheme='teal' p={1}> <TimeIcon /> Chegando</Badge>
                    ): (
                        <></>
                    )}
                </HStack>
                
                <Progress mt={4} value={checkProgress(cron)} colorScheme='teal' size='xs' />
            </CardHeader>

            <CardBody>
                <Table>
                    <Tbody>
                        
                        <Tr>
                            <Td>
                                <Text as='b'>Roteiro</Text>
                            </Td>
                            <Td>{cron.script.title}</Td>
                        </Tr>

                        <Tr>
                            <Td>
                                <Text as='b'>Ínicio</Text>
                            </Td>
                            <Td><DateDisplay date={cron.timestart} /></Td>
                        </Tr>

                        <Tr>
                            <Td>
                                <Text as='b'>Término</Text>
                            </Td>
                            <Td><DateDisplay date={cron.timeend} /></Td>
                        </Tr>
                    </Tbody>
                </Table>
                
            </CardBody>

            <CardFooter>
                <HStack>
                    <IconButton icon={<ViewIcon />} onClick={() => view(cron)} />
                    <IconButton icon={<EditIcon />} onClick={() => handleEdit(cron)} />
                    <IconButton icon={<FaRegComment />} onClick={() => makenote(cron)} />
                    <PopoverUi element={<IconButton icon={<DeleteIcon />} />}>
                        <Text>Tem certeza que deseja excluir este cronograma?</Text>
                        <Button onClick={() => handleDelete(cron)}>Sim</Button>
                    </PopoverUi>
                </HStack>
            </CardFooter>
        </Card>
    )
}

export default CronCard;