import { useEffect, useState } from 'react';
import budgetService from '../../services/backend/BudgetService';

const BudgetList = () => {
    const [budgets, setBudgets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [pagination, setPagination] = useState({
        docs: [],
        page: 1,
        limit: 10,
        totalPages: 0,
        paginCount: 0,
        hasPrevPage: false,
        hasNextPage: false,
        prevPage: 0,
        nextPage: 0,
        totalDocs: 0,
    });

    const toNextPage = () => {
        if (pagination.hasNextPage) {
            setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
        }
    };

    const toPrevPage = () => {
        if (pagination.hasPrevPage) {
            setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
        }
    };

    const fetchBudgets = async () => {
        setLoading(true);
        setError(null);

        try {
            const params = {
                page: pagination.page,
                limit: pagination.limit,
                search: search
            }
            const data = await budgetService.getBudgets(params);
            setBudgets(data.docs);
            setPagination(data);
        } catch (err) {
            setError(err.error || 'Erro ao carregar os orçamentos.');
        } finally {
            setLoading(false);
        }
    };

    const refresh = () => {
        fetchBudgets();
    };

    useEffect(() => {

        fetchBudgets();
    }, [pagination.page, search]);

    return {
        budgets,
        loading,
        error,
        pagination,
        toNextPage,
        toPrevPage,
        setSearch,
        refresh
    }
};

export default BudgetList;