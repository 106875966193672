
// import API from '../backend/axiosConfig'
// import ApiException from '../backend/ApiException'

// const getCronsAll = async ({ page = 1, limit = 10, searchQuery }) => {
//     try {
//         const response = await API.get('/crons', {
//             params: {
//                 page,
//                 limit,
//                 search: searchQuery
//             }
//         })

//         return response.data
//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const getCronById = async (_id = '') => {

//     if (!_id && typeof _id !== 'string') new ApiException('Nenhum ID encontrado')

//     try {
//         const response = await API.get(`/crons/${_id}`)
//         return response.data
//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const updateCronByid = async ({
//     _id = '',
//     title,
//     timestart,
//     timend,
//     scenes,
//     locations,
//     equips,
//     notes,
//     script,
//     team
// }) => {
//     try {

//         const response = await API.put(`/crons/${_id}`, {
//             title,
//             timestart,
//             timend,
//             scenes,
//             locations,
//             equips,
//             notes,
//             script,
//             team
//         })

//         return response.data

//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const deleteCronById = async (_id = '') => {
//     try {
//         const response = await API.delete(`/crons/${_id}`)
//         return response.data
//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const createCron = async ({
//     title,
//     timestart,
//     timend,
//     scenes,
//     locations,
//     equips,
//     notes,
//     script,
//     team
// }) => {
//     try {

//         const response = await API.post(`/crons`, {
//             title,
//             timestart,
//             timend,
//             scenes,
//             locations,
//             equips,
//             notes,
//             script,
//             team
//         })

//         return response.data

//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const addNoteToCron = async (cronId, note) => {
//     try {
//         var response = await API.post(`crons/${cronId}/notes`, note)
//         return response.data
//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const removeNoteFromCron = async (cronId, noteId) => {
//     try {
//         var response = await API.delete(`crons/${cronId}/notes/${noteId}`)
//         return response.data

//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// export {
//     getCronsAll,
//     getCronById,
//     addNoteToCron,
//     removeNoteFromCron,
//     deleteCronById,
//     updateCronByid,
//     createCron
// }
import API from "../backend/axiosConfig";
import ApiException from "../backend/ApiException";

// Serviço de Crons
const cronService = {
  /**
   * Obtém a lista de crons com paginação
   * @param {Object} query - Parâmetros de consulta (página, limite, etc.)
   * @returns {Promise}
   */
  getCronsAll: (query = { page: 1, limit: 10, searchQuery: "" }) => {
    return API.get("/crons", { params: query })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao obter os crons.");
      });
  },

  getLatestCrons: () => {
    return API.get("/crons/latest")
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao obter os crons.");
      });
  },

  /**
   * Obtém os detalhes de um cron pelo ID
   * @param {string} id - ID do cron
   * @returns {Promise}
   */
  getCronById: (id) => {
    if (!id || typeof id !== "string") {
      throw new ApiException("ID inválido ou não fornecido.");
    }

    return API.get(`/crons/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao obter o cron.");
      });
  },

  /**
   * Atualiza um cron existente
   * @param {string} id - ID do cron
   * @param {Object} data - Dados atualizados do cron
   * @returns {Promise}
   */
  updateCronById: (id, data) => {
    return API.put(`/crons/${id}`, data)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao atualizar o cron.");
      });
  },

  /**
   * Remove um cron pelo ID
   * @param {string} id - ID do cron
   * @returns {Promise}
   */
  deleteCronById: (id) => {
    return API.delete(`/crons/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao excluir o cron.");
      });
  },

  /**
   * Cria um novo cron
   * @param {Object} data - Dados do cron
   * @returns {Promise}
   */
  createCron: (data) => {
    return API.post("/crons", data)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao criar o cron.");
      });
  },

  /**
   * Adiciona uma nota a um cron
   * @param {string} cronId - ID do cron
   * @param {Object} note - Nota a ser adicionada
   * @returns {Promise}
   */
  addNoteToCron: (cronId, note) => {
    return API.post(`/crons/${cronId}/notes`, note)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao adicionar a nota.");
      });
  },

  /**
   * Remove uma nota de um cron
   * @param {string} cronId - ID do cron
   * @param {string} noteId - ID da nota
   * @returns {Promise}
   */
  removeNoteFromCron: (cronId, noteId) => {
    return API.delete(`/crons/${cronId}/notes/${noteId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao remover a nota.");
      });
  },
};

export default cronService;
