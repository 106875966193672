import React from "react";
import { Helmet } from "react-helmet";

import LoginLayout from "../layouts/LoginLayout";
import { SITE_NAME } from "../../misc/siteConfig";

import { Button, FormControl, FormErrorMessage, FormLabel, Input, VStack, Text } from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from 'yup';
import { Link } from "react-router-dom";

import { forgotPassword } from "../../services/backend/UserService";
import { useToastContext } from "../../context/ToastContext";

export default function ForgotPassword() {
    const { showToast } = useToastContext()
    const handleSubmit = async (values) => {
        try {
            const makeReset = await forgotPassword(values.email)

            if (makeReset.error) {
                showToast({
                    title: 'Falha ao redefinir senha',
                    description: makeReset.error,
                    status: 'error'
                })
            } else {
                showToast({
                    title: 'Redefinir senha',
                    description: makeReset.message,
                    status: 'success'
                })
            }
        } catch (error) {
            showToast({
                title: 'Falha ao redefinir senha',
                description: error.error,
                status: 'error'
            })
        }
    }

    return (
        <LoginLayout>
            <VStack p={10} bgColor={'white'} borderRadius={'md'} boxShadow={'md'}>
                <Helmet>
                    <title>{SITE_NAME} &#8208; Recuperar Senha</title>
                </Helmet>

                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email().required(),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({ handleChange, handleSubmit, values, errors, touched }) => (
                        <form onSubmit={handleSubmit}>
                            <FormControl id="email" mb={4} isInvalid={touched.email && errors.email} isRequired>
                                <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Endereço de email</FormLabel>
                                <Input
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    placeholder="Digite seu email"
                                />

                                {touched.email && errors.email && (
                                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl>
                                <Button width={'100%'} type="submit" colorScheme={'teal'} mb={4}>Recuperar Senha</Button>
                                <Link to={'/login'} viewTransitionName={'login'}>
                                    <Text textAlign={'center'} fontSize={'xs'} textDecoration={'underline'}>Fazer login</Text>
                                </Link>
                            </FormControl>
                        </form>
                    )}
                </Formik>
            </VStack>
        </LoginLayout>
    )
}