import { Box, Text, HStack, StackDivider } from "@chakra-ui/react"
const ScriptInfo = ({ script }) => {
    return (
        <Box p={4} borderWidth={1} borderRadius="md" boxShadow="md" bgColor="white">
            <HStack spacing={4} divider={<StackDivider />}>
                <Box>
                    <Text fontWeight="bold" fontSize="lg">Título:</Text>
                    <Text>{script.title}</Text>
                </Box>
                <Box>
                    <Text fontWeight="bold" fontSize="lg">Descrição:</Text>
                    <Text>{script.description || 'Nenhuma descrição fornecida.'}</Text>
                </Box>

                <Box>
                    <Text fontWeight="bold" fontSize="lg">Data de Criação:</Text>
                    <Text>{new Date(script.createdAt).toLocaleString()}</Text>
                </Box>

                <Box>
                    <Text fontWeight="bold" fontSize="lg">Última Atualização:</Text>
                    <Text>{new Date(script.updatedAt).toLocaleString()}</Text>
                </Box>
            </HStack>
        </Box>
    )
}

export default ScriptInfo