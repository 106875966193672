import axios from 'axios';
import Cookies from 'js-cookie';

// Cria uma instância do Axios
const API = axios.create({
  baseURL: 'http://localhost:5000/api', // substitua pela sua URL base
  timeout: 10000, // Tempo limite de 10 segundos
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': true
    // Adicione outros headers padrões se necessário
  },
});

// Interceptor de requisição (opcional)
API.interceptors.request.use(
  config => {
    // Adicione tokens de autenticação ou outros headers dinâmicos
    const localToken = Cookies.get('_auth');
    if (localToken) {
      config.headers.Authorization = `Bearer ${localToken}`;
    }
    return config;
  },
  error => {
    // Lide com erros de requisição aqui
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  response => {
    // Você pode processar a resposta aqui antes de retornar
    return response;
  },
  error => {
    // Lide com erros de resposta aqui
    if (error.response.status === 401 || error.response.status === 403) {
      // Por exemplo, redirecionar para login se não estiver autorizado
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default API;
