import React from 'react';
import { Box, Card, CardHeader, HStack, Text, Divider } from '@chakra-ui/react';

const CronScript = ({ script }) => {
  return (
    <Card p={10} boxShadow='md' bgColor='white'>
      <CardHeader p={0}>
        <Text as='b' fontSize='lg'>Informações de Roteiro</Text>
        <Divider mt={5} mb={5}/>
      </CardHeader>

      <HStack>
        <Box fontSize="lg" width='50%'>
          <Text as='b' fontSize='sm'>Título</Text>
          <Text>{script.title}</Text>
        </Box>

        <Box fontSize="lg" width='50%'>
          <Text as='b' fontSize='sm'>Descrição</Text>
          <Text>{script.content}</Text>
        </Box>
      </HStack>
    </Card>
  );
};

export default CronScript;
