import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Accordion, Box, ButtonGroup, Button } from "@chakra-ui/react";

import SectionSocialIcons from "./components/sectionSocialIcons";
import SectionAnnouncement from "./components/sectionannouncement";

export default function SiteConfigForm({ siteConfig, submit }) {

    const validationsSchema = Yup.object().shape({
        _id: Yup.string(),
        name: Yup.string().min(3, "Nome deve ter pelo menos 3 caracteres").required("Nome é obrigatório"),
        announcement: Yup.array().of(Yup.object().shape({
            title: Yup.string().required("Título é obrigatório"),
            message: Yup.string().required("Mensagem é obrigatório"),
            type: Yup.string().oneOf(["info", "warning", "error", "success"]).default("info"),
            status: Yup.boolean().default(false),
            button: Yup.object().shape({
                label: Yup.string(),
                link: Yup.string().url('Link inválido')
            }),
            dimissable: Yup.boolean().default(true)
        })),
        socialIcons: Yup.array().of(Yup.object().shape({
            name: Yup.string().required("Nome é obrigatório"),
            link: Yup.string()
                .test(
                    "is-url-or-email",
                    "Link inválido",
                    (value) =>
                        Yup.string().url().isValidSync(value) || Yup.string().email().isValidSync(value)
                ),
        }))
    })


    return (
        <Formik
            enableReinitialize
            initialValues={{
                _id: siteConfig?._id || "",
                name: siteConfig?.name || "",
                announcement: siteConfig?.announcement || [],
                socialIcons: siteConfig?.socialIcons || []
            }}
            validationSchema={validationsSchema}
            onSubmit={submit}
        >
            {({ errors, touched, setFieldValue, values }) => (
                <Form>
                    <Box p={4}>
                        <Accordion allowToggle defaultIndex={[0]}>
                            <SectionSocialIcons />
                            <SectionAnnouncement />
                        </Accordion>

                        <ButtonGroup mt={4}>
                            <Button type="submit" variant={"solid"} colorScheme={"teal"}>Salvar</Button>
                        </ButtonGroup>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}