
import API from '../backend/axiosConfig'
import ApiException from '../backend/ApiException'

const getEquipsAll = async ({page = 1, limit = 10, searchQuery}) => {
    try {
        const response = await API.get('/equip', {
            params: {
                page,
                limit,
                search: searchQuery
            }
        })
        
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const getAvaliableEquips = async ({searchQuery}) => {
    try {
        const response = await API.get('/equip/avaliable', {
            params: {
                search: searchQuery
            }
        })
        
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const getEquipById = async (_id = '') => {

    if(!_id && typeof _id !== 'string') throw new ApiException('Nenhum ID encontrado')

    try {
        const response = await API.get(`/equip/get/${_id}`)
        
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const updateEquipById = async ({
    _id = '',
    type = '',
    model = '',
    quantity = 0
}) => {
    try {

        const response = await API.put(`/equip/update/${_id}`, {
            type,
            model,
            quantity
        })

        if (response.data.error) throw new ApiException(response.data.error)

        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const deleteEquipById = async (_id = '') => {
    try {
        const response = await API.delete(`/equip/delete/${_id}`)

        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const createEquip = async ({
    type = '',
    model = '',
    quantity = 0
}) => {
    try {

        const response = await API.post(`/equip`, {
            type,
            model,
            quantity
        })

        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

export {
    getEquipsAll,
    getEquipById,
    deleteEquipById,
    updateEquipById,
    createEquip,
    getAvaliableEquips
}