import { AccordionItem, AccordionButton, AccordionPanel, Box, FormControl, FormLabel, Input, FormErrorMessage, HStack } from "@chakra-ui/react";
import { Field, useFormikContext } from "formik";

export default function SectionSocialIcons() {
    const { touched, errors, values } = useFormikContext();
    return (
        <AccordionItem bgColor={'white'} borderRadius={'md'} boxShadow={'md'} my={4}>
            <h2>
                <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                        Redes Sociais
                    </Box>
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <Box mb={4}>
                    {values.socialIcons.map((socialIcon, index) => (
                        <Box key={index} mb={4}>
                            <HStack>
                                <FormControl isInvalid={touched.socialIcons?.[index]?.name && errors.socialIcons?.[index]?.name} width={'10%'}>
                                    <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Nome</FormLabel>
                                    <Field as={Input} name={`socialIcons.${index}.name`} placeholder="Digite o nome" readOnly />
                                    <FormErrorMessage>{errors.socialIcons?.[index]?.name}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={touched.socialIcons?.[index]?.link && errors.socialIcons?.[index]?.link} width={'90%'}>
                                    <FormLabel textTransform={'uppercase'} fontSize={'xs'}>Link</FormLabel>
                                    <Field as={Input} name={`socialIcons.${index}.link`} placeholder="Digite o link" />
                                    <FormErrorMessage>{errors.socialIcons?.[index]?.link}</FormErrorMessage>
                                </FormControl>
                            </HStack>
                        </Box>
                    ))}
                </Box>
            </AccordionPanel>
        </AccordionItem>
    )
}