import React, { useState, useEffect } from 'react';
import { Box, Button, Text, Stack, Progress } from '@chakra-ui/react';

const Countdown = ({ initialMinutes = 1, isResetable = false, isPausable = false, isSimple = false, callback, ...props }) => {
    const [time, setTime] = useState(initialMinutes * 60); // Converte minutos em segundos
    const [isActive, setIsActive] = useState((!isResetable && !isPausable) || false);
    const totalTime = initialMinutes * 60; // Tempo total em segundos

    // Formatação de minutos e segundos
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // Função para iniciar/pausar o countdown
    const toggle = () => {
        setIsActive(!isActive);
    };

    // Função para resetar o countdown
    const reset = () => {
        setTime(initialMinutes * 60);
        setIsActive(false);
    };

    // Efeito para controlar o countdown
    useEffect(() => {
        let interval = null;
        if (isActive && time > 0) {
            interval = setInterval(() => {
                setTime((time) => time - 1);
            }, 1000);
        } else if (time === 0) {
            setIsActive(false);
            callback();
        }
        return () => clearInterval(interval);
    }, [isActive, time]);

    // Calcula a porcentagem da barra de progresso
    const progressPercentage = (time / totalTime) * 100;

    return (
        <>
            {isSimple && (
                <>
                    {formatTime(time)}
                </>
            )}

            {!isSimple && (
                <Box textAlign="center" {...props}>
                    <Text fontSize="lg" fontWeight="bold">
                        {formatTime(time)}
                    </Text>
                    <Progress value={progressPercentage} size="lg" colorScheme="teal" mt={4} />
                    <Stack spacing={4} direction="row" justify="center" mt={6}>
                        {isPausable && (
                            <Button colorScheme={isActive ? 'red' : 'green'} onClick={toggle}>
                                {isActive ? 'Pausar' : 'Iniciar'}
                            </Button>
                        )}
                        {isResetable && (
                            <Button colorScheme="teal" onClick={reset}>
                                Resetar
                            </Button>
                        )}
                    </Stack>
                </Box>
            )}
        </>
    );
};

export default Countdown;