function ApiException(message) {
    this.name = 'API ERROR';
    this.message = message || 'Algo não saiu como esperado';
    this.stack = (new Error()).stack;
}

ApiException.prototype = Object.create(Error.prototype);
ApiException.prototype.constructor = ApiException;

export default ApiException;
