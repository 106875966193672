import { Box, Button, Center, Heading, VStack, Text, HStack, Image } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { SITE_NAME } from "../../../misc/siteConfig";
import Upgrade from '../../../assets/images/Upgrade.jpg'
import { useNavigate } from "react-router-dom";

function ToolNotIncluded() {
    const nagivate = useNavigate();

    const handleClick = () => {
        nagivate('/dashboard/premium/show')
    }

    return (
        <Center height={'100vh'}>
            <Helmet>
                <title>{SITE_NAME} &#8208; Ferramenta Indisponível</title>
            </Helmet>
            <HStack bgColor={'white'} boxShadow={'md'} borderRadius={'md'} width={'40%'}>
                <Image width={'350px'} src={Upgrade} alt="Faça Upgrade de Assinatura" />
                <VStack align={'start'} p={10} gap={10}>
                    <Heading>Indisponível</Heading>
                    <Text textAlign={'start'}>
                        Essa ferramenta não está incluída no seu plano atual, clique no botão para fazer upgrade da sua assinatura para um plano melhor.
                    </Text>
                    <Button colorScheme="teal" onClick={handleClick}>Upgrade de assinatura</Button>
                </VStack>
            </HStack>
        </Center>
    )
}

export default ToolNotIncluded