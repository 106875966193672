import { Heading, Image, Spacer, VStack, Highlight, GridItem, HStack, Text, Box, Icon, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Header_Script from '../../assets/images/Header_Script.png'
import Cron from '../../assets/images/Cron.png'

import { ReactComponent as ScriptIcon } from '../../assets/icons/script.svg'
import { ReactComponent as CronIcon } from '../../assets/icons/cron.svg'
import { ReactComponent as EquipsIcon } from '../../assets/icons/equips.svg'
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg'

import FolderImg from '../../assets/images/Folder.png'

import SubscribeForm from "../../components/dashboard/premium/subscribeForm";
import useGetPlans from "../../hooks/plans/useGetPlans";

import Helmet from "react-helmet";
import { useEffect } from "react";
import { SITE_NAME } from "../../misc/siteConfig";

import { motion } from "framer-motion";

function Homepage() {
    const { plans } = useGetPlans()
    const navigate = useNavigate()

    useEffect(() => {

    }, [plans])

    return (
        <>
            <GridItem >
                <Helmet>
                    <title>{SITE_NAME} &#8208; Plataforma de Gerenciamento de Produções Visuais</title>
                </Helmet>
                <VStack pt={20} bgGradient={'linear(to-t, white, teal.100)'}>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                        <Heading size={'4xl'} fontWeight={'bold'} color={'gray.600'} mt={5}>
                            <Highlight query={['organize', 'crie']} styles={{ bgGradient: 'linear(to-r, teal.300, teal.600)', bgClip: 'text' }}>Organize menos, crie mais!</Highlight>
                        </Heading>
                    </motion.div>

                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.5 }}>
                        <Heading size={'lg'} color={'gray.600'}>Concentre-se no que realmente importa</Heading>
                    </motion.div>

                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 1 }}>
                        <Link to={'/registrar'}>
                            <Button mt={10} bgGradient={'linear(to-r, teal.300, teal.600)'} color={'white'} colorScheme="teal" onClick={() => navigate('/registrar')}>Iniciar teste gratuito!</Button>
                        </Link>
                    </motion.div>

                    <Spacer />

                    <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1, delay: 1 }}>
                        <Image src={Header_Script} />
                    </motion.div>
                </VStack>
            </GridItem>

            <GridItem py={'15vh'} id="ferramentas" bgColor={'gray.50'}>
                <HStack maxW={'1200px'} mx={'auto'} justifyContent={'center'}>
                    <VStack width={'50%'} spacing={10} alignItems={'flex-start'}>
                        <Heading size={'2xl'} color={'teal.600'} width={'90%'}>
                            Como nossa plataforma facilita sua vida
                        </Heading>

                        <Text fontSize={'lg'} color={'gray.600'} width={'90%'}>Simplifique seus processos com uma plataforma feita para entender a rotina intensa de quem cria.</Text>

                        <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                            <HStack width={'70%'} gap={10}>
                                <Icon as={ScriptIcon} fontSize={'4xl'} borderRadius={'md'} />
                                <Box>
                                    <Heading size={'md'} color={'teal.600'}>Editor de Roteiros</Heading>

                                    <Text fontSize={'xs'} color={'gray.600'}>
                                        Escreva e organize suas ideias de forma fluida com um editor intuitivo. Visualize e ajuste cada cena, sequências, e diálogos, sem perder o fio da narrativa.
                                    </Text>
                                </Box>
                            </HStack>
                        </motion.div>

                        <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                            <HStack width={'70%'} gap={10}>
                                <Icon as={CronIcon} fontSize={'4xl'} borderRadius={'md'} />
                                <Box>
                                    <Heading size={'md'} color={'teal.600'}>Cronograma</Heading>

                                    <Text fontSize={'xs'} color={'gray.600'}>
                                        Cronograma detalhado e prático para um fluxo de trabalho mais eficiente, monte equipes, adicione notas, escolha equipamentos, determine prazos e gerencie o progresso.
                                    </Text>
                                </Box>
                            </HStack>
                        </motion.div>

                        <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                            <HStack width={'70%'} gap={10}>
                                <Icon as={EquipsIcon} fontSize={'4xl'} borderRadius={'md'} />
                                <Box>
                                    <Heading size={'md'} color={'teal.600'}>Inventário de Equipamentos</Heading>

                                    <Text fontSize={'xs'} color={'gray.600'}>
                                        Você pode organizar o que precisa para cada projeto, evitando esquecimentos e garantindo que tudo esteja no lugar certo, na hora certa.
                                    </Text>
                                </Box>
                            </HStack>
                        </motion.div>

                        <motion.div initial={{ opacity: 0, x: 100 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                            <HStack width={'70%'} gap={10}>
                                <Icon as={LocationIcon} fontSize={'4xl'} borderRadius={'md'} />
                                <Box>
                                    <Heading size={'md'} color={'teal.600'}>Lista de clientes e locações</Heading>

                                    <Text fontSize={'xs'} color={'gray.600'}>
                                        Cadastre seus clientes e locações e tenha acesso rápido à sua carteira de clientes, associe projetos diretamente aos clientes cadastrados.
                                    </Text>
                                </Box>
                            </HStack>
                        </motion.div>

                    </VStack>

                    <VStack width={'50%'} justifyContent={'center'}>
                        <motion.div initial={{ y: 5 }} whileInView={{ y: 0 }} transition={{ duration: 1, delay: 1, repeat: Infinity, repeatType: 'reverse' }}>
                            <Image src={Cron} />
                        </motion.div>
                    </VStack>
                </HStack>
            </GridItem>

            <GridItem bgColor={'gray.50'}>
                <HStack py={20} maxW={'1200px'} mx={'auto'} justifyContent={'center'}>
                    <VStack width={'50%'} justifyContent={'center'}>
                        <Image src={FolderImg} />
                    </VStack>

                    <VStack width={'50%'} spacing={10} alignItems={'flex-start'}>
                        <Heading size={'2xl'} color={'teal.600'} width={'90%'}> Compartilhe suas ideas com os seus clientes </Heading>

                        <Text fontSize={'lg'} color={'gray.600'} width={'90%'}>Envie um link para seu cliente acompanhar  a criação do roteiro ou compartilhar ideias iniciais.</Text>

                        <motion.div initial={{ y: 5 }} whileInView={{ y: 0 }} transition={{ duration: 1, delay: 1, repeat: Infinity, repeatType: 'reverse' }}>
                            <Link to="/registrar">
                                <Button bgGradient={'linear(to-r, teal.300, teal.600)'} color={'white'} colorScheme="teal">Iniciar teste gratuitamente</Button>
                            </Link>
                        </motion.div>
                    </VStack>
                </HStack>
            </GridItem>

            <GridItem py={'15vh'} bgColor={'gray.50'}>
                <VStack maxW={'1200px'} mx={'auto'} justifyContent={'center'} spacing={10}>
                    <Heading size={'4xl'} color={'gray.600'} width={'90%'} textAlign={'center'}>
                        <Highlight query={['Videomakers', 'equipes']} styles={{ bgGradient: 'linear(to-r, teal.400, teal.600)', color: 'white', bgClip: 'text' }}>Videomakers, freelancers e pequenas equipes.</Highlight>
                    </Heading>

                    <Text p={10} fontSize={'lg'} color={'gray.600'} width={'75%'} textAlign={'center'} bgColor={'gray.100'} borderRadius={'md'} boxShadow={'lg'}>
                        O mercado oferece poucas ferramentas específicas que realmente apoiam o planejamento e a
                        execução dos projetos. Esta ferramenta nasceu da necessidade real de centralizar a organização
                        e otimizar o tempo – porque sabemos que cada minuto conta quando a inspiração surge.
                    </Text>

                    <motion.div initial={{ scale: 0 }} whileInView={{ scale: 1 }} transition={{ duration: 1, delay: 1 }}>
                        <Link to="/registrar">
                            <Button bgGradient={'linear(to-r, teal.300, teal.600)'} color={'white'} colorScheme="teal">Iniciar teste gratuitamente</Button>
                        </Link>
                    </motion.div>
                </VStack>
            </GridItem>

            <GridItem py={'10vh'} bgColor={'gray.50'} id="planos">
                <VStack maxW={'1200px'} mx={'auto'} justifyContent={'center'} spacing={10}>
                    <Heading size={'3xl'} width={'70%'} textAlign={'center'} color={'teal.600'}>A organização pode transformar sua criação.</Heading>

                    <SubscribeForm plans={plans} onSubmit={(plan) => navigate(`/registrar`)} />
                </VStack>
            </GridItem>
        </>
    )
}

export default Homepage;