import React, { useState, useEffect, useCallback } from 'react';
import NewScriptForm from './newScriptForm';
import {
	Box,
	Button,
	Input,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	IconButton,
	useDisclosure,
	VStack,
	Icon,
	HStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	LinkOverlay,
	LinkBox,
	Text
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon, EditIcon } from '@chakra-ui/icons';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import ScriptSharedModal from './scriptSharedModal';
import ScriptService from '../../../services/backend/ScriptService'
import { useToastContext } from '../../../context/ToastContext';

import { useNavigate } from 'react-router-dom';

import truncateText from '../../../misc/truncate';
import PopoverUi from '../../ui/Popover';

const ListScripts = () => {
	const navigate = useNavigate()

	const [scripts, setScripts] = useState([]);
	const [editScript, setEditScript] = useState()
	const [searchTerm, setSearchTerm] = useState('');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenShared, onOpen: onOpenShared, onClose: onCloseShared } = useDisclosure();

	const { showToast } = useToastContext()
	const [shareLoading, setShareLoading] = useState(false)
	const [scriptForShare, setScriptForShare] = useState({})

	const fetchScripts = useCallback(async (bypass = false) => {

		try {
			var response = await ScriptService.getScriptsAll({
				page,
				searchQuery: searchTerm,
				startDate: startDate,
				endDate: endDate
			});

			if (bypass) {
				setScripts(response.docs)
			} else {
				if (response.docs.length !== scripts.length) setScripts(response.docs)
			}


			setTotalPages(response.totalPages)
			setPage(response.page)

		} catch (error) {
			showToast({
				title: 'Algo inesperado aconteceu',
				description: error.message,
				status: 'error'
			})
		}

	}, [searchTerm, page, scripts, showToast, startDate, endDate])

	useEffect(() => {
		fetchScripts();
	}, [fetchScripts]);

	const handleDelete = async (_id) => {

		try {
			var response = await ScriptService.deleteScriptById(_id)
			fetchScripts(true)

			showToast({
				title: 'Deletado!',
				description: response.message,
				status: 'success'
			})
		} catch (error) {
			showToast({
				description: error.message,
				status: "error"
			});
		}

	};

	const handleShare = (script) => {
		setScriptForShare(script)
		onOpenShared()
	}

	const toggleShare = async (script) => {
		setShareLoading(true)
		try {
			if (script.customer !== null) {
				script.customer = script.customer._id
			}

			var response = await ScriptService.updateScriptById(script._id, {
				...script
			})

			fetchScripts(true)
			setShareLoading(false)
			showToast({
				title: script.shared === true ? 'Ativado' : 'Desativado',
				description: response.message,
				status: 'success'
			})
		} catch (error) {
			console.log(error)
			showToast({
				description: error.message,
				status: "error"
			});
		}
	}

	const handleSharedClose = () => {
		setScriptForShare({})
		onCloseShared()
	}

	const handleAddScript = async (action, script) => {
		try {

			var response = []
			if (action === 'create') response = await ScriptService.createScript(script)
			if (action === 'edit') response = await ScriptService.updateScriptById(script._id, script)

			setEditScript(null)
			showToast({
				description: response.message,
				status: 'success'
			})

			if (action === 'create') {
				navigate(`cenas/${response.script._id}`)
			}

			if (action === 'edit') {
				fetchScripts(true)
			}

		} catch (error) {
			showToast({
				description: error.message,
				status: 'error'
			})
		}
	};

	const handleEdit = (script) => {

		setEditScript(script)
		onOpen()

	}

	const handleModalClose = () => {
		setEditScript('')
		onClose()
	}

	return (
		<VStack spacing={4} align="stretch">
			<Box p={4} borderWidth="1px" borderRadius="md" display="flex" alignItems="center" spacing={4} mt={4} boxShadow='md' bgColor='white'>
				<Button colorScheme="teal" leftIcon={<AddIcon />} mr={4} onClick={onOpen}>Adicionar</Button>
				<HStack spacing={4} flex="1">
					<Input
						placeholder="Pesquisar..."
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						width="300px"
					/>

					<HStack width='50%'>
						<Input
							type="date"
							id="startDate"
							value={startDate}
							onChange={(e) => setStartDate(e.target.value)}
							width="50%"
							mr={4}
						/>
						<Input
							type="date"
							id="endDate"
							value={endDate}
							onChange={(e) => setEndDate(e.target.value)}
							width="50%"
						/>
					</HStack>
				</HStack>
			</Box>

			{scripts.length > 0 && (
				<Box p={4} borderWidth="1px" borderRadius="md" bgColor='white' boxShadow='lg' mt={6}>
					<Table variant="simple" style={{ tableLayout: "fixed" }}>
						<Thead>
							<Tr>
								<Th>Título</Th>
								<Th>Descrição</Th>
								<Th>Compartilhado</Th>
								<Th>Cliente</Th>
								<Th>Criado</Th>
								<Th>Modificado</Th>
								<Th></Th>
							</Tr>
						</Thead>
						<Tbody>
							{scripts.map((script) => (
								<Tr key={script._id}>
									<Td>
										<LinkBox>
											<LinkOverlay href={`/dashboard/roteiro/cenas/${script._id}`}>{truncateText(script.title, 20)}</LinkOverlay>
										</LinkBox>
									</Td>


									<Td overflow="hidden" textOverflow="ellipsis">
										<Text>{truncateText(script.description, 40)}</Text>
									</Td>
									<Td>{script.shared ? 'Ativado' : 'Desativado'}</Td>
									<Td>{script.customer && script.customer.fullname}</Td>
									<Td>{new Date(script.createdAt).toLocaleDateString()}</Td>
									<Td>{new Date(script.updatedAt).toLocaleDateString()}</Td>
									<Td>
										<HStack spacing={2}>
											<IconButton
												aria-label="Compartilhar"
												icon={<Icon as={ShareIcon} />}
												onClick={() => handleShare(script)}
											/>

											<IconButton
												aria-label="Editar"
												icon={<EditIcon />}
												onClick={() => handleEdit(script)}
											/>

											<PopoverUi
												element={<IconButton
													aria-label="Deletar"
													icon={<DeleteIcon />}
												/>}>
												<Text>Tem certeza de que deseja deletar este roteiro? Cronogramas e dados relacionados serão excluídos.</Text>
												<Button onClick={() => handleDelete(script._id)}>Deletar</Button>
											</PopoverUi>
										</HStack>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
					<HStack spacing={4} mt={4}>
						<Button
							onClick={() => setPage(prev => Math.max(prev - 1, 1))}
							isDisabled={page === 1}
						>
							Anterior
						</Button>

						<Box>
							Página {page} de {totalPages}
						</Box>

						<Button
							onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}
							isDisabled={page === totalPages}
						>
							Próximo
						</Button>
					</HStack>
				</Box>
			)}

			<ScriptSharedModal loading={shareLoading} isOpen={isOpenShared} onClose={handleSharedClose} script={scriptForShare} toggle={toggleShare} p={16} />

			<Modal isOpen={isOpen} onClose={handleModalClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Criar Novo Roteiro</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<NewScriptForm
							onClose={onClose}
							onSubmit={handleAddScript}
							script={editScript}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</VStack>
	);
};

export default ListScripts;
