import { Modal, ModalBody, ModalHeader, ModalContent, ModalOverlay, ModalCloseButton } from "@chakra-ui/react";

function CronNoteModal({ isOpen, onClose, children }) {
    return (
        <>
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={30}>
            <ModalHeader>Anotação</ModalHeader>

            <ModalCloseButton />

            <ModalBody>
                {children}
            </ModalBody>

        </ModalContent>
        </Modal>
        </>
    )
}

export default CronNoteModal