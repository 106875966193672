import React, { useState } from "react"
import { FormControl, Input, VStack, Button, FormErrorMessage, InputGroup, InputRightElement, Text, Link } from "@chakra-ui/react"
import * as Yup from 'yup';
import { Formik } from 'formik';

function LoginForm({ onSubmit }) {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)

    const handleSubmit = async (values) => {
        await onSubmit(values)
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email('Email inválido')
                    .required('Email de usuário obrigatório'),
                password: Yup.string()
                    .min(6, 'Senha deve ter pelo menos 6 caracteres')
                    .required('Senha de acesso obrigatória')
            })}
            onSubmit={handleSubmit}
        >
            {({ handleChange, handleSubmit, values, errors, touched }) => (
                <VStack bg='white' p='50px' boxShadow='lg' minWidth='17%' borderRadius={10}>
                    <FormControl mb={3} mt={6}>
                        <Input
                            name="email"
                            type="email"
                            placeholder="Email de usuário"
                            value={values.email}
                            onChange={(e) => { handleChange(e) }}
                            isInvalid={errors.email && touched.email}
                            boxShadow='md'
                            isRequired
                            autoFocus
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl>

                        <InputGroup size='md'>
                            <Input
                                pr='4.5rem'
                                name="password"
                                onChange={handleChange}
                                type={show ? 'text' : 'password'}
                                placeholder='Senha de acesso'
                                isInvalid={errors.password && touched.password}
                                boxShadow='md'
                            />

                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={handleClick}>
                                    {show ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>

                    <VStack width='100%' mt={5}>
                        <Button onClick={async (e) => await handleSubmit(e)} width='100%' colorScheme="teal">Fazer login</Button>
                        <Link href="/conta/recuperar" width='100%'>
                            <Button width='100%' variant={'outline'}>Esqueceu sua senha?</Button>
                        </Link>
                        <Link href='/registrar' mt={3}>
                            <Text fontSize='xs'>Não tem conta? Registrar-se agora</Text>
                        </Link>
                    </VStack>
                </VStack>
            )}
        </Formik>
    )
}

export default LoginForm