
import { Box, Table, Tbody, Td, Th, Thead, Tr, Image } from "@chakra-ui/react"
const SceneTable = ({ scenes }) => {

    return (
        <Box overflowX="auto" p={4} bgColor="white" borderWidth="1px" borderRadius="md" boxShadow="md" width='100%'>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Ordem</Th>
                        <Th>Imagem</Th>
                        <Th>Descrição</Th>
                        <Th>Conteúdo</Th>
                        <Th>Voiceover</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {scenes.map((scene) => (
                        <Tr key={scene._id}>
                            <Td>#{scene.order}</Td>
                            <Td>
                                {scene.image !== 'null' && (
                                    <Image
                                        src={`http://localhost:3000${scene.image}`}
                                        fallback={'Sem anexo'}
                                        alt={scene.description}
                                        boxSize="100px"
                                        objectFit="cover"
                                        borderRadius="md"
                                    />
                                )}
                            </Td>
                            <Td>{scene.description}</Td>
                            <Td>{scene.content}</Td>
                            <Td>{scene.voiceover}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default SceneTable;