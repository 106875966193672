import createStore from 'react-auth-kit/createStore';
import createRefresh from 'react-auth-kit/createRefresh';
import { refreshToken } from '../services/backend/UserService';

const refresh = createRefresh({
    interval: 600,
    refreshApiCallback: async (param) => {
        try {
            const response = await refreshToken(param.refreshToken);
            return {
                isSuccess: true,
                newAuthToken: response.accessToken,
                newAuthTokenExpireIn: 600,
                newRefreshToken: response.refreshToken,
            }
        }
        catch (error) {
            console.error(error)
            return {
                isSuccess: false
            }
        }
    }
})

const AuthStore = createStore({
    authName: '_auth',
    authType: 'cookie',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
    refresh: refresh
});

export default AuthStore;