import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    VStack,
    HStack,
    IconButton,
    Image,
    Icon,
    TableCaption,
    Button,
    Tooltip
} from '@chakra-ui/react'

import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Text,
    Progress,
    Box,
    Badge,
} from '@chakra-ui/react'

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from '@chakra-ui/react'

import { DeleteIcon, EditIcon, TimeIcon } from '@chakra-ui/icons';
import { FaPrint } from "react-icons/fa";
import { MdFiberManualRecord } from "react-icons/md";

import CronNote from './view/cronNote';
import isDateNear from '../../../misc/dataNear';
import { useEffect, useState } from 'react';

import { useToastContext } from "../../../context/ToastContext";
import Cronservice from '../../../services/backend/CronService';
import PopoverUi from '../../ui/Popover';
import DateDisplay from '../../ui/DateDisplay';

function CronModal({ isOpen, onClose, cron, onEdit, onDelete }) {
    const isNear = isDateNear(cron.timestart, 5)
    const [progressRecord, setProgressRecord] = useState(0.0)
    const { showToast } = useToastContext()
    const [localCron, setLocalCron] = useState(cron)

    useEffect(() => {
        setLocalCron(cron);  // Atualiza o estado local sempre que cron muda
    }, [cron]);

    const removeNote = async (noteId) => {
        try {
            await Cronservice.removeNoteFromCron(cron._id, noteId)
            const updatedNotes = localCron.notes.filter(note => note._id !== noteId);
            setLocalCron({ ...localCron, notes: updatedNotes });
            showToast({
                title: 'Nota removida',
                description: 'Nota removida com sucesso',
                status: 'success'
            })
        } catch (error) {
            showToast({
                title: 'Nota não removida',
                description: error.message,
                status: 'error'
            })
        }
    }

    useEffect(() => {
        let recorded = localCron.scenes.filter(scene => scene.recorded).length
        let totalScenes = localCron.scenes.length
        let progress = ((recorded / totalScenes) * 100)
        setProgressRecord(progress)
    }, [localCron])

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />

            <ModalContent sx={{ maxWidth: '800px', width: '60%' }} p={30}>
                <ModalCloseButton />
                <ModalHeader>
                    {cron.title} {isNear ? (
                        <Badge colorScheme='teal' p={1}> <TimeIcon /> Chegando</Badge>
                    ) : (
                        <></>
                    )}
                </ModalHeader>

                <ModalBody>
                    <Tabs isFitted variant='enclosed'>
                        <TabList>
                            <Tab>Informações</Tab>
                            <Tab>Equipe</Tab>
                            <Tab>Equipamentos</Tab>
                            <Tab>Locações</Tab>
                            <Tab>Roteiro</Tab>
                            <Tab>Shotlist</Tab>
                            <Tab>Notas</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Tbody>
                                            <Tr key='script_title'>
                                                <Td>
                                                    <Text as='b'>Titulo</Text>
                                                </Td>
                                                <Td>{cron.title}</Td>
                                            </Tr>

                                            <Tr key='timestart'>
                                                <Td>
                                                    <Text as='b'>Ínicio programado</Text>
                                                </Td>
                                                <Td><DateDisplay date={cron.timestart} /></Td>
                                            </Tr>

                                            <Tr key='timend'>
                                                <Td>
                                                    <Text as='b'>Previsão de Término</Text>
                                                </Td>
                                                <Td><DateDisplay date={cron.timend} /></Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>

                            <TabPanel>
                                {cron.team.length > 0 && (
                                    <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Nome</Th>
                                                    <Th>Função</Th>
                                                </Tr>
                                            </Thead>

                                            <Tbody>
                                                {cron.team.map((teamMember) => (
                                                    <Tr key={teamMember._id}>
                                                        <Td>{teamMember.name}</Td>
                                                        <Td>{teamMember.function}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </TabPanel>

                            <TabPanel>
                                {cron.equips.length > 0 && (
                                    <TableContainer>
                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Modelo</Th>
                                                    <Th>Tipo</Th>
                                                    <Th>Quantidade</Th>
                                                </Tr>
                                            </Thead>

                                            <Tbody>
                                                {cron.equips.map((equipamento) => (
                                                    <Tr key={equipamento.equip._id}>
                                                        <Td>{equipamento.equip.model}</Td>
                                                        <Td>{equipamento.equip.type}</Td>
                                                        <Td>{equipamento.inWork}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </TabPanel>

                            <TabPanel>

                                <Table>
                                    <Tbody>
                                        {cron.locations && cron.locations.map(location => (
                                            <Tr key={location._id}>
                                                <Td>
                                                    {location.photo !== "null" && <Image boxSize="150px" objectFit="cover" src={`http://localhost:3000${location.photo}`} alt={location.name} />}
                                                </Td>
                                                <Td> <Text>{location.name}</Text> </Td>
                                                <Td> <Text>{location.address.streetname}, {'Nº ' + location.address.streetnumber || 'S/N'}, {location.address.city} - {location.address.state}</Text> </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>

                            </TabPanel>

                            <TabPanel>
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Tbody>
                                            <Tr key='script_title'>
                                                <Td>
                                                    <Text as='b'>Titulo</Text>
                                                </Td>
                                                <Td>{cron.script.title}</Td>
                                            </Tr>

                                            <Tr key='script_description'>
                                                <Td>
                                                    <Text as='b'>Descrição</Text>
                                                </Td>
                                                <Td>{cron.script.description}</Td>
                                            </Tr>

                                            <Tr key='script_customer'>
                                                <Td>
                                                    <Text as='b'>Cliente</Text>
                                                </Td>
                                                <Td>{cron.script.customer?.fullname || 'Nenhum'}</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>

                            <TabPanel>
                                <Table size='sm'>
                                    <TableCaption>
                                        <HStack>
                                            <Icon color='teal' as={MdFiberManualRecord} />
                                            <Text>Gravado</Text>
                                            <Icon color='red' as={MdFiberManualRecord} />
                                            <Text>Gravar</Text>
                                        </HStack>
                                    </TableCaption>
                                    <Tbody>
                                        {cron.scenes && cron.scenes.map(scene => (
                                            <Tr key={scene._id}>
                                                <Td>
                                                    {scene.recorded ? (
                                                        <Icon color='teal' as={MdFiberManualRecord} />
                                                    ) : (
                                                        <Icon color='red' as={MdFiberManualRecord} />
                                                    )}
                                                </Td>
                                                <Td> {scene.image && scene.image !== 'null' && <Image src={`http://localhost:3000${scene.image}`} width='60px' />} </Td>
                                                <Td> <Text>{scene.description}</Text> </Td>
                                                <Td> <Text>{scene.content}</Text> </Td>
                                                <Td> <Text>{scene.voiceover}</Text> </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TabPanel>

                            <TabPanel>
                                <VStack align='start'>
                                    {localCron.notes && localCron.notes.map(note => (
                                        <CronNote note={note} key={note._id} onDelete={removeNote} />
                                    ))}
                                </VStack>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    <Box>
                        <Progress colorScheme='teal' value={progressRecord} />
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <HStack spacing={4}>
                        <Tooltip label='Em breve!'>
                            <IconButton icon={<FaPrint />} isDisabled />
                        </Tooltip>

                        <IconButton onClick={() => onEdit(cron)} icon={<EditIcon />} />
                        <PopoverUi
                            element={<IconButton icon={<DeleteIcon />} />}>
                            <Text>Tem certeza que deseja excluir este cronograma?</Text>
                            <Button onClick={() => onDelete(cron)}>Sim</Button>
                        </PopoverUi>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )

}

export default CronModal