import { useToast } from '@chakra-ui/react'
import React, { createContext, useContext} from 'react'


const ToastContext = createContext('toast')

export const ToastProvider = ({children}) => {
    const toast = useToast()

    const showToast = ({title, description, status, timer}) => {
        toast({
            title,
            description,
            status, // status pode ser "info", "warning", "success", "error"
            duration: timer || 5000,
            isClosable: true,
            position: 'bottom-right',
        })
    }

    return (
        <ToastContext.Provider value={{showToast}}>
            {children}
        </ToastContext.Provider>
    )
}

export const useToastContext = () => useContext(ToastContext);