import cronService from "../../services/backend/CronService";

const useGetLatestCrons = () => {
    const getLatestCrons = async () => {
        try {
            const response = await cronService.getLatestCrons()
            return response.length > 0 ? response : []
        } catch (error) {
            return {
                error: 'Erro ao obter os crons.'
            }
        }
    }

    return { getLatestCrons }
}

export default useGetLatestCrons