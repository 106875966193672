import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    VStack,
    Grid,
    GridItem,
    FormErrorMessage,
    HStack,
    IconButton,
    InputGroup,
    InputLeftAddon,
    Flex,
    Select,
    Text
} from '@chakra-ui/react';

import { formatDateForInput } from '../../ui/DateDisplay';

import { DeleteIcon } from '@chakra-ui/icons';

const BudgetForm = ({ onSubmit, budget }) => {
    // Validação com Yup
    const validationSchema = Yup.object({
        client: Yup.object().shape({
            name: Yup.string().required('Nome do cliente é obrigatório'),
            email: Yup.string().email('Digite um email válido'),
            phonenumber: Yup.string().matches(/^\d+$/, 'Somente números são permitidos'),
            type: Yup.string().oneOf(['CPF', 'CNPJ']).required('Tipo de documento'),
            document: Yup.number().required('Documento é obrigatório'),
        }).required('Cliente é obrigatório'),
        serviceType: Yup.string().required('Tipo de serviço é obrigatório'),
        serviceDescription: Yup.string().required('Descrição do serviço é obrigatória'),
        warrantyDate: Yup.date()
            .required('Data de entrega é obrigatória')
            .typeError('Data inválida'),
        paymentTerms: Yup.string().required('Termos de pagamento são obrigatórios'),
        paymentMethod: Yup.string().required('Método de pagamento é obrigatório'),
        expireDate: Yup.date().typeError('Data inválida').required('Data de expiração é obrigatória'),
        items: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Nome do item é obrigatório'),
                description: Yup.string().required('Descrição do item é obrigatória'),
                cost: Yup.number().required('Custo do item é obrigatório').min(0, 'O custo deve ser maior ou igual a zero'),
            })
        ).min(1, 'Pelo menos um item é obrigatório'),
    });

    // Inicialização do Formik
    const formik = useFormik({
        initialValues: {
            client: budget?.client || {
                name: '',
                email: '',
                phonenumber: '',
                type: 'CPF',
                document: '',
            },
            serviceType: budget ? budget.serviceType : '',
            serviceDescription: budget ? budget.serviceDescription : '',
            warrantyDate: budget ? formatDateForInput(new Date(budget.warrantyDate)) : '',
            paymentTerms: budget ? budget.paymentTerms : '',
            paymentMethod: budget ? budget.paymentMethod : '',
            expireDate: budget ? formatDateForInput(new Date(budget.expireDate)) : '',
            items: budget ? budget.items : [{
                name: '',
                description: '',
                cost: 0
            }],
        },
        validationSchema,
        onSubmit,
    });

    // Função para adicionar novos itens ao orçamento
    const addItem = () => {
        formik.setFieldValue('items', [
            ...formik.values.items,
            { name: '', description: '', cost: 0 },
        ]);
    };

    // Função para remover itens
    const removeItem = (index) => {
        const updatedItems = [...formik.values.items];
        updatedItems.splice(index, 1);
        formik.setFieldValue('items', updatedItems);
    };

    return (
        <Box p={6} bg="gray.50" borderRadius="md" boxShadow="sm">
            <form onSubmit={formik.handleSubmit}>
                <VStack spacing={4}>
                    <HStack width={"100%"} mb={4}>
                        <FormControl isInvalid={formik.touched.client?.name && !!formik.errors.client?.name}>
                            <FormLabel>Cliente</FormLabel>
                            <Input
                                name="client.name"
                                placeholder="Nome do Cliente"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.client.name}
                            />
                            <FormErrorMessage>{formik.errors.client?.name}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.client?.email && !!formik.errors.client?.email}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                name="client.email"
                                placeholder="Email do Cliente"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.client.email}
                            />
                            <FormErrorMessage>{formik.errors.client?.email}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.client?.phonenumber && !!formik.errors.client?.phonenumber}>
                            <FormLabel>Telefone</FormLabel>
                            <Input
                                name="client.phonenumber"
                                placeholder="Telefone do Cliente"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.client.phonenumber}
                            />
                            <FormErrorMessage>{formik.errors.client?.phonenumber}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.client?.type && !!formik.errors.client?.type} width={"20%"}>
                            <FormLabel>Doc.</FormLabel>
                            <Select
                                name="client.type"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.client.type}
                            >
                                <option value="CPF">CPF</option>
                                <option value="CNPJ">CNPJ</option>
                            </Select>
                            <FormErrorMessage>{formik.errors.client?.type}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.client?.document && !!formik.errors.client?.document}>
                            <FormLabel>Nº</FormLabel>
                            <Input
                                name="client.document"
                                placeholder=""
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.client.document}
                            />
                            <FormErrorMessage>{formik.errors.client?.document}</FormErrorMessage>
                        </FormControl>
                    </HStack>

                    <FormControl isInvalid={formik.touched.serviceType && !!formik.errors.serviceType}>
                        <FormLabel>Projeto</FormLabel>
                        <Input
                            name="serviceType"
                            placeholder="Ex.: Fotografia, Filmagem"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.serviceType}
                        />
                        <FormErrorMessage>{formik.errors.serviceType}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={formik.touched.serviceDescription && !!formik.errors.serviceDescription}>
                        <FormLabel>Descrição</FormLabel>
                        <Textarea
                            name="serviceDescription"
                            placeholder="Descrição detalhada do serviço"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.serviceDescription}
                        />
                        <FormErrorMessage>{formik.errors.serviceDescription}</FormErrorMessage>
                    </FormControl>

                    <HStack spacing={4} width="100%">

                        <FormControl isInvalid={formik.touched.expireDate && !!formik.errors.expireDate}>
                            <FormLabel>Validade</FormLabel>
                            <Input
                                type="date"
                                name="expireDate"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.expireDate}
                            />
                            <FormErrorMessage>{formik.errors.expireDate}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={formik.touched.warrantyDate && !!formik.errors.warrantyDate}>
                            <FormLabel>Garantia</FormLabel>
                            <Input
                                type="date"
                                name="warrantyDate"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.warrantyDate}
                            />
                            <FormErrorMessage>{formik.errors.warrantyDate}</FormErrorMessage>
                        </FormControl>

                    </HStack>

                    <FormControl isInvalid={formik.touched.paymentTerms && !!formik.errors.paymentTerms}>
                        <FormLabel>Termos de Pagamento</FormLabel>
                        <Input
                            name="paymentTerms"
                            placeholder="Ex.: Pagamento em 3 parcelas"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.paymentTerms}
                        />
                        <FormErrorMessage>{formik.errors.paymentTerms}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={formik.touched.paymentMethod && !!formik.errors.paymentMethod}>
                        <FormLabel>Método de Pagamento</FormLabel>
                        <Select
                            name="paymentMethod"
                            value={formik.values.paymentMethod}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} >
                            <option>Seleciona um método...</option>
                            <option key={'Cartão de Crédito'} value="Cartão de Crédito">Cartão de Crédito</option>
                            <option key={'Boleto'} value="Boleto">Boleto</option>
                            <option key={'Pix'} value="Pix">Pix</option>
                            <option key={'Dinheiro'} value="Dinheiro">Dinheiro</option>
                            <option key={'Transferência'} value="Transferência">Transferência</option>
                            <option key={'Outro'} value="Outro">Outros</option>
                        </Select>
                        <FormErrorMessage>{formik.errors.paymentMethod}</FormErrorMessage>
                    </FormControl>

                    <Grid templateColumns={'repeat(3, 1fr)'} gap={4} w="100%" justifyItems={'start'} bgColor={'gray.100'} p={4} borderRadius={'md'} boxShadow={'md'}>
                        {formik.values.items.map((item, index) => (
                            <>
                                <GridItem key={index + 1} w={'100%'}>
                                    <FormControl isInvalid={formik.touched.items?.[index]?.name && !!formik.errors.items?.[index]?.name}>
                                        <Input
                                            name={`items[${index}].name`}
                                            placeholder="Nome"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={item.name}
                                            bgColor={'white'}
                                        />
                                        <FormErrorMessage>{formik.errors.items?.[index]?.name}</FormErrorMessage>
                                    </FormControl>

                                </GridItem>

                                <GridItem key={index + 2} width="100%">


                                    <FormControl isInvalid={formik.touched.items?.[index]?.description && !!formik.errors.items?.[index]?.description}>
                                        <Input
                                            name={`items[${index}].description`}
                                            placeholder="Detalhes"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={item.description}
                                            bgColor={'white'}
                                        />
                                        <FormErrorMessage>{formik.errors.items?.[index]?.description}</FormErrorMessage>
                                    </FormControl>
                                </GridItem>

                                <GridItem key={index + 3} width="100%">
                                    <FormControl
                                        isInvalid={formik.touched.items?.[index]?.cost && !!formik.errors.items?.[index]?.cost}
                                    >
                                        <Flex alignItems="center" gap={2}>
                                            <InputGroup width="70%">
                                                <InputLeftAddon>R$</InputLeftAddon>
                                                <Input
                                                    name={`items[${index}].cost`}
                                                    type="number"
                                                    placeholder="Preço"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={item.cost}
                                                    bgColor="white"
                                                />
                                            </InputGroup>

                                            <IconButton
                                                aria-label="Remover item"
                                                onClick={() => removeItem(index)}
                                                icon={<DeleteIcon />}
                                                variant={'ghost'}
                                            />
                                        </Flex>

                                        <FormErrorMessage>
                                            {formik.errors.items?.[index]?.cost}
                                        </FormErrorMessage>
                                    </FormControl>
                                </GridItem>
                            </>
                        ))}
                        <Button onClick={addItem}>+</Button>
                    </Grid>

                    <HStack alignSelf={'flex-start'}>
                        <Button colorScheme="teal" type="submit">Salvar</Button>
                        <Button variant={'ghost'}>R$ {formik.values.items.reduce((total, item) => total + item.cost, 0)}</Button>
                    </HStack>
                </VStack>
            </form>
        </Box>
    );
};

export default BudgetForm;
