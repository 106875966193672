import React from "react";
import { Helmet } from "react-helmet";
import { Box, Heading } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import { SITE_NAME } from "../../misc/siteConfig";
import BudgetContainer from "../../components/dashboard/budget/budgetContainer";

export default function ViewBudget() {
    const { state } = useLocation();
    const budget = state.budget;

    return (
        <Box>
            <Helmet>
                <title>{SITE_NAME} - {budget.serviceType || 'Orcamento'}</title>
            </Helmet>
            
            <Heading p={4} borderWidth="1px" borderRadius="md" fontSize='md' boxShadow='md' bgColor='white' mb={4}>{budget.serviceType || 'Orcamento'}</Heading>

            <BudgetContainer budget={budget} />
        </Box>
    )
}