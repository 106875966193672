
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { Helmet } from "react-helmet";
import { Heading } from "@chakra-ui/react";

import { SITE_NAME } from "../../misc/siteConfig";
import { useToastContext } from "../../context/ToastContext";

import SubscriptionDetails from "../../components/dashboard/premium/subscriptionDetails";
import SubscriptionForm from "../../components/dashboard/premium/subscribeForm";

import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { getPlans } from "../../services/backend/PlanService";
import { getPayments } from "../../services/backend/PaymentService";

export default function Premium() {
    const { show } = useParams()

    const [pagination, setPagination] = useState({
        current_page: 1,
        total_pages: 1,
        limit: 10
    })

    const [plans, setPlans] = useState([])
    const [payments, setPayments] = useState([])
    const [activePlan, setActivePlan] = useState('')
    const [subscription, setSubscription] = useState({})

    const { showToast } = useToastContext()
    const user = useAuthUser()
    const navigate = useNavigate()

    const paginate = {
        next: () => setPagination({ ...pagination, current_page: pagination.current_page + 1 }),
        previous: () => setPagination({ ...pagination, current_page: pagination.current_page - 1 }),
        first: () => setPagination({ ...pagination, current_page: 1 }),
        last: () => setPagination({ ...pagination, current_page: pagination.total_pages }),
        state: pagination
    }

    useEffect(() => {
        async function fetchPlans() {
            try {
                let plans = await getPlans();
                setPlans(plans.docs)
            } catch (error) {
                showToast({
                    title: 'Sem planos para exibir',
                    description: error.message || 'Erro ao buscar planos',
                    status: 'error'
                })
            }
        }

        async function fetchPayments() {
            try {
                let response = await getPayments(
                    pagination.current_page,
                    pagination.limit,
                    user._id
                );

                if (pagination.current_page !== response.page || payments.length === 0) setPayments(response.docs)

                setPagination(prevSatete => ({
                    ...prevSatete,
                    current_page: response.page,
                    total_pages: response.totalPages
                }))
            } catch (error) {
                showToast({
                    title: 'Sem pagamentos para exibir',
                    description: error.message || 'Erro ao buscar pagamentos',
                    status: 'error'
                })
            }
        }

        if (!show) {
            if (user.subscription) {
                setActivePlan(user.subscription.planId._id)
                setSubscription(user.subscription)

                fetchPayments()
            }
        } else {
            setActivePlan('')
        }

        if (plans.length === 0) fetchPlans()
    }, [activePlan, showToast, plans.length, user, show, pagination.current_page, pagination.limit, payments])

    const handleSubmit = async (plan) => {
        navigate('/checkout', { state: { plan } })
    }

    return (
        <>
            <Helmet>
                <title>{SITE_NAME} &#8208; Premium</title>
            </Helmet>
            <Heading p={4} mb={10} borderWidth="1px" borderRadius="md" fontSize='md' boxShadow='md' bgColor='white'>Gerenciar assinaturas</Heading>

            {activePlan ? (
                <SubscriptionDetails subscription={subscription} payments={payments} pagination={paginate} />
            ) : (
                <SubscriptionForm onSubmit={handleSubmit} plans={plans} activePlan={activePlan} />
            )}
        </>
    )
}