// src/components/LocacaoForm.jsx
import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	FormControl,
	FormLabel,
	Input,
	Text,
	Select,
	Textarea,
	Box,
	HStack,
	FormErrorMessage
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

const LocacaoForm = ({ isOpen, onClose, onSubmit, locacao }) => {
	const [formData, setFormData] = useState({
		name: '',
		address: {
			streetname: '',
			streetnumber: '',
			hood: '',
			city: '',
			state: ''
		},
		rep: {
			name: '',
			phone: '',
			email: ''
		},
		photo: null,
		description: ''
	});
	const [formErrors, setFormErrors] = useState({})

	useEffect(() => {
		if (locacao) {
			setFormData({
				_id: locacao._id,
				name: locacao.name,
				address: locacao.address,
				rep: locacao.rep,
				photo: locacao.photo,
				description: locacao.description
			});
		} else {
			setFormData({
				name: '',
				address: {
					streetname: '',
					streetnumber: '',
					hood: '',
					city: '',
					state: ''
				},
				rep: {
					name: '',
					phone: '',
					email: ''
				},
				photo: null,
				description: ''
			});
		}
	}, [locacao]);

	const onDrop = (acceptedFiles) => {
		setFormData({
			...formData,
			photo: acceptedFiles[0]
		});
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			'image/*': ['.jpeg', '.jpg', '.png']
		}
	});

	const validateForm = () => {
		const errors = {};

		if (!formData.name) {
			errors.name = "O nome da locação é obrigatório.";
		}

		if (!formData.address.streetname) {
			errors.streetname = "O nome da rua é obrigatório.";
		}

		if (!formData.address.city) {
			errors.city = "A cidade é obrigatória.";
		}

		if (!formData.address.state) {
			errors.state = "O estado é obrigatório.";
		}

		if (!formData.rep.phone) {
			errors.phone = "O telefone do representante é obrigatório.";
		} else if (/^(\(?\d{2}\)?\s)?\d{4,5}-\d{4}$/.test(formData.rep.phone)) {
			errors.phone = "Número de telefone inválido. Formato esperado: +1234567890.";
		}

		if (formData.rep.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.rep.email)) {
			errors.email = "Email inválido.";
		}

		setFormErrors(errors);

		// Retorna verdadeiro se não houver erros
		return Object.keys(errors).length === 0;
	};


	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleAddressChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			address: {
				...formData.address,
				[name]: value
			}
		});
	};

	const handleRepChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			rep: {
				...formData.rep,
				[name]: value
			}
		});
	};

	const handleSubmit = () => {
		if (validateForm()) {
			onSubmit(formData);
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent sx={{ maxWidth: '800px', width: '60%' }} p={30}>
				<ModalHeader>{locacao ? (
					<Text>
						Editar &raquo; {locacao.name}
					</Text>
				) : 'Adicionar Locação'}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<FormControl mb={4}>
						<Box
							{...getRootProps()}
							p={4}
							border="2px dashed"
							borderColor="gray.300"
							textAlign="center"
							cursor="pointer"
						>
							<input {...getInputProps()} />
							{isDragActive ? (
								<p>Largue a imagem aqui ...</p>
							) : formData.photo ? (
								<img
									src={(typeof formData.photo === 'string' ? `http://localhost:3000${formData.photo}` : URL.createObjectURL(formData.photo))}
									alt="Imagem da Locação"
									height='300px'
								/>
							) : (
								<p>Arraste e solte a imagem aqui, ou clique para selecionar</p>
							)}
						</Box>
					</FormControl>

					<FormControl mb={4} isRequired isInvalid={formErrors.name}>
						<FormLabel>Nome</FormLabel>
						<Input
							name="name"
							value={formData.name}
							onChange={handleChange}
						/>
						<FormErrorMessage>{formErrors.name}</FormErrorMessage>
					</FormControl>

					<FormControl mb={4}>
						<FormLabel>Descrição</FormLabel>
						<Textarea
							name="description"
							value={formData.description}
							onChange={handleChange}
						/>
					</FormControl>

					<HStack>
						<Box borderWidth="1px" borderRadius="md" p={4} mt={4} mb={4} width='50%'>
							<Text as='b' mt={4} mb={6}>Endereço</Text>
							<HStack>
								<FormControl mb={4} width='70%' isRequired isInvalid={formErrors.streetname}>
									<FormLabel>Rua</FormLabel>
									<Input
										name="streetname"
										value={formData.address.streetname}
										onChange={handleAddressChange}
									/>
									<FormErrorMessage>{formErrors.streetname}</FormErrorMessage>
								</FormControl>

								<FormControl mb={4} width='30%'>
									<FormLabel>Nº</FormLabel>
									<Input
										name="streetnumber"
										value={formData.address.streetnumber}
										onChange={handleAddressChange}
									/>
								</FormControl>
							</HStack>

							<HStack>
								<FormControl mb={4} width='50%'>
									<FormLabel>Bairro</FormLabel>
									<Input
										name="hood"
										value={formData.address.hood}
										onChange={handleAddressChange}
									/>
								</FormControl>

								<FormControl mb={4} width='50%' isRequired isInvalid={formErrors.city}>
									<FormLabel>Cidade</FormLabel>
									<Input
										name="city"
										value={formData.address.city}
										onChange={handleAddressChange}
									/>
									<FormErrorMessage>{formErrors.city}</FormErrorMessage>
								</FormControl>
							</HStack>

							<FormControl mb={4} isRequired isInvalid={formErrors.state}>
								<FormLabel>Estado</FormLabel>
								<Select
									name="state"
									value={formData.address.state}
									onChange={handleAddressChange}
								>
									<option value="AC">AC</option>
									<option value="AL">AL</option>
									<option value="AP">AP</option>
									<option value="AM">AM</option>
									<option value="BA">BA</option>
									<option value="CE">CE</option>
									<option value="DF">DF</option>
									<option value="ES">ES</option>
									<option value="GO">GO</option>
									<option value="MA">MA</option>
									<option value="MT">MT</option>
									<option value="MS">MS</option>
									<option value="MG">MG</option>
									<option value="PA">PA</option>
									<option value="PR">PR</option>
									<option value="PB">PB</option>
									<option value="PE">PE</option>
									<option value="PI">PI</option>
									<option value="RJ">RJ</option>
									<option value="SP">SP</option>
									<option value="RN">RN</option>
									<option value="RS">RS</option>
									<option value="RO">RO</option>
									<option value="RR">RR</option>
									<option value="SC">SC</option>
									<option value="SE">SE</option>
									<option value="TO">TO</option>
								</Select>
								<FormErrorMessage>{formErrors.state}</FormErrorMessage>
							</FormControl>
						</Box>

						<Box borderWidth="1px" borderRadius="md" p={4} mt={4} mb={4} width='50%'>
							<Text as='b' mt={4} mb={6}>Representante</Text>
							<FormControl mb={4}>
								<FormLabel>Nome Completo</FormLabel>
								<Input
									name="name"
									value={formData.rep.name}
									onChange={handleRepChange}
								/>
							</FormControl>
							<FormControl mb={4} isRequired isInvalid={formErrors.phone}>
								<FormLabel>Telefone</FormLabel>
								<Input
									name="phone"
									value={formData.rep.phone}
									onChange={handleRepChange}
								/>
								<FormErrorMessage>{formErrors.phone}</FormErrorMessage>
							</FormControl>

							<FormControl mb={4} isInvalid={formErrors.email}>
								<FormLabel>Email</FormLabel>
								<Input
									name="email"
									value={formData.rep.email}
									onChange={handleRepChange}
								/>
								<FormErrorMessage>{formErrors.email}</FormErrorMessage>
							</FormControl>
						</Box>
					</HStack>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme="teal" mr={3} onClick={handleSubmit}>
						{locacao ? 'Salvar' : 'Adicionar'}
					</Button>
					<Button variant="ghost" onClick={onClose}>Cancelar</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default LocacaoForm;
