import { GridItem, Heading, Text, Box, VStack, Divider, List, ListItem } from "@chakra-ui/react";

import { Helmet } from "react-helmet";
import { SITE_NAME } from "../../../misc/siteConfig";
import Bread from "../../../components/ui/Bread";

export default function Termos() {
    return (
        <GridItem py={20} display={'flex'} w={'100%'}>
            <VStack justifyContent={'center'} flexDirection={'column'} alignItems={'start'} textAlign={'start'} maxW={'800px'} mx={'auto'}>
                <Helmet>
                    <title>{SITE_NAME} &#8208; Termos de Uso</title>
                </Helmet>
                
                <Bread options={[
                    { name: 'Suporte', link: '/suporte', current: false },
                    { name: 'Termos de Uso', link: '/suporte/termos', current: true }
                ]} 
                />

                <Heading size={'4xl'} bgGradient={'linear(to-r, teal.300, teal.600)'} bgClip={'text'}>CraftFrames</Heading>
                <Heading size={'lg'}>Termos de Uso</Heading>

                <Box mt={10}>
                    <Heading size={'md'}>1. Introdução</Heading>
                    <Text mt={4}>
                        Bem-vindo ao CraftFrames! Este Termo de Uso regula o acesso e a utilização da plataforma, 
                        que oferece ferramentas voltadas para planejamento e organização de videomakers. 
                        Ao utilizar nossa plataforma, você concorda com as condições aqui descritas.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>2. Cadastro e Contas</Heading>
                    <Heading size={'xs'} mt={5}>2.1. Cadastro de Usuários</Heading>
                    <Text mt={4}>
                        <List spacing={2} my={4}>
                            <ListItem>
                                <Text fontWeight={'bold'}>Qualquer pessoa pode criar uma conta, independentemente de ser profissional da área.</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>A assinatura de planos pagos está disponível apenas para usuários maiores de idade.</Text>
                            </ListItem>
                        </List>
                    </Text>

                    <Heading size={'xs'} mt={5}>2.2. Uso Indevido</Heading>
                    <Text mt={4}>
                        O uso indevido ou não autorizado da conta poderá resultar em:
                        <List spacing={2} my={4}>
                            <ListItem>
                                <Text fontWeight={'bold'}>Congelamento temporário do acesso;</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>Exclusão da conta, em caso de violações graves.</Text>
                            </ListItem>
                        </List>
                    </Text>

                    <Heading size={'xs'} mt={5}>2.3. Suspensão ou Exclusão</Heading>
                    <Text mt={4}>
                        Contas que violarem os Termos de Uso poderão ser suspensas ou excluídas pela plataforma, a critério exclusivo do CraftFrames.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>3. Planos de Assinatura</Heading>
                    <Heading size={'xs'} mt={5}>3.1. Condições Gerais</Heading>
                    <Text mt={4}>
                        <List spacing={2} my={4}>
                            <ListItem>Os planos de assinatura possuem ciclos mensais e podem ser configurados para renovação automática.</ListItem>
                            <ListItem>Os primeiros 30 dias são gratuitos para novos usuários, como um período de teste.</ListItem>
                        </List>
                    </Text>

                    <Heading size={'xs'} mt={5}>3.2. Cancelamento e Reembolso</Heading>
                    <Text mt={4}>
                        <List spacing={2} my={4}>
                            <ListItem>O usuário pode cancelar ou solicitar reembolso em até 7 dias após o início da assinatura.</ListItem>
                            <ListItem>Após este prazo, solicitações de reembolso serão avaliadas individualmente.</ListItem>
                        </List>
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>4. Uso da Plataforma</Heading>
                    <Heading size={'xs'} mt={5}>4.1. Restrições de Uso</Heading>
                    <Text mt={4}>
                        É proibido utilizar a plataforma para:
                        <List spacing={2} my={4}>
                            <ListItem>Práticas ilegais ou prejudiciais a terceiros;</ListItem>
                            <ListItem>Atividades que possam comprometer a integridade ou funcionamento da plataforma.</ListItem>
                        </List>
                    </Text>

                    <Heading size={'xs'} mt={5}>4.2. Compartilhamento de Contas</Heading>
                    <Text mt={4}>
                        O compartilhamento de contas ou credenciais de acesso é estritamente proibido.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>5. Direitos e Obrigações</Heading>
                    <Heading size={'xs'} mt={5}>5.1. Responsabilidades dos Usuários</Heading>
                    <Text mt={4}>
                        <List spacing={2} my={4}>
                            <ListItem>Fornecer informações corretas e completas durante o cadastro.</ListItem>
                            <ListItem>Utilizar a plataforma de forma ética e respeitosa.</ListItem>
                        </List>
                    </Text>

                    <Heading size={'xs'} mt={5}>5.2. Compromissos do CraftFrames</Heading>
                    <Text mt={4}>
                        <List spacing={2} my={4}>
                            <ListItem>Garantir o funcionamento estável da plataforma, exceto em casos de manutenção ou eventos fora de controle.</ListItem>
                            <ListItem>Oferecer suporte técnico para dúvidas ou problemas relacionados ao uso da plataforma.</ListItem>
                        </List>
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>6. Conteúdo</Heading>
                    <Heading size={'xs'} mt={5}>6.1. Propriedade dos Dados</Heading>
                    <Text mt={4}>
                        Todo conteúdo gerado pelos usuários, como roteiros e informações de clientes, pertence exclusivamente a eles.
                    </Text>

                    <Heading size={'xs'} mt={5}>6.2. Remoção de Conteúdo</Heading>
                    <Text mt={4}>
                        O CraftFrames se reserva o direito de remover qualquer conteúdo que viole estes Termos de Uso ou comprometa a segurança da plataforma.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>7. Limitação de Responsabilidade</Heading>
                    <Heading size={'xs'} mt={5}>7.1. Garantias</Heading>
                    <Text mt={4}>
                        O CraftFrames não garante resultados específicos, uma vez que é apenas uma ferramenta para auxiliar os usuários.
                    </Text>

                    <Heading size={'xs'} mt={5}>7.2. Exclusão de Responsabilidade</Heading>
                    <Text mt={4}>
                    A plataforma não se responsabiliza por danos decorrentes do uso inadequado ou indevido por parte dos usuários.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>8. Rescisão</Heading>
                    <Text mt={4}>
                    O contrato pode ser rescindido em comum acordo entre as partes, ao término de uma assinatura ou em conformidade com as condições estabelecidas neste termo.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>9. Lei Aplicável</Heading>
                    <Text mt={4}>
                    Este Termo de Uso é regido exclusivamente pelas leis brasileiras, em conformidade com a LGPD e o Código Civil.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>10. Alterações nos Termos de Uso</Heading>
                    <Text mt={4}>
                    Quaisquer alterações neste Termo de Uso serão comunicadas aos usuários por e-mail.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>9. Contato</Heading>
                    <Text mt={4}>
                        Para dúvidas ou solicitações relacionadas à sua privacidade, entre em contato conosco: [contato@craftframes.com.br]
                    </Text>
                </Box>
                
            </VStack>
        </GridItem>
    )
}