import API from '../backend/axiosConfig'
import ApiException from '../backend/ApiException'

const getCustomersAll = async ({ page = 1, limit = 10, searchQuery }) => {
    try {
        const response = await API.get('/customers', {
            params: {
                page,
                limit,
                search: searchQuery
            }
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

// @params {String} id
// @return {Object}
const getCustomerById = async (_id = '') => {
    try {
        const response = await API.get(`/customers/${_id}`)
        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const updateCustomerById = async ({
    _id = '',
    type = '',
    fullname = '',
    phonenumber = '',
    email = ''
}) => {
    try {

        const response = await API.put(`/customers/${_id}`, {
            type,
            fullname,
            phonenumber,
            email
        })

        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const deleteCustomerById = async (id = '') => {
    try {
        const response = await API.delete(`/customers/${id}`)
        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const createCustomer = async ({
    type = '',
    fullname = '',
    phonenumber = '',
    email = ''
}) => {
    try {

        const response = await API.post(`/customers`, {
            type,
            fullname,
            phonenumber,
            email
        })

        if (response.data.error) throw new ApiException(response.data.error)
        return response.data

    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

export {
    getCustomersAll,
    getCustomerById,
    deleteCustomerById,
    updateCustomerById,
    createCustomer
}