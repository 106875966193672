import API from '../backend/axiosConfig'
import ApiException from '../backend/ApiException'

const getPlans = async (page = 1, limit = 10) => {
    try {
        const response = await API.get('/plans/list', {
            params: {
                page,
                limit
            }
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const createPlan = async (data) => {
    try {
        const response = await API.post('/plans/create', data)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

const updatePlan = async (data) => {
    try {
        const response = await API.put('/plans/update', data)
        return response.data
    } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            // Lança a mensagem de erro específica da API
            throw new ApiException(error.response.data.error)
        } else {
            // Lança a mensagem de erro padrão
            throw new ApiException(error.message)
        }
    }
}

export { getPlans, createPlan, updatePlan }