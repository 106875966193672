import { GridItem, Heading, Text, Box, VStack, Divider, List, ListItem } from "@chakra-ui/react";

import { Helmet } from "react-helmet";
import { SITE_NAME } from "../../../misc/siteConfig";
import Bread from "../../../components/ui/Bread";

export default function Privacidade() {
    return (
        <GridItem py={20} display={'flex'} w={'100%'}>
            <VStack justifyContent={'center'} flexDirection={'column'} alignItems={'start'} textAlign={'start'} maxW={'800px'} mx={'auto'}>
                <Helmet>
                    <title>{SITE_NAME} &#8208; Política de Privacidade</title>
                </Helmet>

                <Bread options={[
                    { name: 'Suporte', link: '/suporte', current: false },
                    { name: 'Política de Privacidade', link: '/suporte/privacidade', current: true }
                ]}
                />
                
                <Heading size={'4xl'} bgGradient={'linear(to-r, teal.300, teal.600)'} bgClip={'text'}>CraftFrames</Heading>
                <Heading size={'lg'}>Política de Privacidade</Heading>

                <Box mt={10}>
                    <Heading size={'md'}>1. Introdução</Heading>
                    <Text mt={4}>Bem-vindo à plataforma CraftFrames! Valorizamos sua privacidade e estamos
                        comprometidos em proteger as informações que você compartilha conosco. Esta política
                        de privacidade explica como coletamos, usamos, armazenamos e protegemos seus dados,
                        garantindo transparência e conformidade com a legislação aplicável, como a Lei Geral
                        de Proteção de Dados (LGPD).
                    </Text>

                    <Divider mt={5} />
                </Box>


                <Box mt={10}>
                    <Heading size={'md'}>2. Dados que Coletamos</Heading>
                    <Heading size={'xs'} mt={5}>2.1 Informações fornecidas pelos usuários</Heading>
                    <Text mt={4}>
                        Ao utilizar nossa plataforma, podemos solicitar que você forneça os seguintes dados:
                        <Text fontWeight={'bold'}>Nome completo; Endereço de e-mail; CPF; Informações de pagamento (coletadas e processadas de forma segura pelos provedores de pagamento parceiros).</Text>
                        <br />Além disso, nossa plataforma permite que os usuários cadastrem informações de seus próprios clientes. Esses dados são armazenados exclusivamente
                        para viabilizar o uso das ferramentas oferecidas pela plataforma e não são utilizados para outros fins.
                    </Text>

                    <Heading size={'xs'} mt={5}>2.2 Informações coletadas automaticamente</Heading>
                    <Text mt={4}>
                        Utilizamos cookies para autenticação de usuários e melhorar sua experiência. Também coletamos dados de
                        tráfego por meio de serviços de análise, como endereço IP e informações sobre o navegador e dispositivo utilizado.
                    </Text>

                    <Heading size={'xs'} mt={5}>2.3 Informações de formulários</Heading>
                    <Text mt={4}>
                        Dados inseridos em formulários de contato e cadastro também são coletados para fins de suporte e personalização dos serviços.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>3. Uso dos Dados</Heading>
                    <Text mt={4}>
                        Utilizamos as informações coletadas para:
                        <List spacing={2} my={4}>
                            <ListItem>
                                <Text fontWeight={'bold'}>Fornecer e gerenciar os serviços da plataforma;</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>Melhorar e atualizar a experiência do usuário;</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>Enviar notificações relacionadas à sua conta, como atualizações ou informações sobre sua assinatura;</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>Processar pagamentos com segurança.;</Text>
                            </ListItem>
                        </List>
                        Atualmente, não utilizamos os dados para fins de marketing ou envio de newsletters.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>4. Compartilhamento de Dados</Heading>
                    <Text mt={4}>
                        Seus dados não são compartilhados com terceiros, exceto:
                        <List spacing={2} my={4}>
                            <ListItem>
                                <Text fontWeight={'bold'}>Provedores de pagamento: para processar transações de forma segura;</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>Serviços de análise de tráfego: para monitorar e melhorar o desempenho da plataforma (ex.: Google Analytics).</Text>
                            </ListItem>
                        </List>
                        Essas informações são compartilhadas exclusivamente para garantir a operação do site, seguindo rigorosos padrões de segurança.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>5. Armazenamento e Segurança</Heading>
                    <Text mt={4}>
                    Seus dados são armazenados em nossos servidores próprios, utilizando medidas de segurança robustas, incluindo:
                        <List spacing={2} my={4}>
                            <ListItem>
                                <Text fontWeight={'bold'}>Criptografia durante o transporte (SSL/TLS) e no armazenamento;</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>Backups regulares para proteger contra perdas de dados.</Text>
                            </ListItem>
                        </List>
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>6. Seus Direitos</Heading>
                    <Text mt={4}>
                    Você tem o direito de:
                        <List spacing={2} my={4}>
                            <ListItem>
                                <Text fontWeight={'bold'}>Solicitar acesso aos dados pessoais que coletamos;</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>Corrigir ou excluir suas informações pessoais;</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>Optar por não receber notificações não essenciais.</Text>
                            </ListItem>
                        </List>

                        Para exercer seus direitos, entre em contato conosco pelo e-mail [contato@craftframes.com.br].
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>7. Cookies e Tecnologias Semelhantes</Heading>
                    <Text mt={4}>
                    Utilizamos cookies para:
                        <List spacing={2} my={4}>
                            <ListItem>
                                <Text fontWeight={'bold'}>Autenticação: garantir o login seguro dos usuários;</Text>
                            </ListItem>

                            <ListItem>
                                <Text fontWeight={'bold'}>Análise: entender como a plataforma é utilizada, por meio de serviços como o Google Analytics.</Text>
                            </ListItem>
                        </List>

                        Você pode gerenciar ou desativar os cookies diretamente nas configurações do seu navegador.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>8. Alterações na Política de Privacidade</Heading>
                    <Text mt={4}>
                        Esta política pode ser atualizada periodicamente para refletir mudanças na plataforma ou nas leis aplicáveis.
                        Notificaremos os usuários sobre alterações significativas por meio da plataforma ou outros canais apropriados.
                    </Text>

                    <Divider mt={5} />
                </Box>

                <Box mt={10}>
                    <Heading size={'md'}>9. Contato</Heading>
                    <Text mt={4}>
                        Para dúvidas ou solicitações relacionadas à sua privacidade, entre em contato conosco: [contato@craftframes.com.br]
                    </Text>
                </Box>
            </VStack>
        </GridItem>
    )
}