import React from "react";
import { Helmet } from "react-helmet";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import { SITE_NAME } from "../../misc/siteConfig";
import BudgetForm from "../../components/dashboard/budget/budgetForm";

import {useToastContext} from "../../context/ToastContext";
import budgetService from "../../services/backend/BudgetService";

export default function EditBudget() {
    const {showToast} = useToastContext()
    const location = useLocation();
    const budget = location.state.budget;

    const handleSubmit = async (values) => {
        try {
            const response = await budgetService.updateBudget(budget._id, values)

            showToast({
                title: 'Edição salva',
                status: 'success'
            })
        } catch (error) {
            showToast({
                title: 'Edição falhou',
                description: error.error,
                status: 'error'
            })
        }
    }

    return (
        <Box>
            <Helmet>
                <title>{SITE_NAME} - Editar Orçamento</title>
            </Helmet>
            
            <BudgetForm onSubmit={handleSubmit} budget={budget}/>
        </Box>
    );
}