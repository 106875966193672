// import API from '../backend/axiosConfig'
// import ApiException from '../backend/ApiException'

// const getScriptsAll = async ({page = 1, limit = 10, searchQuery, startDate, endDate}) => {
//     try {
//         const response = await API.get('/scripts', {
//             params: {
//                 page,
//                 limit,
//                 search: searchQuery,
//                 startDate,
//                 endDate
//             }
//         })
        
//         return response.data
//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const getScriptById = async (_id = '') => {

//     if(!_id && typeof _id !== 'string') throw new ApiException('Nenhum ID encontrado')

//     try {
//         const response = await API.get(`/scripts/${_id}`)
        
//         return response.data
//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const updateScriptById = async ({
//     _id,
//     title,
//     description = '',
//     shared = false,
//     customer = null,
// }) => {
//     try {

//         const response = await API.put(`/scripts/${_id}`, {
//             title,
//             description,
//             shared,
//             customer,
//         })

//         if (response.data.error) throw new ApiException(response.data.error)

//         return response.data

//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const deleteScriptById = async (_id = '') => {
//     try {
//         const response = await API.delete(`/scripts/${_id}`)

//         return response.data
//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// const createScript = async ({
//     title,
//     description = '',
//     shared = false,
//     customer,
// }) => {
//     try {

//         const response = await API.post(`/scripts`, {
//             title,
//             description,
//             shared,
//             customer
//         })

//         return response.data

//     } catch (error) {
//         if (error.response && error.response.data && error.response.data.error) {
//             // Lança a mensagem de erro específica da API
//             throw new ApiException(error.response.data.error)
//         } else {
//             // Lança a mensagem de erro padrão
//             throw new ApiException(error.message)
//         }
//     }
// }

// export {
//     getScriptsAll,
//     getScriptById,
//     deleteScriptById,
//     updateScriptById,
//     createScript
// }

import API from "../backend/axiosConfig";
import ApiException from "../backend/ApiException";

// Serviço de Roteiros
const scriptService = {
  /**
   * Obtém a lista de roteiros com paginação
   * @param {Object} query - Parâmetros de consulta (página, limite, etc.)
   * @returns {Promise}
   */
  getScriptsAll: (query = { page: 1, limit: 10, searchQuery: "", startDate: null, endDate: null }) => {
    return API.get("/scripts", { params: query })
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao obter os roteiros.");
      });
  },

  /**
   * Obtém os detalhes de um roteiro pelo ID
   * @param {string} id - ID do roteiro
   * @returns {Promise}
   */
  getScriptById: (id) => {
    if (!id || typeof id !== "string") {
      throw new ApiException("ID inválido ou não fornecido.");
    }

    return API.get(`/scripts/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao obter o roteiro.");
      });
  },

  /**
   * Atualiza um roteiro existente
   * @param {string} id - ID do roteiro
   * @param {Object} data - Dados atualizados do roteiro
   * @returns {Promise}
   */
  updateScriptById: (id, data) => {
    return API.put(`/scripts/${id}`, data)
      .then((response) => {
        if (response.data.error) {
          throw new ApiException(response.data.error);
        }
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao atualizar o roteiro.");
      });
  },

  /**
   * Remove um roteiro pelo ID
   * @param {string} id - ID do roteiro
   * @returns {Promise}
   */
  deleteScriptById: (id) => {
    return API.delete(`/scripts/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao excluir o roteiro.");
      });
  },

  /**
   * Cria um novo roteiro
   * @param {Object} data - Dados do roteiro
   * @returns {Promise}
   */
  createScript: (data) => {
    return API.post("/scripts", data)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response?.data?.error || new ApiException("Erro ao criar o roteiro.");
      });
  },
};

export default scriptService;
