import React from "react";
import { Outlet } from "react-router-dom";
import { Grid, Spacer } from "@chakra-ui/react";

import Footer from "../../components/site/Footer";
import Header from "../../components/site/Header";

const SiteLayout = () => {
    return (
        <Grid
            templateRows={"auto 1fr auto"} // Header, conteúdo expansível, Footer
            templateColumns={"1fr"}
            minHeight="100vh"
        >
            <Header bgColor={"gray.50"} py={4} />
            <Outlet />
            <Footer />
        </Grid>
    );
};

export default SiteLayout;