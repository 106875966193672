// src/components/LocacaoModal.jsx
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Table,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { API } from '../../../misc/siteConfig';

const LocacaoModal = ({ isOpen, onClose, locacao }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        {locacao?.photo !== 'null' && (
            <Image src={`${API}${locacao.photo}`} alt={locacao.name} />
        )}
        
        <ModalHeader>{locacao.name}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Th>Nome</Th>
                <Td>{locacao.name}</Td>
              </Tr>
              <Tr>
                <Th>Descrição</Th>
                <Td>{locacao?.description}</Td>
              </Tr>
              <Tr>
                <Th>Endereço</Th>
                <Td>
                  {locacao?.address?.streetname}, {'Nº '+locacao.address?.streetnumber || 'S/N'}, {locacao.address?.hood}, {locacao.address?.city} - {locacao.address?.state}
                </Td>
              </Tr>
              <Tr>
                <Th>Representante</Th>
                <Td>{locacao.rep?.name}</Td>
              </Tr>
              <Tr>
                <Th>Telefone</Th>
                <Td>{locacao.rep?.phone}</Td>
              </Tr>
              <Tr>
                <Th>Email</Th>
                <Td>{locacao.rep?.email}</Td>
              </Tr>
            </Tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LocacaoModal;
