// src/components/ListLocacao.jsx
import { useState, useEffect, useCallback } from 'react';
import { Box, Input, Button, useDisclosure, Text, HStack, SimpleGrid } from '@chakra-ui/react';
import LocacaoCard from './LocacaoCard';
import LocacaoForm from './LocacaoForm';
import LocacaoModal from './LocacaoModal';
import { AddIcon } from '@chakra-ui/icons';


import { useToastContext } from '../../../context/ToastContext';
import { createLocation, deleteLocationById, getLocationsAll, updateLocationById } from '../../../services/backend/LocationsService';


const ListLocacao = () => {
	const [locacoes, setLocacoes] = useState([]);
	const [selectedLocacao, setSelectedLocacao] = useState(null);
	const [totalPages, setTotalPages] = useState(0)
	const [searchTerm, setSearchTerm] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isFormOpen, onOpen: onFormOpen, onClose: onFormClose } = useDisclosure();
	const [currentPage, setCurrentPage] = useState(1);
	const { showToast } = useToastContext();

	// const getNestedValue = (obj, path) => {
	// 	return path.split('.').reduce((acc, part) => acc && acc[part], obj);
	// };

	const fetchLocations = useCallback(async (bypass = false) => {

		try {
			var response = await getLocationsAll({
				page: currentPage,
				searchQuery: searchTerm
			})

			if (bypass) { setLocacoes(response.docs) }
			else {
				if (locacoes.length !== response.docs.length) setLocacoes(response.docs)
			}

			setTotalPages(response.totalPages)
			setCurrentPage(response.page)
		} catch (error) {
			showToast({
				title: 'Erro de carregamento',
				description: error.message,
				status: 'error'
			})
		}

	}, [currentPage, locacoes, showToast, searchTerm])


	useEffect(() => {

		fetchLocations()

	}, [fetchLocations])

	const handleAdd = async (newLocacao) => {

		try {

			var response = await createLocation({ ...newLocacao })
			showToast({
				title: newLocacao.title,
				description: response.message,
				status: 'success'
			})
			fetchLocations()

		} catch (error) {
			showToast({
				title: newLocacao.title || 'Error ao cadastrar locação',
				description: error.message,
				status: 'error'
			})
		}

	};

	const handleEdit = async (editedLocacao) => {
		try {
			var response = await updateLocationById(editedLocacao)
			showToast({
				title: editedLocacao.title,
				description: response.message,
				status: 'success'
			})
			fetchLocations(true)
		} catch (error) {
			showToast({
				title: editedLocacao.title || 'Error ao editar locação',
				description: error.message,
				status: 'error'
			})
		}
	};

	const handleDelete = async (deletedLocacao) => {
		try {
			var response = await deleteLocationById(deletedLocacao._id)
			showToast({
				title: deletedLocacao.title,
				description: response.message,
				status: 'success'
			})
			fetchLocations()
		} catch (error) {
			showToast({
				title: deletedLocacao.title,
				description: error.message,
				status: 'error'
			})
		}
	};

	const handleView = (locacao) => {
		setSelectedLocacao(locacao);
		onOpen();
	};

	const handleFormOpen = (locacao = null) => {
		setSelectedLocacao(locacao);
		onFormOpen();
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	return (
		<Box>

			<HStack mb={4} display="flex" p={4} mt={4} borderWidth="1px" borderRadius="md" alignItems="center" boxShadow='md' bgColor='white'>
				<Button colorScheme="teal" onClick={() => handleFormOpen()} leftIcon={<AddIcon />}>Adicionar</Button>
				<HStack>
					<Input placeholder="Pesquisar" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
				</HStack>
			</HStack>

			<SimpleGrid templateColumns="repeat(auto-fit, 400px)" gap={5} mt={10}>
				{locacoes.map(locacao => (
					<LocacaoCard
						key={locacao._id}
						sx={{ borderWidth: "2px", borderRadius: "lg", borderColor: 'white' }}
						_hover={{ borderColor: 'teal' }}
						locacao={locacao}
						onView={handleView}
						onEdit={() => handleFormOpen(locacao)}
						onDelete={() => handleDelete(locacao)}
					/>
				))}
			</SimpleGrid>

			{totalPages > 1 && (
				<Box mt={4} display="flex" alignItems="center">
					<Button
						onClick={() => handlePageChange(currentPage - 1)}
						isDisabled={currentPage === 1}
					>
						Anterior
					</Button>
					<Text mx={2}>{currentPage} / {totalPages}</Text>
					<Button
						onClick={() => handlePageChange(currentPage + 1)}
						isDisabled={currentPage === totalPages}
					>
						Próximo
					</Button>
				</Box>
			)}

			{selectedLocacao && (
				<LocacaoModal
					isOpen={isOpen}
					onClose={onClose}
					locacao={selectedLocacao}
				/>
			)}
			{isFormOpen && (
				<LocacaoForm
					isOpen={isFormOpen}
					onClose={onFormClose}
					locacao={selectedLocacao}
					onSubmit={selectedLocacao ? handleEdit : handleAdd}
				/>
			)}
		</Box>
	);
};

export default ListLocacao;
