import { Alert, AlertIcon, AlertDescription, AlertTitle, Button, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useState } from "react";

export default function Announcement({ announcement, ...rest }) {
    const [show, setShow] = useState(announcement.status);

    const handleClose = () => {
        setShow(false);
    }

    return (
        <>
            {show && (
                <Alert status={announcement.type} {...rest}>
                    <AlertIcon />
                    <AlertTitle>{announcement.title}</AlertTitle>
                    <AlertDescription mx={2}>
                        {announcement.message}
                    </AlertDescription>

                    {announcement.button.label && announcement.button.link && (
                        <Button as={'a'} href={announcement.button.link} target={'_blank'} colorScheme={'teal'} size={'sm'}>{announcement.button.label}</Button>
                    )}

                    {announcement.dimissable && (
                        <IconButton variant={'ghost'} icon={<CloseIcon />} onClick={handleClose} mx={4} />
                    )}
                </Alert>
            )}
        </>
    )
}