import React from 'react';
import { Box, Heading, Image, Text, Table, Tbody, Tr, Td, Thead, Th } from '@chakra-ui/react';

const CronLocations = ({ locations }) => {
  return (
    <Box p={10} boxShadow='md' bgColor='white'>
      <Heading size="md">Locações</Heading>
      <Table >
          <Thead>
            <Tr>
              <Th>Referência</Th>
              <Th>Endereço</Th>
              <Th>Responsavel</Th>
              <Th>Telefone</Th>
            </Tr>
          </Thead>
          <Tbody>
              {locations.map( location => (
                  <Tr key={location._id}>
                      <Td> <Image boxSize="150px" objectFit="cover" src={`http://localhost:3000${location.photo}`} alt={location.name} /> </Td>
                      <Td> <Text>{`${location.address.streetname}, ${location.address.streetnumber}, ${location.address.hood}, ${location.address.city} - ${location.address.state}`}</Text> </Td>
                      <Td> <Text>{location.rep.name}</Text> </Td>
                      <Td> <Text>{location.rep.phone}</Text> </Td>
                  </Tr>
              ))}
          </Tbody>
      </Table>
    </Box>
  );
};

export default CronLocations;
