import ListLocacao from '../components/dashboard/locacao/ListLocacao';
import { Box, Heading } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { SITE_NAME } from '../misc/siteConfig';
import ToolNotIncluded from '../components/dashboard/plan/toolNotIncluded';

import {checkPlanTools} from "../auth/userHelpers";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

function Locacao() {
    const auth = useAuthUser();

    return (
        checkPlanTools(auth, 'Locacoes') ? (
            <Box>
                <Helmet>
                    <title>{SITE_NAME} &#8208; Locações</title>
                </Helmet>
                <Heading p={4} borderWidth="1px" borderRadius="md" fontSize='md' boxShadow='md' bgColor='white'>Locações</Heading>
                <ListLocacao />
            </Box>
        ) : <ToolNotIncluded />
    )
}

export default Locacao;