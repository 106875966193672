// import React, { useState, useEffect } from 'react';
// import {
// 	Box,
// 	Button,
// 	Input,
// 	FormControl,
// 	FormLabel,
// 	Select,
// 	NumberInput,
// 	NumberInputField
// } from '@chakra-ui/react';

// const CustomerForm = ({ customer, onSubmit }) => {
// 	const [formData, setFormData] = useState({
// 		_id: '',
// 		fullname: '',
// 		email: '',
// 		phonenumber: '',
// 		type: ''
// 	});

// 	const [formError, setFormError] = useState({});

// 	useEffect(() => {
// 		if (customer) {
// 			setFormData(customer);
// 		}
// 	}, [customer]);

// 	const validateForm = () => {
// 		var errors = {};
// 		if (!formData.fullname) errors.fullname = 'Preencha o nome completo';
// 		if (!formData.type) errors.type = 'Selecione o tipo de cliente';
// 		setFormError(errors);
// 		return Object.keys(errors).length === 0;
// 	};

// 	const handleChange = (e) => {
// 		const { name, value } = e.target;
// 		setFormData((prev) => ({ ...prev, [name]: value }));
// 		setFormError((prev) => ({ ...prev, [name]: '' }));
// 	};

// 	const handleSubmit = (e) => {
// 		e.preventDefault();
// 		if(validateForm()) {
// 			onSubmit(formData);
// 		}
// 	};

// 	return (
// 		<Box as="form" onSubmit={handleSubmit}>
// 			<FormControl id="fullname" mb={4} isInvalid={formError.fullname} isRequired>
// 				<FormLabel>Nome Completo</FormLabel>
// 				<Input name="fullname" value={formData.fullname} onChange={handleChange}  />
// 			</FormControl>
// 			<FormControl id="email" mb={4}>
// 				<FormLabel>Email</FormLabel>
// 				<Input name="email" value={formData.email} onChange={handleChange}  />
// 			</FormControl>
// 			<FormControl id="phonenumber" mb={4}>
// 				<FormLabel>Telefone</FormLabel>
// 				<NumberInput value={formData.phonenumber}>
// 					<NumberInputField name="phonenumber" onChange={handleChange}  />
// 				</NumberInput>
// 			</FormControl>
// 			<FormControl id="type" mb={4} isInvalid={formError.type} isRequired>
// 				<FormLabel>Tipo</FormLabel>
// 				<Select name="type" value={formData.type} onChange={handleChange} >
// 					<option>Seleciona um tipo...</option>
// 					<option value="CNPJ">Pessoa Jurídica</option>
// 					<option value="CPF">Pessoa Física</option>
// 				</Select>
// 			</FormControl>
// 			<Button type="submit" colorScheme="teal">Salvar</Button>
// 		</Box>
// 	);
// };

// export default CustomerForm;
import React from 'react';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  NumberInput,
  NumberInputField,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const CustomerForm = ({ customer, onSubmit }) => {
  // Validação com Yup
  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required('Preencha o nome completo'),
    email: Yup.string().email('Digite um email válido').nullable(),
    phonenumber: Yup.string()
      .matches(/^\d+$/, 'Somente números são permitidos')
      .nullable(),
    type: Yup.string().required('Selecione o tipo de cliente'),
  });

  return (
    <Formik
      initialValues={{
        _id: customer?._id || '',
        fullname: customer?.fullname || '',
        email: customer?.email || '',
        phonenumber: customer?.phonenumber || '',
        type: customer?.type || '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <Box p={4}>
            {/* Nome Completo */}
            <FormControl id="fullname" mb={4} isInvalid={touched.fullname && errors.fullname} isRequired>
              <FormLabel>Nome Completo</FormLabel>
              <Field as={Input} name="fullname" placeholder="Digite o nome completo" />
              <FormErrorMessage>{errors.fullname}</FormErrorMessage>
            </FormControl>

            {/* Email */}
            <FormControl id="email" mb={4} isInvalid={touched.email && errors.email}>
              <FormLabel>Email</FormLabel>
              <Field as={Input} name="email" placeholder="Digite o email" />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            {/* Telefone */}
            <FormControl id="phonenumber" mb={4} isInvalid={touched.phonenumber && errors.phonenumber}>
              <FormLabel>Telefone</FormLabel>
              <NumberInput
                value={values.phonenumber}
                onChange={(value) => setFieldValue('phonenumber', value)}
              >
                <NumberInputField name="phonenumber" placeholder="Digite o telefone" />
              </NumberInput>
              <FormErrorMessage>{errors.phonenumber}</FormErrorMessage>
            </FormControl>

            {/* Tipo */}
            <FormControl id="type" mb={4} isInvalid={touched.type && errors.type} isRequired>
              <FormLabel>Tipo</FormLabel>
              <Field as={Select} name="type" placeholder="Selecione um tipo">
                <option value="CNPJ">Pessoa Jurídica</option>
                <option value="CPF">Pessoa Física</option>
              </Field>
              <FormErrorMessage>{errors.type}</FormErrorMessage>
            </FormControl>

            {/* Botão Salvar */}
            <Button type="submit" colorScheme="teal" mt={4}>
              Salvar
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerForm;
