import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, ModalHeader, useDisclosure } from "@chakra-ui/react";

import ListPlans from "../../components/dashboard/admin/plans/listPlans";
import CustomHeading from "../../components/ui/Heading";
import PlansForm from "../../components/dashboard/admin/plans/plansForm";

import { useToastContext } from "../../context/ToastContext";
import { getPlans, createPlan } from "../../services/backend/PlanService";

function Planos() {
    const [plans, setPlans] = useState([])
    const { showToast } = useToastContext()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [ editable, setEditable ] = useState({})

    const fetchPlans = useCallback(async (bypass = false) => {
        try {
            let fetch = await getPlans();
            if (bypass || plans.length !== fetch.docs.length) setPlans(fetch.docs)
        } catch (error) {
            showToast({
                title: 'Sem planos para exibir',
                description: error.message || 'Erro ao buscar planos',
                status: 'error'
            })
        }
    }, [plans.length,showToast])

    useEffect(() => {
        fetchPlans()
    })

    const handleSetEditable = (data) => {
        setEditable(data)
        onOpen()
    }

    const handleCreatePlan = async (formData) => {
        console.log(formData)
        onClose()

        try {
            let response = await createPlan(formData)
            fetchPlans(true)
            showToast({
                title: 'Sucesso',
                description: response.message,
                status: 'success'
            })
        } catch (error) {
            showToast({
                title: 'Algo deu errado',
                description: error.message,
                status: 'error'
            })
        }
    }

    const handleClose = () => {
        setEditable({})
        onClose()
    }

    const handleEditPlan = async (formData) => {
        console.log(formData)
    }

    return (
        <Box>
            <CustomHeading>Gerenciar Planos</CustomHeading>

            <CustomHeading mt={4}>
                <Button colorScheme="teal" onClick={onOpen}>Adicionar Plano</Button>
            </CustomHeading>

            <ListPlans plans={plans} setEditable={handleSetEditable} p={4} borderWidth="1px" borderRadius="md" boxShadow='md' bgColor='white' mt={4} />

            <Modal isOpen={isOpen} onClose={handleClose} size={"3xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Adicionar Plano</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <PlansForm onSubmit={handleCreatePlan} planData={editable} edit={!!(editable._id)} handleEdit={handleEditPlan} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default Planos;