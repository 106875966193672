/**
 * Verifica se uma data está dentro de um intervalo de dias a partir da data atual.
 * @param {Date} targetDate - A data alvo para verificar.
 * @param {number} days - O número de dias para considerar como "próximo".
 * @returns {boolean} - Retorna true se a data está dentro do intervalo, caso contrário, false.
 */
export default function isDateNear(targetDate, days) {
    const today = new Date();
    const target = new Date(targetDate);
    
    // Calcula a diferença em milissegundos
    const timeDiff = target.getTime() - today.getTime();
    
    // Calcula o número de dias entre as duas datas
    const dayDiff = timeDiff / (1000 * 3600 * 24);
    
    // Verifica se a data está dentro do intervalo
    return dayDiff > 0 && dayDiff <= days;
}