import { ButtonGroup, GridItem, VStack, Button, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { SITE_NAME } from "../../misc/siteConfig";
import { Link } from "react-router-dom";
import Bread from "../../components/ui/Bread";

export default function Suporte() {
    return (
        <GridItem py={20} display={'flex'} justifyContent={'center'}>
            <Helmet>
                <title>{SITE_NAME} &#8208; Suporte</title>
            </Helmet>

            <VStack width={'40%'} alignItems={'flex-start'} spacing={5} justifyContent={'start'}>
                <Bread options={[{ name: 'Suporte', link: '/suporte', current: true }]} />

                <Heading size={'lg'} color={'teal.600'}>Suporte</Heading>
                <Text width={'80%'}>Tem alguma dúvida ou sugestão? Entre em contato conosco!</Text>

                <ButtonGroup variant={'outline'} colorScheme="teal">
                    <Link to="termos">
                        <Button fontSize={'sm'} color={'teal.600'} p={10}>Termos de Uso</Button>
                    </Link>

                    <Link to="privacidade">
                        <Button fontSize={'sm'} color={'teal.600'} p={10}>Politica de Privacidade</Button>
                    </Link>

                    <Link to="/contato">
                        <Button fontSize={'sm'} color={'teal.600'} p={10}>Mandar mensagem</Button>
                    </Link>
                </ButtonGroup>
            </VStack>
        </GridItem>
    )
}