import React, { useEffect, useState } from "react";
import { Icon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

function PaymentStatus({ status }) {
    const [color,setColor] = useState();

    useEffect(() => {
        switch (status) {
            case "pending":
                setColor("yellow.500");
                break;
            case "approved":
                setColor("green.500");
                break;
            case "rejected":
                setColor("red.500");
                break;
            case "refunded":
                setColor("gray.500");
                break;
            case "in_process":
                setColor("blue.500");
                break;
            default:
                setColor("gray.500");
        }
    }, [status])

    return (
        <Icon
            as={InfoIcon}
            color={color}
            w={5}
            h={5}
        />
    );
}

export default PaymentStatus;