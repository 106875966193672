import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Button, Heading, Input, Link, Stack } from "@chakra-ui/react";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { useNavigate } from "react-router-dom";

import { SITE_NAME } from "../../misc/siteConfig";
import { checkPlanTools } from "../../auth/userHelpers";
import ToolNotIncluded from "../../components/dashboard/plan/toolNotIncluded";
import { useToastContext } from "../../context/ToastContext";

import budgetService from "../../services/backend/BudgetService";
import useGetBudgets from '../../hooks/budget/useGetBudgets';
import BudgetTable from "../../components/dashboard/budget/budgetTable";

export default function Budget() {
    const navigate = useNavigate();
    const { showToast } = useToastContext();
    const user = useAuthUser();
    const { budgets, refresh, pagination, setSearch, toNextPage, toPrevPage } = useGetBudgets();

    useEffect(() => {
    }, [budgets]);

    const handleEdit = (budget) => {
        navigate(`/dashboard/orcamentos/editar/${budget._id}`, { state: { budget } })
    }

    const handleDelete = async (budget) => {
        try {
            await budgetService.deleteBudget(budget._id)
            refresh()
        } catch (error) {
            showToast({
                title: 'Erro ao excluir orçamento',
                description: error.error,
                status: 'error'
            })
        }
    }

    return (
        checkPlanTools(user, 'Orcamentos') ? (
            <Box>
                <Helmet>
                    <title>{SITE_NAME} - Orcamentos</title>
                </Helmet>
                <Heading borderWidth="1px" borderRadius="md" fontSize='md' boxShadow='md' bgColor='white' p={4}>
                    Orcamentos
                </Heading>

                <Box mt={4} width={'100%'} borderRadius={'md'} bgColor={'white'} boxShadow={'md'} p={4}>
                    <Stack direction={['column', 'row']} spacing={4}>

                        <Link as={RouterLink} to={'novo'}>
                            <Button colorScheme="teal" size="md">Criar orçamento</Button>
                        </Link>
                        <Input placeholder="Procurar..." size="md" width={'20%'} onChange={(e) => setSearch(e.target.value)} />
                    </Stack>
                </Box>

                <BudgetTable
                    budgets={budgets}
                    actions={{
                        edit: handleEdit,
                        remove: handleDelete
                    }}
                    pagination={{
                        ...pagination,
                        toNextPage,
                        toPrevPage
                    }} />
            </Box>
        ) : <ToolNotIncluded />
    )
}