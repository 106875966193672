import React, { useState } from "react";
import { FormControl, FormErrorMessage, FormLabel, Input, Stack } from "@chakra-ui/react";

function FormPixType({ pixObject, errors, change, ...props }) {

    return (
        <div>
            <FormControl>
                <FormLabel>Nome</FormLabel>
                <Stack direction={["column", "row"]}>
                    <Input
                        isRequired
                        isInvalid={errors.first_name ? true : false}
                        type="text"
                        value={pixObject.first_name}
                        placeholder="Ex: Eduardo"
                        onChange={(e) => change({ first_name: e.target.value })} />

                    <Input
                        isRequired
                        isInvalid={errors.last_name ? true : false}
                        type="text"
                        value={pixObject.last_name}
                        placeholder="Ex: Almeida"
                        onChange={(e) => change({ last_name: e.target.value })} />
                </Stack>

                <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4}>
                <FormLabel>CPF</FormLabel>
                <Input
                    isRequired
                    isInvalid={errors.document ? true : false}
                    type="number"
                    value={pixObject.document}
                    placeholder="Ex: 000.000.000-00"
                    onChange={(e) => change({ document: e.target.value })} />
                <FormErrorMessage>{errors.cpf}</FormErrorMessage>
            </FormControl>
        </div>
    )
}

export default FormPixType