import { Box, Center, HStack, Image, VStack, Link } from "@chakra-ui/react";

import Logo from '../../assets/images/Logo_Invert.png'
import { FaInstagram, FaYoutube  } from "react-icons/fa";
import { RiCustomerServiceLine, RiMailLine  } from "react-icons/ri";
import bgLogin from '../../assets/images/bgLogin.jpg'

import { Link as RouterLink } from "react-router-dom";

import SupportFooter from "../../components/ui/supportFooter";

function LoginLayout({children}) {
    return (
        <HStack height='100vh' spacing={0}>
            <Box width='50%' height='100%' bgColor='gray.50' boxShadow='md'>
                <Center width='100%' height='94.3%'>
                    <VStack width='100%'>
                        <Link as={RouterLink} to='/' mt={10}>
                            <Image src={Logo} width='300px' />
                        </Link>

                    </VStack>
                </Center>

                <SupportFooter options={[
                    {label: 'Suporte ao Usuário', href: '/suporte'},
                    {label: 'Contato', href: '/contato'},
                    {label: 'Termos de Uso', href: '/suporte/termos'},
                    {label: 'Política de Privacidade', href: '/suporte/privacidade'},
                ]} />
            </Box>

            <Center 
                width='50%'
                height='100%'
                bgColor='teal'
                backgroundImage={`linear-gradient(rgba(56, 178, 172, 0.5), rgba(56, 178, 172, 0.5)), url(${bgLogin})`}
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"> 
                {children}
            </Center>
        </HStack>
    )
}

export default LoginLayout