// import React, { useState, useEffect } from 'react';
// import {
// 	FormControl,
// 	FormLabel,
// 	Input,
// 	Textarea,
// 	Button,
// 	Box,
// 	FormErrorMessage
// } from '@chakra-ui/react';
// import AsyncSelect from 'react-select/async';
// import { useToastContext } from '../../../context/ToastContext';

// import { getCustomersAll } from '../../../services/backend/CustomersService'

// const NewScriptForm = ({ onClose, onSubmit, script }) => {
// 	const [newScript, setNewScript] = useState({ title: '', description: '', customer: '' });
// 	const [formError, setFormError] = useState('');
// 	const [action, setAction] = useState('create')
// 	const { showToast } = useToastContext()

// 	useEffect(() => {
// 		if (script) {
// 			setAction('edit')
// 			setNewScript(prevData => ({
// 				...prevData,
// 				...script,
// 				customer: script.customer
// 					? { label: script.customer.fullname, value: script.customer._id }
// 					: null
// 			}));
// 		}
// 	}, [script])

// 	const loadCustomers = async (inputValue) => {
// 		try {
// 			const response = await getCustomersAll({ limit: 15, search: inputValue })
// 			return response.docs.map(customer => ({
// 				label: customer.fullname,
// 				value: customer._id
// 			}));
// 		} catch (error) {
// 			showToast({
// 				title: 'Carregar clientes falhou',
// 				description: error.message,
// 				status: 'error'
// 			})
// 			return [];
// 		}
// 	};

// 	const handleChange = (name, value) => {
// 		setNewScript({ ...newScript, [name]: value })
// 		setFormError(prevErrors => ({ ...prevErrors, [name]: '' }));
// 	}

// 	const handleChangeCustomer = (customer_id) => {
// 		setNewScript({ ...newScript, customer: customer_id })
// 		setFormError(prevErrors => ({ ...prevErrors, customer: '' }));
// 	}

// 	const validateForm = () => {
// 		var errors = {}
// 		if (!newScript.title) errors.title = 'Preencha o titulo do roteiro'

// 		setFormError(errors)
// 		return Object.keys(errors).length === 0
// 	}

// 	// Função para adicionar um novo roteiro
// 	const handleAddScript = () => {
// 		if (validateForm()) {
// 			// Reseta os erros
// 			setFormError('');

// 			// Chama o callback e fecha o modal
// 			const customerId = newScript.customer
// 				? (newScript.customer._id || newScript.customer.value)
// 				: null;

// 			onSubmit(action, { ...newScript, customer: customerId });
// 			onClose();  // Fecha o modal
// 		}
// 	};


// 	return (
// 		<Box p={4}>
// 			<FormControl isInvalid={formError.title} isRequired>
// 				<FormLabel htmlFor="title">Título</FormLabel>
// 				<Input
// 					id="title"
// 					value={newScript.title}
// 					onChange={(e) => handleChange('title', e.target.value)}
// 					placeholder="Título do roteiro"
// 				/>
// 				<FormErrorMessage>{formError.title}</FormErrorMessage>
// 			</FormControl>

// 			<FormControl>
// 				<FormLabel mt={4} htmlFor="description">Descrição</FormLabel>
// 				<Textarea
// 					id="description"
// 					value={newScript.description}
// 					onChange={(e) => handleChange('description', e.target.value)}
// 					placeholder="Descrição do roteiro"
// 				/>
// 			</FormControl>

// 			<FormControl>
// 				<FormLabel mt={4} htmlFor="customer">Cliente</FormLabel>
// 				<AsyncSelect
// 					id="customer"
// 					cacheOptions
// 					loadOptions={loadCustomers}
// 					isClearable
// 					defaultOptions
// 					value={newScript.customer}
// 					onChange={(value) => handleChangeCustomer(value)}
// 					placeholder="Selecione um cliente"
// 				/>
// 			</FormControl>
// 			<Button colorScheme="teal" mt={4} onClick={handleAddScript}>{action === 'edit' ? 'Editar' : 'Adicionar'}</Button>
// 		</Box>
// 	);
// };

// export default NewScriptForm;
import React, { useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Box,
  FormErrorMessage,
} from '@chakra-ui/react';
import AsyncSelect from 'react-select/async';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useToastContext } from '../../../context/ToastContext';
import { getCustomersAll } from '../../../services/backend/CustomersService';

const NewScriptForm = ({ onClose, onSubmit, script }) => {
  const { showToast } = useToastContext();

  // Validação com Yup
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Preencha o título do roteiro'),
    description: Yup.string(),
    customer: Yup.object().nullable(),
  });

  // Carregar clientes com AsyncSelect
  const loadCustomers = async (inputValue) => {
    try {
      const response = await getCustomersAll({ limit: 15, search: inputValue });
      return response.docs.map((customer) => ({
        label: customer.fullname,
        value: customer._id,
      }));
    } catch (error) {
      showToast({
        title: 'Carregar clientes falhou',
        description: error.message,
        status: 'error',
      });
      return [];
    }
  };

  return (
    <Formik
      initialValues={{
		    _id: script?._id || '',
        title: script?.title || '',
        description: script?.description || '',
        customer: script?.customer
          ? { label: script.customer.fullname, value: script.customer._id }
          : null,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const customerId = values.customer?.value || null;
        onSubmit(script ? 'edit' : 'create', { ...values, customer: customerId });
        onClose();
      }}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <Box p={4}>
            {/* Campo Título */}
            <FormControl isInvalid={touched.title && errors.title} isRequired>
              <FormLabel htmlFor="title">Título</FormLabel>
              <Field
                as={Input}
                id="title"
                name="title"
                placeholder="Título do roteiro"
              />
              <FormErrorMessage>{errors.title}</FormErrorMessage>
            </FormControl>

            {/* Campo Descrição */}
            <FormControl>
              <FormLabel mt={4} htmlFor="description">Descrição</FormLabel>
              <Field
                as={Textarea}
                id="description"
                name="description"
                placeholder="Descrição do roteiro"
              />
            </FormControl>

            {/* Campo Cliente */}
            <FormControl>
              <FormLabel mt={4} htmlFor="customer">Cliente</FormLabel>
              <AsyncSelect
                id="customer"
                cacheOptions
                loadOptions={loadCustomers}
                isClearable
                defaultOptions
                value={values.customer}
                onChange={(value) => setFieldValue('customer', value)}
                placeholder="Selecione um cliente"
              />
            </FormControl>

            {/* Botão Submeter */}
            <Button colorScheme="teal" mt={4} type="submit">
              {script ? 'Editar' : 'Adicionar'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default NewScriptForm;
