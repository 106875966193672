import React from "react";
import {
    Heading,
    Text,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    List,
    ListItem,
    ListIcon,
    Divider,
    Box,
    VStack,
    Button
} from "@chakra-ui/react";

import { MdCheckCircle } from 'react-icons/md'

function PlanCard({ plan, callback, activePlan, button_label }) {

    return (
        <Card
            _hover={{ borderColor: 'teal', borderWidth: '2px' }}
            p={10}
            pb={5}
            sx={plan._id === activePlan ? { cursor: 'pointer', borderColor: 'teal', borderWidth: '2px' } : { cursor: 'pointer' }}
            boxShadow={'md'}
            key={plan._id}>

            <CardHeader>
                <Heading size={'lg'}>{plan.name}</Heading>
                <Text mt={4} size={'xs'}>{plan.description}</Text>
            </CardHeader>

            <CardBody>
                <List spacing={3}>
                    {plan.tools && plan.tools.map((tool) => (
                        <Box key={`${plan._id}-${tool}`}>
                            <ListItem display='flex' alignItems='center'>
                                <ListIcon as={MdCheckCircle} color='green.500' />
                                <Text>{tool}</Text>
                            </ListItem>
                            <Divider mt={2} />
                        </Box>
                    ))}
                </List>
            </CardBody>

            <CardFooter>
                <VStack align={'center'} width='100%' spacing={1}>
                    <Heading size={'2xl'} mt={4}>
                        {!plan.freeTrial.status ? (
                            `R$${plan.price}`
                        ) : (
                            'Gratuito'
                        )}
                    </Heading>
                    <Text>{plan.frequency}x {plan.frequencyType}</Text>
                </VStack>
            </CardFooter>
            {button_label && (
                <Button
                    mt={4}
                    colorScheme='teal'
                    onClick={() => callback(plan)}
                    size='sm'
                >
                    {button_label}
                </Button>
            )}
        </Card>
    )
}

export default PlanCard