import { DownloadIcon, ViewIcon } from "@chakra-ui/icons"
import { Box, Tr, Td, Heading, HStack, StackDivider, Table, Text, Th, Thead, Tbody, IconButton, ButtonGroup, Button, Tfoot, Link } from "@chakra-ui/react"
import DateDisplay from "../../ui/DateDisplay"
import PaymentStatus from "./paymentStatus"

import FormattedCycle from "./utils/FormattedCycle"


export default function SubscriptionDetails({ subscription, payments, pagination }) {

    return (
        <>
            {subscription && (
                <Box>
                    <Heading size={'md'} mb={5}>Detalhes da Assinatura</Heading>
                    <HStack spacing={4} divider={<StackDivider />} justify={'space-between'}>
                        <Box boxShadow={'md'} bgColor={'white'} borderRadius={'lg'} p={10} width={'33%'}>
                            <Text fontSize="lg">Plano</Text>
                            <Text fontWeight="bold" fontSize="2xl">{subscription.planId.name}</Text>
                        </Box>

                        <Box boxShadow={'md'} bgColor={'white'} borderRadius={'lg'} p={10} width={'33%'}>
                            <Text fontSize="lg">Ciclo</Text>
                            <Text fontWeight="bold" fontSize="2xl">
                                {FormattedCycle(subscription.planId.frequency, subscription.planId.frequencyType)}
                            </Text>
                        </Box>

                        <Box boxShadow={'md'} bgColor={'white'} borderRadius={'lg'} p={10} width={'33%'}>
                            <Text fontSize="lg">Termina em</Text>
                            <Text fontWeight="bold" fontSize="2xl">
                                <DateDisplay date={subscription.endDate} />
                            </Text>
                        </Box>
                    </HStack>

                    <Heading size={'md'} mb={5} mt={5}>Histórico</Heading>
                    <Table variant={'simple'} bgcolor="white" borderRadius={'lg'} boxShadow={'md'} mt={5}>
                        <Thead>
                            <Tr>
                                <Th>Nº</Th>
                                <Th>Plano</Th>
                                <Th>Criado</Th>
                                <Th>Pagamento</Th>
                                <Th>Status</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {payments && payments.map((payment, index) => (
                                <Tr key={index}>
                                    <Td>#{index + 1}</Td>
                                    <Td>{payment.subscriptionId.planId.name}</Td>
                                    <Td><DateDisplay date={payment.createdAt} /></Td>
                                    <Td>
                                        {payment.transactionAmount > 0 && (
                                            <>
                                                {payment.paymentMethod === 'credit_card' ? 'Cartão de Crédito' : 'Pix'}
                                            </>
                                        )}

                                        {payment.transactionAmount <= 0 && (
                                            'Assinatura Trial'
                                        )}
                                    </Td>
                                    <Td><PaymentStatus status={payment.status} /></Td>
                                    <Td>
                                        <ButtonGroup spacing={3}>
                                            <IconButton icon={<DownloadIcon />} />
                                            <IconButton icon={<ViewIcon />} />
                                        </ButtonGroup>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>

                        <Tfoot p={3}>
                            <Tr>
                                <Th>
                                    <ButtonGroup >
                                        <Button
                                            onClick={() => pagination.previous()}
                                            isDisabled={pagination.state.current_page === 1}
                                        >
                                            Anterior
                                        </Button>

                                        <Button
                                            onClick={() => pagination.next()}
                                            isDisabled={pagination.state.current_page === pagination.state.total_pages}
                                        >
                                            Próximo
                                        </Button>
                                    </ButtonGroup>
                                </Th>
                            </Tr>
                        </Tfoot>
                    </Table>

                    <ButtonGroup mt={5}>
                        <Button as={Link} href={'/dashboard/premium/show'} colorScheme="teal">Upgrade</Button>
                    </ButtonGroup>
                </Box>
            )}
        </>
    )
}