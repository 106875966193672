import { Box, Heading } from "@chakra-ui/react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import useSignOut from 'react-auth-kit/hooks/useSignOut';

import ConfigForm from "../components/dashboard/config/configForm"
import { SITE_NAME } from "../misc/siteConfig"
import { useToastContext } from "../context/ToastContext"

import useAuthUser from "react-auth-kit/hooks/useAuthUser"
import { updateUser, updatePass, deleteAccount } from "../auth/userHelpers"

function Config() {
    const auth = useAuthUser()
    const navigate = useNavigate()
    const signOut = useSignOut()
    const { showToast } = useToastContext()

    const handleSubmit = async (data) => {
        let updateCheck = await updateUser(auth, data)

        if (updateCheck) {
            showToast({
                title: 'Concluido',
                description: 'Dados atualizados',
                status: 'success'
            })
        } else {
            showToast({
                description: 'Erro ao tentar atualizar os dados. Tente novamente mais tarde.',
                status: 'error'
            })
        }
    }

    const changePassword = async (credentials) => {
        let changePasswordCheck = await updatePass(auth, credentials)

        if (changePasswordCheck) {
            showToast({
                title: 'Concluido',
                description: 'Senha atualizada',
                status: 'success'
            })
        } else {
            showToast({
                description: 'Erro ao tentar atualizar a senha. Tente novamente mais tarde.',
                status: 'error'
            })
        }
    }

    const handleDelete = async () => {
        let deleteCheck = await deleteAccount(auth)

        if (deleteCheck) {
            signOut()
            navigate('/login')
        } else {
            showToast({
                description: 'Erro ao tentar apagar a conta. Tente novamente mais tarde.',
                status: 'error'
            })
        }
    }

    return (
        <Box>
            <Helmet>
                <title>{SITE_NAME} &#8208; Configurações</title>
            </Helmet>
            <Heading p={4} borderWidth="1px" borderRadius="md" fontSize='md' boxShadow='md' bgColor='white'>Configurações da Conta</Heading>
            <ConfigForm user={auth} onSubmit={handleSubmit} passwordReset={changePassword} onDelete={handleDelete} />
        </Box>
    )
}

export default Config